import React, { useState, useEffect, useCallback } from 'react';
import '../../screens/logging/logging.css';
import { DataGridPro, useGridApiRef, useGridApiContext, useGridSelector, gridDetailPanelExpandedRowsContentCacheSelector,  GRID_DETAIL_PANEL_TOGGLE_COL_DEF, } from '@mui/x-data-grid-pro';
import { InputAdornment, TextField, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPersonFalling } from '@fortawesome/free-solid-svg-icons';
import MatchingModal from './MatchingModal';
import EmailTableDetail from './EmailTableDetail';
import SearchIcon from '@mui/icons-material/Search';
import Colors from '../../library/Colors';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import SftGlobalData from '../../library/SftGlobalData';
import axios from 'axios';

const EmailsTable = (props) => {
  console.log("props email table:", props);
  const [pageSize, setPageSize] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentRow, setCurrentRow] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedClient, setSelectedClient] = useState('');
  const [familyMemberFirstName, setFamilyMemberFirstName] = useState('');
  const [familyMemberLastName, setFamilyMemberLastName] = useState('');
  const [isFamilyInputVisible, setFamilyInputVisible] = useState(false);
  const [onlyMatchedOrNoMatched, setOnlyMatchedOrNoMatched] = useState(true); // true = only matched / false = only no matched
  const [allRecords, setAllRecords] = useState(false);
  const [customers, setCustomers] = useState(props.customers);
  const [gridData, setGridData] = useState(props.data.map((row, index) => ({
    id: row.id,
    company_name: row.company_name,
    client_id_matches: row.client_id_matches,
    zoho_last_name: row.zoho_last_name,
    zoho_first_name: row.zoho_first_name,
    zoho_email: row.zoho_email,
    first_name: row.first_name,
    name: row.name,
    email: row.email,
    email2: row.email2,
  })));

 
  const apiRef = useGridApiRef();

  const gridColumns = [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      disableExport: true,
      flex: 0.5, // Adjust flex value as needed
      display: 'flex',
      renderCell: (params) => (
        <CustomDetailPanelToggle id={params.id} value={params.value} />
      ),
      headerName: 'expand',
      disableReorder: true,
    },
    { field: 'id', headerName: 'ID', flex: 0.5, align: "left" },
    { field: 'client_id_matches', headerName: props.t('client_id_matches'), flex: 3, align: "left" },
    { field: 'zoho_last_name', headerName: props.t('lastname'), flex: 1.5, align: "left" },
    { field: 'zoho_first_name', headerName: props.t('firstname'), flex: 1.5, align: "left" },
    { field: 'zoho_email', headerName: props.t('zoho_email'), flex: 4, align: "left" },
  ];

  const visibilityChangedHandler = () => {
    let temp = apiRef.current.exportState();
    if (typeof temp.columns.columnVisibilityModel === "undefined") {
      temp.columns.columnVisibilityModel = {};
    }
    try {
      temp.preferencePanel.open = false;
    } catch (e) { }

    try {
      delete temp.pagination;
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const visibilityChangedHandlerEff = () => {
        let temp = apiRef.current.exportState();
        if (typeof temp.columns.columnVisibilityModel === "undefined") {
          temp.columns.columnVisibilityModel = {};
        }
        try {
          temp.preferencePanel.open = false;
        } catch (e) { }
    
        try {
          delete temp.pagination;
        } catch (e) {
          console.error(e);
        }
      };
    try {
      apiRef.current.subscribeEvent('columnOrderChange', () => { visibilityChangedHandlerEff(); });
    } catch (ex) { }
  }, [apiRef]);

  const startMatchingAll = () => {
    const selectedRowData = apiRef.current.getSelectedRows();
    const selectedRowsArray = Array.from(selectedRowData.values());

    if (selectedRowsArray.length > 0) {
      setSelectedRows(selectedRowsArray);
      setCurrentRow(selectedRowsArray[0]);
    } else {
      const allRows = apiRef.current.getRowModels();
      const allRowsArray = Array.from(allRows.values());
      setSelectedRows(allRowsArray);
      setCurrentRow(allRowsArray[0]);
    }
    setOpenModal(true);
  };

  const handleNextRow = () => {
    const currentIndex = selectedRows.findIndex(row => row.id === currentRow.id);
    if (currentIndex < selectedRows.length - 1) {
      setCurrentRow(selectedRows[currentIndex + 1]);
    } else {
      setOpenModal(false);
    }
  };

  const createNewClient = (contact) => {
    let url = "https://app.softtouch.eu/zoho/routes.php?action=createCustomer";
    let postData = contact;
    axios.post(url, postData, {
        headers : {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
        }})
    .then(response => {
        if(SftGlobalData.debug){
            console.log('url', url);
            console.log('postData', postData);
            console.log(' response.data customers wih emails', response.data);
        }
        
        // setCustomers([response.data.data, ...customers]);
    }).catch(error => {
        if(SftGlobalData.debug){
          console.log('error', error);
        }
    });
  }

  const updateClient = (contact) => {
    let url = "https://app.softtouch.eu/zoho/routes.php?action=updateCustomer";
    let postData = contact;
    axios.post(url, postData, {
        headers : {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
        }})
    .then(response => {
        if(SftGlobalData.debug){
            console.log('url', url);
            console.log('postData', postData);
            console.log('response customers wih emails', response);
        }
        setCustomers(customers.map((customer) => {
          if (customer.klnr === contact.klnr) {
            return { ...customer, email: contact.email, email2: contact.email2 };
          }
          return customer;
        }));
    }).catch(error => {
        if(SftGlobalData.debug){
          console.log('error', error);
        }
    });
  }

  const addNewFamilyMember = (contact) => {
    let url = "https://app.softtouch.eu/zoho/routes.php?action=createAsClientFamily";
    let postData = contact;
    axios.post(url, postData, {
        headers : {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
        }})
    .then(response => {
        if(SftGlobalData.debug){
            console.log('url', url);
            console.log('postData', postData);
            console.log('response customers wih emails', response);
        }
    }).catch(error => {
        if(SftGlobalData.debug){
          console.log('error', error);
        }
    });
  }

  const ignoreRow = (id) => {
    let url = "https://app.softtouch.eu/zoho/routes.php?action=ignoreRow";
    let postData = {id: id};
    axios.post(url, postData, {
        headers : {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
        }})
    .then(response => {
        if(SftGlobalData.debug){
            console.log('url', url);
            console.log('postData', postData);
            console.log('response customers wih emails', response);
        }
    }).catch(error => {
        if(SftGlobalData.debug){
          console.log('error', error);
        }
    });
  }

  const removeRow = (id) => {
    let url = "https://app.softtouch.eu/zoho/routes.php?action=removeRow";
    let postData = {id: id};
    axios.post(url, postData, {
        headers : {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
        }})
    .then(response => {
        if(SftGlobalData.debug){
            console.log('url', url);
            console.log('postData', postData);
            console.log('response customers wih emails', response);
        }
        //find the id in the griddata and remove it
        gridData.map((row, index) => {
          if(row.id == id){
            gridData.splice(index, 1);
            setGridData([...gridData]);
          }
        })
    }).catch(error => {
        if(SftGlobalData.debug){
          console.log('error', error);
        }
    });
  }

  const handleAction = (actionType, params = {}) => {
    console.log("currentRow:", currentRow);
    if (actionType.includes("_exist")) {
      actionType = actionType.replace("_exist", "");
      setCustomers(customers.map((customer) => {
        if (customer.klnr === params.klnr) {
          return { ...customer, email: params.email, email2: params.email2 };
        }
        return customer;
      }));
    }

    let contact = {};

    switch (actionType) {
      case 'addToEmail1':
      case 'addToEmail2':
      case 'overwriteEmail1':
      case 'overwriteEmail2':
        contact = { klnr: params.klnr, email: params.email ?? "", email2: params.email2 ?? "", id: currentRow.id };
        console.log("contact:", contact);
        updateClient(contact);
        removeRow(currentRow.id);
        break;
      case 'checkCreateNew':
        setFamilyInputVisible(false);
        break;
      case 'addNewFamilyMember':
        setFamilyInputVisible(true);
        break;
      case 'confirmAddNewFamilyMember':
        setFamilyInputVisible(false);
        contact = { klnr: params.klnr, firstname: familyMemberFirstName, lastname: familyMemberLastName, email: params.email ?? "", id: currentRow.id };
        addNewFamilyMember(contact);
        removeRow(currentRow.id);
        break;
      case 'addNewClient':
        contact = { clientName: params.clientName, clientEmail: params.clientEmail, id: currentRow.id, clientType: params.clientType };
        createNewClient(contact);
        removeRow(currentRow.id);
        break;
      case 'ignore':
        console.log("Ignoring match for:", currentRow);
        contact = { klnr: currentRow.id, deleted_at: new Date() };
        ignoreRow(contact);
        break;
      default:
        break;
    }

    if (actionType !== 'addNewClient' && actionType !== 'addNewFamilyMember' && actionType !== 'checkCreateNew' && actionType !== 'ignore') {
      //reset all values
      setSelectedClient('');
      setFamilyMemberFirstName('');
      setFamilyMemberLastName('');
      handleNextRow();
    }
  };

  const getDetailPanelContent = useCallback(
    ({ row }) => (
        row ? <EmailTableDetail row={row} theme={props.theme} t={props.t} /> : null
    ),
    [props.theme, props.t]
  );

  const getDetailPanelHeight = useCallback(
    ({ row }) => {
        // get row.client_id_matches split on comma en check length
        if (row && row.client_id_matches) {
          let size = 125//175;
          //for each id in row.client_id_matches add 50 to size
          size += row.client_id_matches.split(',').length * 50;
          return size;
        }
        return 100;
    },
    []
  );

  function CustomDetailPanelToggle(props) {
    // console.log("props:", props);
    const { id, value: isExpanded } = props;
    const apiRef = useGridApiContext();
  
    // To avoid calling ´getDetailPanelContent` all the time, the following selector
    // gives an object with the detail panel content for each row id.
    const contentCache = useGridSelector(
      apiRef,
      gridDetailPanelExpandedRowsContentCacheSelector,
    );
  
    // If the value is not a valid React element, it means that the row has no detail panel.
    const hasDetail = React.isValidElement(contentCache[id]);
  
    return (
      <IconButton
        size="small"
        tabIndex={-1}
        disabled={!hasDetail}
        aria-label={isExpanded ? 'Close' : 'Open'}
      >
        <ExpandMoreIcon
          sx={{
            transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
            transition: (theme) =>
              theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
              }),
          }}
          fontSize="inherit"
        />
      </IconButton>
    );
  }
  
  CustomDetailPanelToggle.propTypes = {
    /**
     * The grid row id.
     */
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    /**
     * The cell value, but if the column has valueGetter, use getValue.
     */
    value: PropTypes.any,
  };

  return (
    <div>
      <div className='log__container_options'>

        <TextField
          id="input-with-icon-textfield"
          autoComplete="off"
          className={props.theme + '_accent margin_right__10px'}
          sx={{ borderRadius: 3, marginTop: 0 }}
          size={'small'}
          fullWidth
          type="search"
          placeholder={props.t('search')}
          onChange={(e) => setSearchValue(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon style={{ color: Colors.sft_blue }} />
              </InputAdornment>
            ),
          }}
        />
      <Button className={"environment__toggle_button " + (searchValue === "vibrator" ? "shaker " : "") + (allRecords ? "yellow" : onlyMatchedOrNoMatched ? "green" : "red")} onDoubleClick={() => { setAllRecords(true); }} onClick={() => { setAllRecords(false); setOnlyMatchedOrNoMatched(!onlyMatchedOrNoMatched); }}><FontAwesomeIcon icon={faPersonFalling} color={'white'} size="1x" /></Button>
      </div>

      <DataGridPro
        apiRef={apiRef}
        className={props.theme}
        initialState={ {pinnedColumns: { left: ['expand'] }} }
        columns={gridColumns}
        onColumnWidthChange={() => visibilityChangedHandler()}
        onColumnVisibilityModelChange={() => visibilityChangedHandler()}
        onPreferencePanelClose={() => visibilityChangedHandler()}
        rows={
          gridData
          ?.filter(row => (!onlyMatchedOrNoMatched && !allRecords ? row.id?.includes("_no_match") : true) && (onlyMatchedOrNoMatched && !allRecords ? !row.id?.includes("_no_match") : true))
          ?.filter(row => (row.client_id_matches?.match(new RegExp(searchValue, "i")) || row.zoho_last_name?.match(new RegExp(searchValue, "i")) || row.zoho_first_name?.match(new RegExp(searchValue, "i")) || row.zoho_email?.match(new RegExp(searchValue, "i"))))
        }
        rowHeight={38}
        style={{ maxHeight: 500 }}
        pagination={true}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 20, 30]}
        getDetailPanelHeight={getDetailPanelHeight}
        getDetailPanelContent={getDetailPanelContent}
      />

      { props.data?.length > 0 && (
        <Button
        variant="outlined"
        sx={{ borderRadius: 3, border: '2px solid ' + Colors.sft_blue, color: Colors.sft_blue }}
        style={{ width: '100%', marginTop: 5 }}
        onClick={() => { startMatchingAll() }}>Start matching</Button>
      )}

      <MatchingModal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setFamilyInputVisible(false);
          setCurrentRow(null);
        }}
        currentRow={currentRow}
        isFamilyInputVisible={isFamilyInputVisible}
        selectedClient={selectedClient}
        handleAction={handleAction}
        handleClientSelection={setSelectedClient}
        familyMemberFirstName={familyMemberFirstName}
        familyMemberLastName={familyMemberLastName}
        setFamilyMemberFirstName={setFamilyMemberFirstName}
        setFamilyMemberLastName={setFamilyMemberLastName}
        t={props.t}
        theme={props.theme}
        customers={props.customers?.map(customer => ({
          ...customer, 
          "label": `${customer?.klnr}.${customer?.firmanaam}`
        })
      )}
      />
    </div>
  );
}

EmailsTable.propTypes = {
  theme: PropTypes.string,
  initialGrid: PropTypes.object,
  data: PropTypes.array,
  t: PropTypes.func.isRequired,
};

export default EmailsTable;