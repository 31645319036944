import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import SftGlobalData from '../../library/SftGlobalData';
import './excel.css';
import ListItemV2 from '../../components/ListItemV2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Colors from '../../library/Colors';
import { faLink, faPencilAlt, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import FloatingAddButton from '../../components/FloatingAddButton';
import axios from 'axios';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import LicenceCheckBox from '../../components/licence/LicenceCheckBox';

const LinkCell = (props) => {
    return (<Link to={"/" + props.lang + "/excel/" + props.id} style={{ width: '100%', height: '100%', alignContent: 'center', alignItems: 'center', display: 'flex' }}>{props.children}</Link>)
}

const ExcelScreen = (props) => {
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const params = useParams();
    let navigate = useNavigate();
    const apiRef = useGridApiRef();
    const gridColumns = [
        {
            field: 'id',
            headerName: "Module",
            width: 75,
            align: "left",
            display: 'flex',
            renderCell: (params) => {
                return <LinkCell id={params.row.id} lang={props.lang} theme={props.theme} ><span>{params.row.id}</span></LinkCell>
            }
        },
        {
            field: 'mod_name',
            headerName: props.t('name'),
            headerClassName: 'bold',
            minWidth: 175,
            width: 550,
            align: "left",
            display: 'flex',
            renderCell: (params) => {
                return <LinkCell id={params.row.id} lang={props.lang} theme={props.theme} ><span>{params.row.mod_name}</span></LinkCell>
            }
        },
        {
            field: 'active',
            headerName: ((props.login.sft_status !== "0") ? props.t('active') : ""),
            width: 100,
            align: "left",
            display: 'flex',
            renderCell: (params) => { return (props.login.sft_status !== "0" && props.login.sft_group === "SUPER") ? <LicenceCheckBox key={params.row.id} theme={props.theme} checked={params.row.active === "Y"} api={props.api} database={""} mod={{ mod_id: params.row.id, id: params.row.client_mod_id }} /> : <div></div> }
            /*renderCell: (params)=>{return (props.login.sft_status !== "0") ? <ListItemV2 
                key={params.row.id} 
                id={params.row.client_mod_id} 
                lang={props.lang} 
                name={params.row.name}
                active={params.row.active} 
                api={props.api} 
                theme={props.theme}
                disabled={params.row.owner === '1'}
                submitHandler={(id,value)=>{setModActive(params.row.id, id, value);}}
                /> : <div></div>}*/
        },
        {
            field: 'edit',
            headerName: ((props.login.sft_status !== "0" && (props.login.sft_group === "DEV" || props.login.sft_group === "ADMIN" ||props.login.sft_group === "SUPER")) ? props.t('edit') : ""),
            minWidth: 60,
            maxWidth: 60,
            align: 'center',
            headerAlign: 'center',
            display: 'flex',
            renderCell: (params) => {
                return (props.login.sft_status !== "0" && (props.login.sft_group === "DEV" || props.login.sft_group === "ADMIN" ||props.login.sft_group === "SUPER")) ? <Button style={{ color: (props.theme === 'dark' ? Colors.dark_text : Colors.light_text), height: "100%", borderRadius: "50%" }} onClick={() => { navigate("/" + props.lang + "/excel/edit/" + params.row.id) }} >
                    <FontAwesomeIcon icon={faPencilAlt} color={Colors.def_orange} size="2x" />
                </Button> : <div></div>;
            }
        },
        /*{
            field: 'delete',
            headerName: props.t('delete'),
            minWidth: 60,    
            maxWidth: 60,  
            align: 'center',      
            headerAlign: 'center', 
            renderCell: (params) => {
            return  (props.login.sft_status !== "0" && props.login.sft_group === "SUPER") ? <Button style={{color: (props.theme === 'dark' ? Colors.dark_text : Colors.light_text), height:"100%", borderRadius: "50%" }} onClick={()=>{deleteModule(params.row);}} >
                        <FontAwesomeIcon icon={faTrashCan} color={Colors.def_red} size="2x" />
                    </Button> : <div></div>;        
            }
        },*/
        {
            field: 'link',
            headerName: "",
            minWidth: 60,
            maxWidth: 60,
            align: 'center',
            headerAlign: 'center',
            display: 'flex',
            renderCell: (params) => {
                return (props.login.sft_status !== "0") ? <Button style={{ color: (props.theme === 'dark' ? Colors.dark_text : Colors.light_text), height: "100%", borderRadius: "50%" }} onClick={() => { copyUrl(params.row.id) }} ><FontAwesomeIcon icon={faLink} color={Colors.def_grey} size="2x" /></Button>
                    : <div></div>
            }
        },
    ];

    useEffect(() => {
        if (props.api !== '')
            loadData();
    }, [props.api]);

    const loadData = () => {
        setLoading(true);
        let url = SftGlobalData.baseURL_API + 'excel&action=fetch';
        axios.post(url, { key: props.api, sft_status: props.login?.sft_status, sft_group: props.login?.sft_group, sft_user_id: props.login?.sft_user_id }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        })
            .then(response => {
                if (SftGlobalData.debug) {
                    console.log('url', url);
                    console.log('response', response);
                }
                if (typeof response.error === 'undefined') {
                    setList(response.data);
                } else {
                    props.notify(props.t('errorOccured'), 7500, "top-center", props.theme, 1);
                }
                setLoading(false);
            });
        props.changeScreen('Licence');
    }

    const deleteModule = (modId) => {
        loadData();
    }

    const copyUrl = (modId) => {
        navigator.clipboard.writeText("https://my.softtouch.eu/" + props.lang + "/authentication/{hashkey}/excel/" + modId);
        props.notify(props.t('copied') + " excel import report URL", 2000, "top-right", props.theme, 1);
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }} className={(props.screenWidth) < 600 ? 'not_expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme : 'expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme} >
            <div className={"container_list__title " + props.theme}><h2>Excel import</h2></div>
            <div className='rowContainerCenter _100perc'>
                <DataGridPro
                    apiRef={apiRef}
                    //initialState={initialGrid}
                    className={'excel_grid ' + props.theme}
                    columns={gridColumns}
                    rows={list.map((row, index) => {
                        return {
                            id: row.mod_id,
                            client_mod_id: row.client_mod_id,
                            mod_name: (props.lang === "nl" ? row.mod_name : row.mod_name_fr),
                            active: row.active,
                            edit: "Edit",
                            link: "Link",
                            delete: "Delete",
                        }
                    })}
                    //onColumnWidthChange={()=>{visibilityChangedHandler();}}
                    //onColumnVisibilityModelChange={()=>{visibilityChangedHandler();}}
                    //onPreferencePanelClose={()=>{visibilityChangedHandler();}}
                    rowThreshold={0}
                    rowHeight={38}
                    autoHeight={true}
                    loading={loading}
                    pagination={false}
                />
            </div>

            {(props.login.sft_status !== "0" && props.login.sft_group === "SUPER") ? <FloatingAddButton disabled={loading} onClick={() => { navigate("/" + props.lang + "/excel/edit/+") }} /> : <div></div>}
        </div>
    );
}

export default ExcelScreen;
