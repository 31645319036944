import React, { useEffect, useState, useRef } from 'react';
import { TailSpin } from 'react-loader-spinner';
import {
    Link,
    useNavigate,
    useParams
} from "react-router-dom";
import { Button, TextField, Switch, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, Select, MenuItem, ListItemText, Autocomplete, Box} from '@mui/material';
import axios from 'axios';
import SftGlobalData from '../../library/SftGlobalData';
import Colors from '../../library/Colors';
import HorizontalLinearStepper from '../../components/Stepper';
import MailIntegrationOption from './MailIntegrationOption';
import mailchimpLogo from '../../assets/mailintegration-icons/ic_mailchimp.png'
import klaviyoLogo from '../../assets/mailintegration-icons/ic_klaviyo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { CodeBlock, atomOneDark} from 'react-code-blocks';

const MailIntegrationsSetup = (props) => {
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [mailIntegrationId, setMailIntegrationId] = useState(0);
    const [selectedIntegration, setSelectedIntegration] = useState(null);
    const [selectedIcon, setSelectedIcon] = useState(null); // Store selected icon
    const [currentStep, setCurrentStep] = useState(0); // Track current step in parent
    const [canProceed, setCanProceed] = useState(false);
    const [token, setToken] = useState("");
    const [listId, setListId] = useState("");
    const [dailyRepId, setDailyRepId] = useState(0);
    const [fullRepId, setFullRepId] = useState(0);
    const [active, setActive] = useState(false);
    const [useReport, setUseReport] = useState(false);
    const [useClientTypeFilter, setUseClientTypeFilter] = useState(false);
    const [groupedCodes, setGroupedCodes] = useState({});
    const [clientCodeFilters, setClientCodeFilters] = useState([]);
    const [savedClientTypeCodes, setSavedClientTypeCodes] = useState({}); 
    const [useClientMagFilter, setUseClientMagFilter] = useState(false);
    const [selectedMagFilter, setSelectedMagFilter] = useState([]);
    const [famUseClientCard, setFamUseClientCard] = useState(false);

    const [tempTblQueryFull, setTempTblQueryFull] = useState("");
    const [tempTblQueryDaily, setTempTblQueryDaily] = useState("");
    const [isFullQueryValid, setIsFullQueryValid] = useState({ isValid: true, message: "" });
    const [isDailyQueryValid, setIsDailyQueryValid] = useState({isValid: true, message: "" });
    const [testingTmpTblQuery, setTestingTmpTblQuery] = useState(false);
    const [testingExampleQuery, setTestingExampleQuery] = useState(false);
    const [failedTestingTmpTblQuery, setFailedTestingTmpTblQuery] = useState(false);

    const [availableDb, setAvailableDb] = useState([]);
    const [mainTbl, setMainTbl] = useState("klant");
    const [clientFields, setClientFields] = useState([
        { field: 'EMAIL', value: 'klant.email', canBeDeleted: false },
        { field: 'CUSTNO', value: 'klant.klnr', canBeDeleted: false },
        { field: 'FNAME', value: 'klant.firstname', canBeDeleted: true },
        { field: 'LNAME', value: 'klant.naam', canBeDeleted: true },
        { field: 'BIRTHDAY', value: "DATE_FORMAT(klant.gebdatum, '%m/%d')", canBeDeleted: true }
    ]);
    const [newClientMapField, setNewClientMapField] = useState("");
    const [newClientMappedField, setNewClientMappedField] = useState("");
    const [leftJoins, setLeftJoins] = useState(["LEFT JOIN magazijn on klant.mag = magazijn.code"]);
    const [newJoin, setNewJoin] = useState("");
    const [wheres, setWheres] = useState([]);
    const [newWhere, setNewWhere] = useState("");
    const [useFam, setUseFam] = useState(false);
    const [mainFamTbl, setMainFamTbl] = useState("klant_fam");
    const [clientFamFields, setClientFamFields] = useState([]);
    const [leftJoinsFam, setleftJoinsFam] = useState(["LEFT JOIN magazijn on klant.mag = magazijn.code"]);
    const [newJoinFam, setNewJoinFam] = useState("");
    const [wheresFam, setWheresFam] = useState([]);
    const [newWhereFam, setNewWhereFam] = useState("");
    const [fullQuery, setFullQuery] = useState("");

    const [checkingApiConnection, setCheckingApiConnection] = useState(true);
    const [apiConnectionSuccess, setApiConnectionSuccess] = useState(false);
    const [checkingKlKaart, setCheckingKlKaart] = useState(true);
    const [fetchingReports, setFetchingReports] = useState(false);
    const [klkaartTypes, setKlkaartTypes] = useState([]);
    const allowedKlkaartTypes = [2];

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    const [exampleDataQuery, setExampleDataQuery] = useState([]);

    const [gridIntegrationColumns, setGridIntegrationColumns] = useState([]);


    const [availableStores, setAvailableStores] = useState("");

    useEffect(() => {
        if(props.api !== ""){
            let url = SftGlobalData.baseURL_API + 'sftData';
            axios.post(url, {key: props.api, type: "stores"}, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('response stores', response);
                }
                setAvailableStores(response.data.data);
            });

            url = SftGlobalData.baseURL_API + 'mailIntegration&action=getDatabaseStructure';
            axios.post(url, {key: props.api }, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('response db', response.data);
                }

                setAvailableDb(response.data);
            });

            // url = SftGlobalData.baseURL_API + 'mailIntegration&action=getClientCodes';
            url = SftGlobalData.baseURL_API + 'sftData';
            let postData = { type: 'customerCodes', key: props.api };
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if (SftGlobalData.debug) {
                        console.log("url", url);
                        console.log("response customerCodes (client codes)", response.data);
                    }
        
                    // Map data to codeGroups and codes
                    const codeGroups = [...new Set(response.data.data.map(item => item.codegroep))].sort();
                    const grouped = codeGroups.reduce((acc, group) => {
                        let groupId = group;
                        if (group?.startsWith("0")) {
                            group = group.substring(1);
                        }
                        acc[`client_codes${group}`] = response.data.data.filter(code => code.codegroep === groupId);
                        return acc;
                    }, {});
        
                    setGroupedCodes(grouped);
        
                    // Pre-fill `clientCodeFilters` based on savedClientTypeCodes
                    const savedFilters = Object.keys(grouped).map(groupKey => {
                        return (savedClientTypeCodes[groupKey] || []).map(savedCode =>
                            grouped[groupKey].find(item => item.totcode === savedCode) || null
                        ).filter(Boolean);
                    });
        
                    setClientCodeFilters(savedFilters); // Align filters with grouped codes
             
            });


        }
    },[props.api]);

    useEffect(() => {
        if (groupedCodes && savedClientTypeCodes && Object.keys(groupedCodes).length > 0) {
            // Map saved codes to grouped codes
            const updatedFilters = Object.keys(groupedCodes).map(groupKey => {
                return (savedClientTypeCodes[groupKey] || []).map(savedCode =>
                    groupedCodes[groupKey].find(item => item.totcode === savedCode) || null
                ).filter(Boolean);
            });
    
            setClientCodeFilters(updatedFilters);
        }
    }, [groupedCodes, savedClientTypeCodes]);
    
    

    // Generate grid columns dynamically
    useEffect(() => {
        const dynamicClientColumns = clientFields.map(field => ({
            field: field.field,
            headerName: field.field,
            headerClassName: 'bold',
            minWidth: 150,
            width: 250,
            align: "left",
        }));

        setGridIntegrationColumns([...dynamicClientColumns]);
    }, [clientFields, clientFamFields, useFam]);


    useEffect(() => {
        props.changeScreen('Licence');
        if(props.api !== ""){
            if(params.id !== '+'){
                let url = SftGlobalData.baseURL_API + 'mailIntegration&action=fetchOne';
                let postData = {key: props.api, id: params.id};
                console.log("url:", url);
                axios.post(url, postData, {
                    headers : {
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                    }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log("URL", url);
                        console.log("POST", postData);
                        console.log("RESP FETCH", response);
                    }

                    //get the config json
                    let config = JSON.parse(response.data.config);
                    setSelectedIntegration(response.data.type);
                    handleSelectIntegration(response.data.type);
                    setToken(response.data.token);
                    setListId(response.data.list_id);
                    setDailyRepId(response.data.report_id_daily);
                    setFullRepId(response.data.report_id_full);
                    setActive(response.data.active === "1");
                    setUseReport((response.data.report_id_full !== "0" && response.data.report_id_daily !== "0"));
                    setUseClientTypeFilter(config.use_client_type_filter);
                    setSavedClientTypeCodes(config.client_type_filter ?? []);
                    setUseClientMagFilter(config.selectedMagFilter?.length > 0);
                    setSelectedMagFilter(config.selectedMagFilter ?? []);
                    setFamUseClientCard(config.fam_use_client_card == "1");
                    setTempTblQueryFull(config.tbl_temp_queries_full);
                    setTempTblQueryDaily(config.tbl_temp_queries_daily);
                    setMainTbl(config.client_main_table);
                    setClientFields(config.client_fields);
                    (Array.isArray(config.client_joins)) ? setLeftJoins(config.client_joins) : setLeftJoins([config.client_joins]);    
                    setWheres(config?.client_wheres ?? []);
                    setMainFamTbl(config.client_fam_table);
                    setUseFam(config.use_fam);
                    setClientFamFields(config.client_fam_fields);
                    (Array.isArray(config.client_fam_joins)) ? setleftJoinsFam(config.client_fam_joins) : setleftJoinsFam([config.client_fam_joins]);
                    setWheresFam(config?.client_wheres_fam ?? []);
                    setLoading(false);
                });
            } else {
                setLoading(false);
            }
        }
    },[props.api, params.id]);


    let navigate = useNavigate();

    const integrationTypes = [
        { key: 'MailChimp', label: 'MailChimp', value: 'mailchimp', icon: mailchimpLogo },
        { key: 'Klaviyo', label: 'Klaviyo', value: 'klaviyo', icon: klaviyoLogo },
    ];

    //use translations for step names
    const steps = [
        { label: props.t('mail_step_integration_type'), isOptional: false },
        { label: props.t('mail_step_credentials'), isOptional: false },
        { label: props.t('mail_step_configuration'), isOptional: false },
        { label: props.t('mail_step_tmp_tbls'), isOptional: false },
        { label: props.t('mail_step_client_fields'), isOptional: false },
        { label: props.t('mail_step_client_fam_fields'), isOptional: false },
        { label: props.t('mail_step_final_check'), isOptional: false },
        { label: props.t('mail_step_example'), isOptional: false },
        { label: props.t('mail_step_finish'), isOptional: false },
    ];

    const sendTempTableQuery = async (query) => {
        let url = SftGlobalData.baseURL_API + 'mailIntegration&action=createTempTableQuery';
        try {
            const response = await axios.post(url, { key: props.api, query: query }, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }
            });
            if(SftGlobalData.debug){
                console.log("response sendTempTableQuery:", response);
            }
            
            // Assuming the backend sends a response with a 'success' field to indicate if the request succeeded
            if (response.data?.length > 0) {
                setFailedTestingTmpTblQuery(false);
                return { success: true, message: "Query successful", data: response.data };
            } else {
                setFailedTestingTmpTblQuery(true);
                return { success: false, message: response?.data?.errorMsg || "Query failed" };
            }
        } catch (error) {
            // Handle network or other errors
            if(SftGlobalData.debug){
                console.error("Error during API call:", error);
            }
            setFailedTestingTmpTblQuery(true);
            return { success: false, message: "An error occurred while testing the query." };
        }
    };

    /** Step 0: Selection of Integration */
    const handleSelectIntegration = (key) => {
        const selected = integrationTypes.find(item => item.key === key);
        setSelectedIntegration(key);
        setSelectedIcon(selected.icon); // Store icon of selected integration
        if (selected && key) {
            setCanProceed(true);
        }
    };

    /** Step 1: Credentials */
    useEffect(() => {
        setCanProceed((token && listId));
    }, [token, listId]);

    /** Step 2: Configuration */
    useEffect(() => {
        setCanProceed(((dailyRepId && fullRepId) && (dailyRepId !== 0 && fullRepId !== 0)) || !useReport && dailyRepId == 0 && fullRepId == 0);
    }, [dailyRepId, fullRepId, useReport]);

    /** Step 3: Temporary tables  (Might not be needed since the queries can also be empty and should only be blocked if a filled in on fails*/
    useEffect(() => {
        if(currentStep === 3 && testingTmpTblQuery){
            setCanProceed(false);
        } 
    }, [testingTmpTblQuery]);

    /** Step 4: ClientFields */
    useEffect(() => {
        // Only get client fields where both `field` and `value` are filled
        const filledClientFields = clientFields.filter(
            (x) => x.field && x.value
        );
        // the filled client fields should be copied to default clientfamfields but the values should be changed to klant_fam instead of KLANT and for field CUSTNO the value  should be CONCAT(klant_fam.klnr,".",klant_fam.trecno)
        const filledClientFamFields = filledClientFields.map((field) => {
            switch (field.field) {
                case "CUSTNO":
                    return { ...field, value: 'CONCAT(klant_fam.klnr,".",klant_fam.trecno)' };
                case "FNAME": 
                    return { ...field, value: `klant_fam.text1` };
                case "LNAME":
                    return { ...field, value: `klant_fam.text2` };
                case "BIRTHDAY":
                    return { ...field, value: `DATE_FORMAT(klant_fam.text3, '%m/%d')` };
                default:
                                    // Check if the value contains a dot (i.e., table reference)
                    const pointIndex = field.value.indexOf('.');
            
                    if (pointIndex !== -1) {
                        // Split into before and after the dot
                        const beforePoint = field.value.substring(0, pointIndex);
                        const afterPoint = field.value.substring(pointIndex);
            
                        // Clean up the beforePoint (the table name) - replace with 'klant_fam'
                        const cleanedBeforePoint = beforePoint.replace(/^[a-zA-Z]+(?=\W|$)/, mainFamTbl);
            
                        // Check if the value is inside a function (i.e., contains parentheses)
                        if (beforePoint.includes('(')) {
                            //trim from end of str to the index of the first open parenthesis
                            const openParenthesisIndex = beforePoint.indexOf('(');
                            const cleanedBeforePointWithoutParenthesis = beforePoint.substring(0, openParenthesisIndex);
                            
                            return { ...field, value: `${cleanedBeforePointWithoutParenthesis}(${mainFamTbl}${afterPoint.substring(openParenthesisIndex)}${afterPoint}` };
                        
                        } else {
                            // Regular case: just return with the cleaned before point
                            return { ...field, value: `${cleanedBeforePoint}${afterPoint}` };
                        }
                    } else {
                        // If there's no dot, just prepend 'klant_fam.'
                        return { ...field, value: `${mainFamTbl}.${field.value}` };
                    }
            }
        });
        
        setClientFamFields(filledClientFamFields);

        // Set canProceed if there are filled client fields and mainTbl is set
        if(currentStep === 4){
            setCanProceed(filledClientFields.length > 0 && mainTbl);
        }
    }, [clientFields]);

    // useRef to track the previous mainTbl
    const previousMainTblRef = useRef(mainTbl);

    useEffect(() => {
        const previousMainTbl = previousMainTblRef.current;
    
        // Update clientFields to replace or reinsert table name properly
        setClientFields((prevFields) =>
            prevFields.map((field) => {
                // Case where field value is empty or just whitespace
                if (!field.value.trim()) {
                    // If the field is empty, we don't prepend anything.
                    return field;
                }

                // If previousMainTbl is empty and mainTbl is provided, check if the field already has a table reference before the dot
                if (!previousMainTbl && mainTbl) {
                    // Check if there are alphabetic characters before the dot (indicating an existing table)
                    if (!/^[a-zA-Z]+(?=\.)/.test(field.value)) {
                        // If there is no table reference, prepend the mainTbl
                        const updatedValue = field.value.replace(".", ` ${mainTbl}.`);
                        return { ...field, value: updatedValue.trim() };
                    }else{
                        return field;
                    }
                }
    
                // Replace the table name part (before the first dot) if the old table name is present
                if (field.value.includes(previousMainTbl)) {
                    // This ensures only the table name before the first dot is replaced
                    const updatedValue = field.value.replace(new RegExp(`\\b${previousMainTbl}\\b(?=\.)`), mainTbl);
                    return {
                        ...field,
                        value: updatedValue.trim(),
                    };
                }
    
                // If the field value doesn't already have a table reference (no dot) and is not empty
                if (mainTbl && !field.value.includes('.') && field.value.trim()) {
                    const updatedValue = `${mainTbl}.${field.value}`;
                    return {
                        ...field,
                        value: updatedValue.trim(),
                    };
                }

                return field; // Leave unchanged if no changes needed
            })
        );
    
        // Update the ref to the current mainTbl for the next update
        previousMainTblRef.current = mainTbl;
    }, [mainTbl]);
    
    /** Step 5: ClientFamFields */
    useEffect(() => {
        if(currentStep === 5){
            setCanProceed((clientFamFields.length > 0 && mainFamTbl && clientFamFields.filter(field => !field.value).length === 0) || !useFam);
        }
    }, [clientFamFields, mainFamTbl, useFam]);


    /** Step 6: Check API connection & result query */
    useEffect(() => {
        if (currentStep === 6) {
            setCheckingKlKaart(true);
    
            if (testingExampleQuery) {
                setCanProceed(false);
            } else if (klkaartTypes) {
                setCanProceed(apiConnectionSuccess);
            } else {
                setCanProceed(false);
            }
    
            setCheckingKlKaart(false);
        }
    }, [currentStep, apiConnectionSuccess, klkaartTypes, testingExampleQuery]);

    const checkApiConnection = () => {
        setCheckingApiConnection(true);
        const selected = integrationTypes.find(item => item.key === selectedIntegration);
    
        let postBody = {
            integration: selected.key,
            token: token,
        };

        if (selected) {
            axios.post(SftGlobalData.baseURL_API + 'mailIntegration&action=checkApi', JSON.stringify(postBody))
                .then(response => {
                    if (SftGlobalData.debug) {
                        console.log('url', SftGlobalData.baseURL_API + 'mailIntegration&action=checkApi');
                        console.log('data', JSON.stringify(postBody));
                        console.log('response', response.data);
                    }
                    if(response?.data?.success) {
                        setCheckingApiConnection(false);
                        setApiConnectionSuccess(true);
                    }else{
                        setCheckingApiConnection(false);
                        setApiConnectionSuccess(false);
                    }
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        console.log('Authentication failed: Incorrect API key.');
                    } else {
                        if(SftGlobalData.debug){
                            console.log('Error:', error.message);
                        }
                    }
                    setCheckingApiConnection(false);
                    setApiConnectionSuccess(false);
                });
        }
    };

    const getReportData = () => {
        setFetchingReports(true);
        let url = SftGlobalData.baseURL_API + 'sftData';
        let postdata =  {
            key: props.api,
            type: "reports",
            ids: [dailyRepId, fullRepId]
        }
        axios.post(url, postdata, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        }).then(response => {
            if (SftGlobalData.debug) {
                console.log('url', url);
                console.log('data', { key: props.api });
                console.log('response fetchReportData', response.data.data);
            }

            if (response.data.data) {
                //transform the data to the correct format
                let dailyReport = response.data.data.filter(x => x.repid == dailyRepId);
                let fullReport = response.data.data.filter(x => x.repid == fullRepId);

                console.log("dailyReport:", dailyReport);
                console.log("fullReport:", fullReport);

                if(dailyReport.length === 0 || fullReport.length === 0){
                    setFetchingReports(false);
                    setCanProceed(false);
                    //go a step back an notify and error 
                    //check if daily full or both are missing and create a message
                    if(dailyReport.length === 0 && fullReport.length === 0){
                        props.notify(props.t('noReportData') + '(full & daily)', 7500, "top-right", props.theme, 1);
                    }else{
                        props.notify(props.t('noReportData') + ' (' + (dailyReport.length === 0 ? 'full' : 'daily') + ')', 7500, "top-right", props.theme, 1);
                    }
                    setCurrentStep(currentStep);
                    return;
                }

                let queryObjectDaily = {
                    tempTableQueries: dailyReport?.filter( x => x?.repsql && x?.repsql.startsWith('CREATE TABLE')).map(x => x.repsql),
                    resultQuery: dailyReport?.find(x => x?.repsqltype === 'G' &&  (x?.repsql.startsWith('WITH') || x?.repsql.startsWith('SELECT')))?.repsql, // might change to dailyReport?.find(x => x?.repsqltype === 'G'))?.repsql
                    wrongEmailsQuery: "",
                    cleanupQueries: dailyReport?.filter( x => x?.repsql && x?.repsql.startsWith('DROP TABLE')).map(x => x.repsql),
                    deletedClientsQuery: ""
                };

                let queryObjectFull = {
                    tempTableQueries: fullReport?.filter( x => x?.repsql && x?.repsql.startsWith('CREATE TABLE')).map(x => x.repsql),
                    resultQuery: fullReport?.find(x => x?.repsqltype === 'G' && (x?.repsql.startsWith('WITH') || x?.repsql.startsWith('SELECT')))?.repsql, // might change to fullReport?.find(x => x?.repsqltype === 'G'))?.repsql
                    wrongEmailsQuery: "",
                    cleanupQueries: fullReport?.filter( x => x?.repsql && x?.repsql.startsWith('DROP TABLE')).map(x => x.repsql),
                    deletedClientsQuery: ""
                };

                let fullQueryObj = {
                    queryObjectDaily: queryObjectDaily,
                    queryObjectFull: queryObjectFull
                };

                getExampleQuery(fullQueryObj);
                    
            }

            setFetchingReports(false);
        }).catch(error => {
            if (SftGlobalData.debug) {
                console.log('url', url);
                console.log('data', { key: props.api });
                console.log('error', error);
            }
            setFetchingReports(false);
        });
    };

    const checkKlKaartTypes = () => {
        if (props.api !== '') {
            setCheckingKlKaart(true);
            let url = SftGlobalData.baseURL_API + 'sftData';
            axios.post(url, { key: props.api, type: "kasvars", code: 'klkaart_type' }, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }
            }).then(response => {
                if (SftGlobalData.debug) {
                    console.log('url', url);
                    console.log('data', { key: props.api });
                    console.log('response fetchKasvar', response.data.data);
                }
                setKlkaartTypes(response.data?.data?.map(x => x?.value)?.sort((a, b) => a - b));
                setCheckingKlKaart(false);
            }).catch(error => {
                if (SftGlobalData.debug) {
                    console.log('url', url);
                    console.log('data', { key: props.api });
                    console.log('error', error);
                }
                setCheckingKlKaart(false);
            });
        }
    }

    const getExampleQuery = async (queryObject) => {
        setTestingExampleQuery(true);
        let url = SftGlobalData.baseURL_API + 'mailIntegration&action=exampleQuery';
        try {
            const response = await axios.post(url, { key: props.api, queries: queryObject.queryObjectDaily }, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }
            });
    
            if (response.data?.length > 0) {
                setTestingExampleQuery(false);
                setExampleDataQuery(response.data);
                setFullQuery(queryObject.queryObjectDaily.resultQuery);
            } else {
                setTestingExampleQuery(false);
                props.notify(`${response.data?.error}  ${response.data?.errorMsg}`, 7500, "top-right", props.theme, 1);
            }
        } catch (error) {
            setTestingExampleQuery(false);
            props.notify(props.t('unexpectedError'), 7500, "top-right", props.theme, 1);
            console.log("Error getting example query", error);
        }
    }

    const canProceedToStep = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return !!selectedIntegration;
            case 1:
                return !!token && !!listId;
            case 2:
                return (dailyRepId && fullRepId && dailyRepId !== 0 && fullRepId !== 0) || (!useReport && dailyRepId === 0 && fullRepId === 0);
            case 3:
                return true;
            case 4:
                return clientFields.length > 0 && !!mainTbl;
            case 5:
                return ((clientFamFields.length > 0 && !!mainFamTbl) || !useFam) || useReport;
            case 6:
                return apiConnectionSuccess;
            case 7:
                return exampleDataQuery.length > 0;
            case 8:
                return true;
            default:
                return false;
        }
    };

    const handleStepChange = async (stepIndex) => {
        const isNextStep = stepIndex === currentStep + 1;
        const isPreviousStep = stepIndex === currentStep - 1;


        // If moving to the next step (step 3, for API test)
        if (isNextStep && currentStep === 3) {
            if(tempTblQueryFull || tempTblQueryDaily){setTestingTmpTblQuery(true);}
            

            try {
                // Reset validity states
                setIsFullQueryValid({ isValid: true, message: '' });
                setIsDailyQueryValid({ isValid: true, message: '' });
            
                let failedQueries = [];
                let fieldsFullTempQuery = [];
                let fieldsDailyTempQuery = [];
                // Process the "full" query first, if it exists
                if (tempTblQueryFull) {
                    const result = await sendTempTableQuery(tempTblQueryFull);
                    console.log(`result:`, result);
                    if (!result.success) {
                        failedQueries.push({ type: "full", message: result.message });
                        setIsFullQueryValid({ isValid: false, message: result.message });
                    }else {
                        fieldsFullTempQuery = result?.data;
                        setIsFullQueryValid({ isValid: true, message: '' });
                    }
                }else{
                    setIsFullQueryValid({ isValid: true, message: '' });
                }
            
                // Process the "daily" query second, if it exists
                if (tempTblQueryDaily) {
                    const result = await sendTempTableQuery(tempTblQueryDaily);
                    if (!result.success) {
                        failedQueries.push({ type: "daily", message: result.message });
                        setIsDailyQueryValid({ isValid: false, message: result.message });
                    }else{
                        fieldsDailyTempQuery = result?.data;
                        setIsDailyQueryValid({ isValid: true, message: '' });
                    }
                }else{
                    setIsDailyQueryValid({ isValid: true, message: '' });
                }
            
                // merge fieldFullTempQuery and fieldsDailyTempQuery
                let mergedTempTblFields = [...fieldsFullTempQuery, ...fieldsDailyTempQuery];

                const transformed = mergedTempTblFields.reduce((result, item) => {
                    const { table, field, type } = item;
                  
                    // If the table doesn't exist in the result object, add it
                    if (!result[table]) {
                      result[table] = [];
                    }
                  
                    // Add the field/type only if it doesn't already exist
                    if (!result[table].some(entry => entry.field === field && entry.type === type)) {
                      result[table].push({ field, type });
                    }
                  
                    return result;
                  }, {});
                  
                //add to the availableDb fields
                setAvailableDb({ ...availableDb, ...transformed });

                setTestingTmpTblQuery(false);
            
                // Check if there are failed queries
                if (failedQueries.length > 0) {
                    setCanProceed(false); // Disable "Next" button
                    setCurrentStep(3); // Stay on the current step
                } else {
                    setCanProceed(true); // Enable "Next" button
                    setCurrentStep(4); // Move to the next step
                }
            } catch (error) {
                // Handle unexpected errors
                setTestingTmpTblQuery(false);
                setCanProceed(false);
                alert("An error occurred while testing the query. Please try again.");
                if (SftGlobalData.debug) {
                    console.error("API call error:", error);
                }
            }

        } else if (isNextStep && currentStep === 2 && useReport) {
            // fetch the daily report data
            getReportData();

            // Handle the case where you skip to step 6 when using a report
            setCurrentStep(6); // Skip to step 7 (index 6)
            setCanProceed(false); // Disable "Next" until the API connection is checked
            if(!apiConnectionSuccess){checkApiConnection()};
            checkKlKaartTypes();
        } else if (isPreviousStep && currentStep === 6 && useReport) {
            // Handle the case for navigating backward from step 6
            setCurrentStep(2); // Go back to step 3 (index 2)
            if (canProceedToStep(5)) setCanProceed(true);
        } else if (isNextStep && currentStep === 6 && !useReport) {

            let url = 'https://mail-integrations.softtouch.eu/getQueryObject';
            let getQueryObject = {
                config: buildConfigJson(),
                list_id: listId,
                type: 'daily',
                mailType: selectedIntegration,
            }

            axios.post(url, getQueryObject, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(async response => {
                if (SftGlobalData.debug) {
                    console.log('url', url);
                    console.log('data', getQueryObject);
                    console.log('response', response.data);
                }

                let queryObject = response.data;
                url = await getExampleQuery(queryObject);
                setCurrentStep(stepIndex);

            }).catch(error => {
                if (SftGlobalData.debug) {
                    console.log('url', url);
                    console.log('data', getQueryObject);
                    console.log('error', error);
                }
           
                setCurrentStep(stepIndex-1);
                props.notify(props.t('mail_integration_error_get_query'), 7500, "top-right", props.theme, 1);
            });
        }
        else {
            // Handle regular step change (not involving API call)
            setCurrentStep(stepIndex);

            if (canProceedToStep(stepIndex)) {
                setCanProceed(true);
            } else {
                setCanProceed(false);
                if (isNextStep) {
                    // Additional condition when reaching step 6 or using reports at step 3
                    if (stepIndex === 6 || (useReport && stepIndex === 3)) {
                        if(!apiConnectionSuccess){checkApiConnection()};
                        checkKlKaartTypes();
                    }
                }
            }
        }
    };

    const onAddNewJoin = () => {
        setLeftJoins([...leftJoins, newJoin]);
        setNewJoin('');
    }

    const onAddNewJoinFam = () => {
        setleftJoinsFam([...leftJoinsFam, newJoinFam]);
        setNewJoinFam('');
    }

    const handleDeleteJoin = (indexToDelete) => {
        setLeftJoins(leftJoins.filter((_, i) => i !== indexToDelete));
    }; 

    const handleDeleteJoinFam = (indexToDelete) => {
        setleftJoinsFam(leftJoinsFam.filter((_, i) => i !== indexToDelete));
    };

    const onAddNewWhere = () => {
        setWheres([...wheres, newWhere]);
        setNewWhere('');
    }

    const onAddNewWhereFam = () => {
        setWheresFam([...wheresFam, newWhereFam]);
        setNewJoinFam('');
    }

    const handleDeleteWhere = (indexToDelete) => {
        setWheres(wheres.filter((_, i) => i !== indexToDelete));
    }; 

    const handleDeleteWhereFam = (indexToDelete) => {
        setWheresFam(wheresFam.filter((_, i) => i !== indexToDelete));
    };

    const handleSetClientFields = (clientFieldValue, clientFieldId, clientFieldType) => {
        let updatedClientFields = [...clientFields];
        switch (clientFieldType) {
            case "field":
                updatedClientFields[clientFieldId] = {
                    ...updatedClientFields[clientFieldId],
                    field: clientFieldValue
                };
                break;
            case "value":
                updatedClientFields[clientFieldId] = {
                    ...updatedClientFields[clientFieldId],
                    value: clientFieldValue
                };
                break;
            case "delete":
                updatedClientFields.splice(clientFieldId, 1);
                break;
            default:
                break;

        }
        setClientFields(updatedClientFields);
    };

    const onAddNewClientFields = () => {
        setClientFields([...clientFields, { field: newClientMapField, value: newClientMappedField, canBeDeleted: true }]);
        setNewClientMapField('');
        setNewClientMappedField('');
    }

    const handleSetClientFamFields = (clientFieldValue, clientFieldId, clientFieldType) => {
        let updatedClientFamFields = [...clientFamFields];
        switch (clientFieldType) {
            case "field":
                updatedClientFamFields[clientFieldId] = {
                    ...updatedClientFamFields[clientFieldId],
                    field: clientFieldValue
                };
                break;
            case "value":
                updatedClientFamFields[clientFieldId] = {
                    ...updatedClientFamFields[clientFieldId],
                    value: clientFieldValue
                };
                break;
            case "delete":
                updatedClientFamFields.splice(clientFieldId, 1);
                break;
            default:
                break;
        }
        setClientFamFields(updatedClientFamFields);
    };


    const createReport = async () => {
        let url = 'https://mail-integrations.softtouch.eu/getQueryObject';

        let getQueryObject = {
            config: buildConfigJson(),
            list_id: listId,
            type: 'daily'
        }

        axios.post(url, getQueryObject, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (SftGlobalData.debug) {
                console.log('url', url);
                console.log('data', getQueryObject);
                console.log('response', response.data);
            }

            let queryObject = response.data;
            url = SftGlobalData.baseURL_API + 'mailIntegration&action=createReport';
 
            let queries = { full: queryObject.queryObjectFull, daily: queryObject.queryObjectDaily};
    
            try {
                const response = await axios.post(url, { key: props.api, queries: queries, type: selectedIntegration, id: params.id == "+" ? mailIntegrationId : params.id, reportType: 'myreport' }, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    }
                });
                if(SftGlobalData.debug){
                    console.log("url:", url);
                    console.log("response:", response);
                }
    
                if (response.data?.success) {
                    props.notify(props.t('mail_create_report_success'), 2000, "bottom-center", props.theme, 87879); 
                } else {
                    props.notify(props.t('mail_create_report_fail'), 2000, "bottom-center", props.theme, 87879); 
                }
            } catch (error) {
                
            }

        }).catch(error => {
            if (SftGlobalData.debug) {
                console.log('url', url);
                console.log('data', getQueryObject);
                console.log('error', error);
            }
        });
    }

    const buildConfigJson = () => {
        let config = {};
        config.client_main_table = mainTbl;
        config.client_fam_table = mainFamTbl;
        config.client_fields = clientFields;
        config.client_fam_fields = clientFamFields;
        config.client_joins = leftJoins;
        config.client_fam_joins = leftJoinsFam;
        config.client_wheres = wheres;
        config.client_fam_wheres = wheresFam;
        config.use_fam = useFam;
        config.selectedMagFilter = selectedMagFilter;
        config.use_client_type_filter = useClientTypeFilter;
        config.client_type_filter = clientCodeFilters.reduce((acc, filters, index) => {
            if (filters.length > 0) {
                let codegroup = filters[0].codegroep;
                if (codegroup?.startsWith('0')) {
                    codegroup = codegroup.substring(1);
                }
                acc[`client_codes${codegroup}`] = filters.map(x => x.totcode);
            }
            return acc;
        }, {});
        config.fam_use_client_card = famUseClientCard;
        config.tbl_temp_queries_full = tempTblQueryFull;
        config.tbl_temp_queries_daily = tempTblQueryDaily;
        if(SftGlobalData.debug){
            console.log("config:", config);
        }
        return JSON.stringify(config);
    }

    const createOrUpdateMailIntegration = async () => {
        let url = SftGlobalData.baseURL_API + `mailIntegration&action=${params.id && params.id !== '+' ? 'update&id=' + params.id : 'create'}`;

        let postData = {
            key: props.api,
            type: selectedIntegration,
            list_id: listId,
            token: token,
            report_id_full: fullRepId,
            report_id_daily: dailyRepId,
            config: buildConfigJson(),
            active: active ? '1' : '0'
        };

        try {
            const response = await axios.post(url, postData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }
            });

            if (SftGlobalData.debug) {
                console.log("URL", url);
                console.log("response:", response);
            }
     
            if (response.data?.success) {
                props.notify(props.t('savedChanges'), 7500, "top-right", props.theme, 1);
                //get the id of the mailintegration that was created
                if (params.id == "+") {  
                    setMailIntegrationId(response.data.id); //set id for myReport 
                }
            } else {
                props.notify(props.t('errorOccured'), 7500, "top-right", props.theme, 1);
            }
        } catch (error) {
            if(SftGlobalData.debug){
                console.log("error:", error);
            }
            props.notify(props.t('errorOccured'), 7500, "top-right", props.theme, 1);
        }

    }

    return (
        <div className={(props.screenWidth) < 600 ? 'not_expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme : 'expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme} >

            {loading && (
                <div className={"container_list__title " + props.theme} style={{ display: "flex", justifyContent: "space-evenly", marginTop: 5, border: 1 }}>
                    <TailSpin height="40" width="40" color={Colors.sft_blue} ariaLabel='loading' />
                </div>
            )}

            {!loading && (
                <form>
                    <div className={"container_list__title " + props.theme}>
                        <h2>{currentStep > 0 || params.id != "+" ? `Mailintegration - ${selectedIntegration}` : 'Mailintegrations'}</h2>
                    </div>

                    <div className='container_split'>
                        <HorizontalLinearStepper
                            steps={steps}
                            t={props.t}
                            onStepChange={handleStepChange}
                            canProceed={canProceed}
                            activeStep={currentStep}
                            onFinishUrl={"/"+props.lang+"/mail-integrations/"}
                        />
                    </div>

                    {/* Step 0: Selection of Integration */}
                    {currentStep === 0 && (
                        <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', padding: '20px' }}>
                            {integrationTypes.map((integration) => (
                                <MailIntegrationOption
                                    key={integration.value}
                                    name={integration.label}
                                    icon={integration.icon}
                                    onSelect={handleSelectIntegration}
                                    isSelected={selectedIntegration === integration.key}
                                />
                            ))}
                        </div>
                    )}

                    {/* Step 1 and Step 2: Display Icon + Fields */}
                    {(currentStep === 1 || currentStep === 2) && selectedIcon && (
                        <div style={{ display: 'flex', gap: '30px', padding: '20px', alignItems: 'flex-start', margin: '2rem' }}>
                            {/* Left Side: Larger Icon */}
                            <div style={{ width: '25rem', textAlign: 'center' }}>
                                <img src={selectedIcon} alt={`${selectedIntegration} icon`} style={{ width: '100%', height: 'auto' }} />
                            </div>

                            <div style={{ flex: 1, marginLeft: '10rem' }}>
                                {currentStep === 1 && (
                                    <div>
                                        <TextField
                                            minRows={1}
                                            autoComplete="off"
                                            className={props.theme + '_accent _100perc margin_top_10px'}
                                            id="token"
                                            label="Token"
                                            size="small"
                                            sx={props.theme === 'light' ? lightTextStyle : darkTextStyle}
                                            value={token}
                                            onChange={(event) => setToken(event.target.value)}
                                        />
                                        <TextField
                                            minRows={1}
                                            autoComplete="off"
                                            className={props.theme + '_accent _100perc margin_top_10px'}
                                            id="listId"
                                            label="ListId"
                                            size="small"
                                            sx={props.theme === 'light' ? lightTextStyle : darkTextStyle}
                                            value={listId}
                                            onChange={(event) => setListId(event.target.value)}
                                        />
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                            <label className={'_100perc margin_top_10px'} style={{ fontSize: '0.9rem', alignItems: 'center', marginTop: '0.5rem' }}>
                                                <b>{props.t('active')}</b>
                                            </label>
                                            <Switch
                                                checked={active}
                                                className="align_right"
                                                style={{}}
                                                onChange={() => setActive(!active)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </div>
                                    </div>
                                )}


                                {currentStep === 2 && (
                                    <div>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                            <label className={'_100perc margin_top_10px'} style={{ fontSize: '0.9rem', alignItems: 'center', marginTop: '0.5rem' }}>
                                                <b>{props.t('mail_use_report')}</b>
                                            </label>
                                            <Switch
                                                checked={useReport}
                                                className="align_right"
                                                style={{}}
                                                onChange={() => { setUseReport(!useReport); setDailyRepId(0); setFullRepId(0); if(!useReport){setUseClientTypeFilter(); setUseClientMagFilter(false); setSelectedMagFilter([]);}  }}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </div>

                                        {
                                            useReport && (
                                                <>
                                                    <TextField
                                                        minRows={1}
                                                        autoComplete="off"
                                                        className={props.theme + '_accent _100perc margin_top_10px margin_right__5px'}
                                                        id="dailySyncRepId"
                                                        label={props.t('mail_daily_sync_report_id')}
                                                        size="small"
                                                        sx={props.theme === 'light' ? lightTextStyle : darkTextStyle}
                                                        value={dailyRepId}
                                                        onChange={(event) => setDailyRepId(event.target.value)}
                                                    />
                                                    <TextField
                                                        minRows={1}
                                                        autoComplete="off"
                                                        className={props.theme + '_accent _100perc margin_top_10px'}
                                                        id="fullSyncRepId"
                                                        label={props.t('mail_full_sync_report_id')}
                                                        size="small"
                                                        sx={props.theme === 'light' ? lightTextStyle : darkTextStyle}
                                                        value={fullRepId}
                                                        onChange={(event) => setFullRepId(event.target.value)}
                                                    />
                                                </>
                                            )
                                        }

                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                            <label className={'_100perc margin_top_10px'} style={{ fontSize: '0.9rem', alignItems: 'center', marginTop: '0.5rem' }}>
                                                <b>{props.t('mail_use_client_type_filter')}</b>
                                            </label>
                                            <Switch
                                                checked={useClientTypeFilter}
                                                className="align_right"
                                                style={{}}
                                                disabled={useReport}
                                                onChange={() => { setUseClientTypeFilter(!useClientTypeFilter);}}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </div>

                                        {useClientTypeFilter &&
                                            Object.keys(groupedCodes).map((group, index) => (
                                                <Autocomplete
                                                    key={`clientCodeFilter-${index}`}
                                                    multiple
                                                    style={{ margin: "0.3rem" }}
                                                    options={groupedCodes[group] || []} // Options for this group
                                                    getOptionLabel={(option) => `${option.totcode}: ${option.codeoms}`} // Adjust label
                                                    value={clientCodeFilters[index] || []} // Ensure the value matches
                                                    onChange={(event, newValue) => {
                                                        const updatedFilters = [...clientCodeFilters];
                                                        updatedFilters[index] = newValue; // Update selected values
                                                        setClientCodeFilters(updatedFilters);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={`${props.t("mail_client_type_code_filter")} ${group}`}
                                                            variant="outlined"
                                                            size="small"
                                                        />
                                                    )}
                                                />
                                        ))}

                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                            <label className={'_100perc margin_top_10px'} style={{ fontSize: '0.9rem', alignItems: 'center', marginTop: '0.5rem' }}>
                                                <b>{props.t('mail_use_client_mag_filter')}</b>
                                            </label>
                                            <Switch
                                                checked={useClientMagFilter}
                                                className="align_right"
                                                style={{}}
                                                disabled={useReport}
                                                onChange={() => { setUseClientMagFilter(!useClientMagFilter);}}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </div>

                                        {
                                            useClientMagFilter && (
                                            <>
                                                    <Select  id="mail_klant_mag_filter"
                                                    className={props.theme+"_accent "+props.theme+"_text _100perc"}
                                                    label={props.t('mail_klant_mag_filter')}
                                                    value={selectedMagFilter}
                                                    onChange={(event)=>{setSelectedMagFilter(event.target.value)}}
                                                    style={{ maxHeight: 43 }}
                                                    multiple
                                                    renderValue={(selected) => {
                                                        if (!selected)
                                                            return "";
                                                        return selected?.sort((a, b) => a.localeCompare(b))?.join(",");
                                                    }}>
                                                        {availableStores?.filter(store => {
                                                            return !selectedMagFilter?.includes(store);
                                                        })?.map((row, index) => {
                                                            return (
                                                                <MenuItem value={row?.code} name={`${row?.code}: ${row?.oms}`} key={index}>
                                                                    <ListItemText
                                                                        primary={row?.code}
                                                                        secondary={row?.oms}
                                                                    />
                                                                </MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                              </>
                                            )
                                                   
                                        }
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {currentStep === 3 && (
                        <div style={{ padding: '20px', alignItems: 'flex-start', margin: '2rem' }}>
                            {testingTmpTblQuery ? (
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                        <TailSpin height="40" width="40" color={Colors.sft_blue} ariaLabel="loading" />
                                        <span style={{ color: Colors.sft_blue, fontSize: '1.2rem' }}>{tempTblQueryFull && tempTblQueryDaily ? 'Testing Queries' : 'Testing Query'}</span>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {failedTestingTmpTblQuery && (
                                        <div className="error_text text_center">{props.t('mail_failed_to_test_query')}</div>
                                    )}
                                    <h4 className="error_text text_center">{props.t('mail_make_sure_tbls_start_with_t_mail')}</h4>

                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', padding: '20px', margin: '2rem' }}>
                                        {/* Full Query Section */}
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', flex: 1 }}>
                                        <TextField
                                                required
                                                multiline
                                                minRows={6}
                                                error= {!isFullQueryValid.isValid}
                                                helperText={isFullQueryValid.message}
                                                autoComplete="off" 
                                                className={props.theme+ '_accent log__100_perc margin_right__10px'}
                                                id="remark"
                                                label={props.t('mail_temp_tbl_full')}
                                                size={'small'}
                                                sx={(props.theme==='light') ? {
                                                    '& .MuiOutlinedInput-input': {
                                                        color: Colors.light_text
                                                        },
                                                    '& .MuiInputLabel-root': {
                                                        color: Colors.light_text
                                                        }, borderRadius: 3, marginTop: 0} 
                                                    :
                                                    {
                                                    '& .MuiOutlinedInput-input': {
                                                        color: Colors.dark_text
                                                        },
                                                        '& .MuiInputLabel-root': {
                                                            color: Colors.dark_text_accent
                                                        }, borderRadius: 3, marginTop: 0}}
                                                value={tempTblQueryFull}
                                                onChange={(event)=>{setTempTblQueryFull(event.target.value)}}
                                                />
                                        </div>

                                        {/* Daily Query Section */}
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', flex: 1 }}>      
                                        <TextField
                                                required
                                                multiline
                                                minRows={6}
                                                error= {!isDailyQueryValid.isValid}
                                                helperText={isDailyQueryValid.message}
                                                autoComplete="off" 
                                                className={props.theme+ '_accent log__100_perc margin_right__10px'}
                                                id="remark"
                                                label={props.t('mail_temp_tbl_daily')}
                                                size={'small'}
                                                sx={(props.theme==='light') ? {
                                                    '& .MuiOutlinedInput-input': {
                                                        color: Colors.light_text
                                                        },
                                                    '& .MuiInputLabel-root': {
                                                        color: Colors.light_text
                                                        }, borderRadius: 3, marginTop: 0} 
                                                    :
                                                    {
                                                    '& .MuiOutlinedInput-input': {
                                                        color: Colors.dark_text
                                                        },
                                                        '& .MuiInputLabel-root': {
                                                            color: Colors.dark_text_accent
                                                        }, borderRadius: 3, marginTop: 0}}
                                                value={tempTblQueryDaily}
                                                onChange={(event)=>{setTempTblQueryDaily(event.target.value)}}
                                                />
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>


                    )}

                    {currentStep === 4 && (
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', padding: '20px', margin: '2rem' }}>
                            <div style={{ width: '100%' }}>
                                <Autocomplete
                                    required
                                    freeSolo={true} // Allows custom values
                                    id="mainTbl-select"
                                    className={props.theme + '_accent log__100_perc margin_right__10px'}
                                    size="small"
                                    sx={
                                        props.theme === 'light'
                                        ? {
                                            '& .MuiOutlinedInput-input': { color: Colors.light_text },
                                            '& .MuiInputLabel-root': { color: Colors.light_text },
                                            borderRadius: 3,
                                            marginTop: 0,
                                            }
                                        : {
                                            '& .MuiOutlinedInput-input': { color: Colors.dark_text },
                                            '& .MuiInputLabel-root': { color: Colors.dark_text_accent },
                                            borderRadius: 3,
                                            marginTop: 0,
                                            }
                                    }
                                    options={availableDb ? Object.keys(availableDb) : []} // Ensure options is always an array
                                    onChange={(event, value, reason) => {
                                        console.log('Selected table:', value); // Log selected value
                                        if (reason === 'selectOption') {
                                        setMainTbl(value); // Update state with selected value
                                        }
                                    }}
                                    value={mainTbl || ''} // Ensure value is controlled and defaults to an empty string
                                    autoHighlight
                                    getOptionLabel={(option) => option || ''} // Ensure getOptionLabel always returns a string
                                    isOptionEqualToValue={(option, value) => option === value} // Simplify comparison for string options
                                    renderOption={(props, option) => (
                                        <Box component="li" {...props}>
                                        {option}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            autoFocus
                                            required
                                            {...params}
                                            label={props.t('mail_main_table')}
                                            inputProps={{
                                                ...params.inputProps,
                                            }}
                                            onChange={(event) => setMainTbl(event.target.value)}
                                        />
                                    )}
                                />
                            </div>

                            <div style={{ width: '100%' }}>
                                <h2 style={{ textAlign: 'center' }}>{props.t('mail_client_fields')}</h2>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginLeft: '6rem', marginRight: '6rem' }}>
                                    {clientFields.map((field, i) => (
                                        <div key={i} style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                            <TextField
                                                minRows={1}
                                                autoComplete="off"
                                                className={props.theme + '_accent _100perc margin_top_10px'}
                                                id={`clientMapField-${i}`}
                                                label=""
                                                size="small"
                                                sx={props.theme === 'light' ? lightTextStyle : darkTextStyle}
                                                value={field?.field}
                                                disabled={!field?.canBeDeleted}
                                                onChange={(event) => handleSetClientFields(event.target.value, i, 'field')}
                                                fullWidth
                                            />

                                            <Autocomplete
                                                id={`clientMappedField-${i}`}
                                                freeSolo={true} // Allows custom values
                                                size="small"
                                                className={props.theme + '_accent _100perc margin_top_10px'}
                                                sx={props.theme === 'light' ? lightTextStyle : darkTextStyle}
                                                options={Object.entries(availableDb).flatMap(([key, fields]) =>
                                                    fields.map((field) => `${key}.${field.field}`)
                                                )} // Flatten to get all `key.field` combinations
                                                getOptionLabel={(option) => option} // Directly display the `key.field` combination
                                                value={field?.value || ''} // Ensure the value is controlled and defaults to an empty string
                                                onChange={(event, value) => handleSetClientFields(value, i, 'value')} // Handle value changes
                                                disabled={!field?.canBeDeleted} // Disable based on field properties
                                                renderInput={(params) => (
                                                    <TextField
                                                    {...params}
                                                    label="" // Label can be empty or customized
                                                    disabled={!field?.canBeDeleted} // Disable based on field properties
                                                    onChange={(event) => handleSetClientFields(event.target.value, i, 'value')}
                                                    />
                                                )}
                                            />

                                            {field?.canBeDeleted ? (
                                                <Button
                                                    style={{
                                                        color: props.theme === 'dark' ? Colors.dark_text : Colors.light_text,
                                                        height: "100%",
                                                        borderRadius: "50%",
                                                        justifyContent: "center",
                                                        alignItems: 'center',
                                                        marginTop: '0.5rem'
                                                    }}

                                                    onClick={() => handleSetClientFields('', i, 'delete')}>
                                                    <FontAwesomeIcon icon={faTrash} color={Colors.def_red} size="2x" />
                                                </Button>
                                            )
                                                :
                                                <div style={{ width: "8.6rem" }} />

                                            }
                                        </div>
                                    ))}
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginLeft: '6rem', marginRight: '6rem' }}>
                                        <TextField
                                            minRows={1}
                                            autoComplete="off"
                                            className={props.theme + '_accent _100perc margin_top_10px'}
                                            id={`newClientMapField`}
                                            label=""
                                            size="small"
                                            sx={props.theme === 'light' ? lightTextStyle : darkTextStyle}
                                            value={newClientMapField}
                                            onChange={(event) => setNewClientMapField(event.target.value)}
                                            fullWidth
                                        />
      
                                        <Autocomplete
                                            id={`newClientMappedField`}
                                            freeSolo={true} // Allows custom values
                                            size="small"
                                            className={`${props.theme}_accent _100perc margin_top_10px`}
                                            sx={props.theme === 'light' ? lightTextStyle : darkTextStyle}
                                            options={Object.entries(availableDb).flatMap(([key, fields]) =>
                                                fields.map((field) => `${key}.${field.field}`)
                                            )} // Flatten to get all `key.field` combinations
                                            isOptionEqualToValue={(option, value) => {
                                                // Custom equality test to avoid console errors
                                                return option === value || value === '' || value === null;
                                            }}
                                            getOptionLabel={(option) => option} // Directly display the `key.field` combination
                                            value={newClientMappedField || ''} // Ensure the value is controlled and defaults to an empty string
                                            onChange={(event, newValue) => setNewClientMappedField(newValue)} // Correctly handle value changes
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="" // Label can be empty or customized
                                                    onChange={(event) => setNewClientMappedField(event.target.value)}
                                                />
                                            )}
                                        />


                                        <Button
                                            style={{
                                                color: props.theme === 'dark' ? Colors.dark_text : Colors.light_text,
                                                height: "100%",
                                                borderRadius: "50%",
                                                justifyContent: "center",
                                                alignItems: 'center',
                                                marginTop: '0.5rem'
                                            }}

                                            onClick={() => onAddNewClientFields()}>
                                            <FontAwesomeIcon icon={faPlus} color={Colors.sft_blue} size="2x" />
                                        </Button>
                                    </div>
                                </div>


                                <h2 style={{ textAlign: 'center', marginTop: '2rem' }}>{props.t('mail_left_joins')}</h2>
                                <div style={{ marginLeft: '6rem', marginRight: '6rem' }}>
                                    {leftJoins?.map((join, i) => (
                                        <React.Fragment key={i}>
                                            <span
                                                style={{ color: Colors.sft_blue, fontSize: '1.2rem' }}
                                                onClick={() => { handleDeleteJoin(i) }}
                                            >
                                                {i === 0 ? join : `${join}`}
                                            </span>
                                            <br />
                                        </React.Fragment>
                                    ))}
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginLeft: '6rem', marginRight: '6rem' }}>
                                    <TextField
                                        minRows={1}
                                        autoComplete="off"
                                        className={props.theme + '_accent _100perc margin_top_10px'}
                                        id={`newClientMapField`}
                                        label=""
                                        size="small"
                                        sx={props.theme === 'light' ? lightTextStyle : darkTextStyle}
                                        value={newJoin}
                                        onChange={(event) => setNewJoin(event.target.value)}
                                        fullWidth
                                    />
                                       
                                    <Button
                                        style={{
                                            color: props.theme === 'dark' ? Colors.dark_text : Colors.light_text,
                                            height: "100%",
                                            borderRadius: "50%",
                                            justifyContent: "center",
                                            alignItems: 'center',
                                            marginTop: '0.5rem'
                                        }}
                                        disabled={String(newJoin).trim() === ''}
                                        onClick={() => onAddNewJoin()}>
                                       
                                        <FontAwesomeIcon icon={faPlus} color={Colors.sft_blue} size="2x" />
                                    </Button>
                                </div>
                                <h2 style={{ textAlign: 'center', marginTop: '2rem' }}>{props.t('mail_wheres')}</h2>
                                <div style={{ marginLeft: '6rem', marginRight: '6rem' }}>
                                    {wheres?.map((where, i) => (
                                        <React.Fragment key={i}>
                                            <span
                                                style={{ color: Colors.sft_blue, fontSize: '1.2rem' }}
                                                onClick={() => { handleDeleteWhere(i) }}
                                            >
                                                {i === 0 ?  `WHERE ${where}` : `AND ${where}`}
                                            </span>
                                            <br />
                                        </React.Fragment>
                                    ))}
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginLeft: '6rem', marginRight: '6rem' }}>
                                    <TextField
                                        minRows={1}
                                        autoComplete="off"
                                        className={props.theme + '_accent _100perc margin_top_10px'}
                                        id={`newClientWhere`}
                                        label=""
                                        size="small"
                                        sx={props.theme === 'light' ? lightTextStyle : darkTextStyle}
                                        value={newWhere}
                                        onChange={(event) => setNewWhere(event.target.value)}
                                        fullWidth
                                    />
                                       
                                    <Button
                                        style={{
                                            color: props.theme === 'dark' ? Colors.dark_text : Colors.light_text,
                                            height: "100%",
                                            borderRadius: "50%",
                                            justifyContent: "center",
                                            alignItems: 'center',
                                            marginTop: '0.5rem'
                                        }}
                                        disabled={String(newWhere).trim() === ''}
                                        onClick={() => onAddNewWhere()}>
                                       
                                        <FontAwesomeIcon icon={faPlus} color={Colors.sft_blue} size="2x" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}


                    {currentStep === 5 && (
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', padding: '20px', margin: '2rem' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <label className={'_100perc margin_top_10px'} style={{ fontSize: '0.9rem', alignItems: 'center', marginTop: '0.5rem' }}>
                                    <b>{props.t('use_fam')}</b>
                                </label>
                                <Switch
                                    checked={useFam}
                                    className="align_right"
                                    style={{}}
                                    onChange={() => {
                                        setUseFam(!useFam);
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <label className={'_100perc margin_top_10px'} style={{ fontSize: '0.9rem', alignItems: 'center', marginTop: '0.5rem' }}>
                                    <b>{props.t('famUseClientCard')}</b>
                                </label>
                                <Switch
                                    checked={famUseClientCard}
                                    className="align_right"
                                    style={{}}
                                    onChange={() => setFamUseClientCard(!famUseClientCard)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </div>
                            {useFam && (
                                <>
                                    <div style={{ width: '100%' }}>
                                        <Autocomplete
                                            required
                                            id="mainFamTbl-select"
                                            freeSolo={true} // Allows custom values
                                            className={props.theme + '_accent log__100_perc margin_right__10px'}
                                            size="small"
                                            sx={
                                                props.theme === 'light'
                                                ? {
                                                    '& .MuiOutlinedInput-input': { color: Colors.light_text },
                                                    '& .MuiInputLabel-root': { color: Colors.light_text },
                                                    borderRadius: 3,
                                                    marginTop: 0,
                                                    }
                                                : {
                                                    '& .MuiOutlinedInput-input': { color: Colors.dark_text },
                                                    '& .MuiInputLabel-root': { color: Colors.dark_text_accent },
                                                    borderRadius: 3,
                                                    marginTop: 0,
                                                    }
                                            }
                                            options={availableDb ? Object.keys(availableDb) : []} // Ensure options is always an array
                                            onChange={(event, value, reason) => {
                                                console.log('Selected table:', value); // Log selected value
                                                if (reason === 'selectOption') {
                                                    setMainFamTbl(value); // Update state with selected value
                                                }
                                            }}
                                            value={mainFamTbl || ''} // Ensure value is controlled and defaults to an empty string
                                            autoHighlight
                                            getOptionLabel={(option) => option || ''} // Ensure getOptionLabel always returns a string
                                            isOptionEqualToValue={(option, value) => option === value} // Simplify comparison for string options
                                            renderOption={(props, option) => (
                                                <Box component="li" {...props}>
                                                {option}
                                                </Box>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    autoFocus
                                                    required
                                                    {...params}
                                                    label={props.t('main_fam_table')}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                    }}
                                                    onChange={(event) => setMainFamTbl(event.target.value)}
                                            />
                                        )}
                                    />
                                    </div>

                                    <div style={{ width: '100%' }}>
                                        <h2 style={{ textAlign: 'center' }}>{props.t('mail_client_fam_fields')}</h2>
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginLeft: '6rem', marginRight: '6rem' }}>
                                            {clientFamFields.map((field, i) => (
                                                <div key={i} style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                    <TextField
                                                        minRows={1}
                                                        autoComplete="off"
                                                        className={props.theme + '_accent _100perc margin_top_10px'}
                                                        id={`clientMapField-${i}`}
                                                        label=""
                                                        size="small"
                                                        sx={props.theme === 'light' ? lightTextStyle : darkTextStyle}
                                                        value={field?.field}
                                                        disabled={true}
                                                        onChange={(event) => handleSetClientFamFields(event.target.value, i, 'field')}
                                                        fullWidth
                                                    />
                                                    <Autocomplete
                                                        id={`clientMappedField-${i}`}
                                                        freeSolo={true} // Allows custom values
                                                        size="small"
                                                        className={`${props.theme}_accent _100perc margin_top_10px`}
                                                        sx={props.theme === 'light' ? lightTextStyle : darkTextStyle}
                                                        options={Object.entries(availableDb).flatMap(([key, fields]) =>
                                                            fields.map((field) => `${key}.${field.field}`)
                                                        )} // Flatten to get all `key.field` combinations
                                                        isOptionEqualToValue={(option, value) => {
                                                            // Custom equality test to avoid console errors
                                                            return option === value || value === '' || value === null;
                                                        }}
                                                        getOptionLabel={(option) => option} // Directly display the `key.field` combination
                                                        value={field?.value || ''} // Ensure the value is controlled and defaults to an empty string
                                                        onChange={(event, newValue) => handleSetClientFamFields(newValue, i, 'value')} // Correctly handle value changes
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="" // Label can be empty or customized
                                                                onChange={(event) => handleSetClientFamFields(event?.target?.value, i, 'value')}
                                                            />
                                                        )}
                                                    />

                                                </div>
                                            ))}
                                        </div>


                                        <h2 style={{ textAlign: 'center', marginTop: '2rem' }}>{props.t('mail_left_joins')}</h2>
                                            <div style={{ marginLeft: '6rem', marginRight: '6rem' }}>
                                                {leftJoinsFam?.map((join, i) => (
                                                    <React.Fragment key={i}>
                                                        <span
                                                            style={{ color: Colors.sft_blue, fontSize: '1.2rem' }}
                                                            onClick={() => { handleDeleteJoinFam(i) }}
                                                        >
                                                            {i === 0 ? join : `${join}`}
                                                        </span>
                                                        <br />
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginLeft: '6rem', marginRight: '6rem' }}>
                                                <TextField
                                                    minRows={1}
                                                    autoComplete="off"
                                                    className={props.theme + '_accent _100perc margin_top_10px'}
                                                    id={`newClientMapField`}
                                                    label=""
                                                    size="small"
                                                    sx={props.theme === 'light' ? lightTextStyle : darkTextStyle}
                                                    value={newJoinFam}
                                                    onChange={(event) => setNewJoinFam(event.target.value)}
                                                    fullWidth
                                                />
                                                <Button
                                                    style={{
                                                        color: props.theme === 'dark' ? Colors.dark_text : Colors.light_text,
                                                        height: "100%",
                                                        borderRadius: "50%",
                                                        justifyContent: "center",
                                                        alignItems: 'center',
                                                        marginTop: '0.5rem'
                                                    }}
                                                    disabled={String(newJoinFam).trim() === ''}
                                                    onClick={() => onAddNewJoinFam()}>
                                                    <FontAwesomeIcon icon={faPlus} color={Colors.sft_blue} size="2x" />
                                                </Button>
                                            </div>

                                            <h2 style={{ textAlign: 'center', marginTop: '2rem' }}>{props.t('mail_wheres')}</h2>
                                            <div style={{ marginLeft: '6rem', marginRight: '6rem' }}>
                                                {wheres?.map((where, i) => (
                                                    <React.Fragment key={i}>
                                                        <span
                                                            style={{ color: Colors.sft_blue, fontSize: '1.2rem' }}
                                                            onClick={() => { handleDeleteWhereFam(i) }}
                                                        >
                                                            {i === 0 ?  `WHERE ${where}` : `AND ${where}`}
                                                        </span>
                                                        <br />
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginLeft: '6rem', marginRight: '6rem' }}>
                                                <TextField
                                                    minRows={1}
                                                    autoComplete="off"
                                                    className={props.theme + '_accent _100perc margin_top_10px'}
                                                    id={`newClientFamWhere`}
                                                    label=""
                                                    size="small"
                                                    sx={props.theme === 'light' ? lightTextStyle : darkTextStyle}
                                                    value={newWhereFam}
                                                    onChange={(event) => setNewWhereFam(event.target.value)}
                                                    fullWidth
                                                />
                                                
                                                <Button
                                                    style={{
                                                        color: props.theme === 'dark' ? Colors.dark_text : Colors.light_text,
                                                        height: "100%",
                                                        borderRadius: "50%",
                                                        justifyContent: "center",
                                                        alignItems: 'center',
                                                        marginTop: '0.5rem'
                                                    }}
                                                    disabled={String(newWhereFam).trim() === ''}
                                                    onClick={() => onAddNewWhereFam()}>
                                                
                                                    <FontAwesomeIcon icon={faPlus} color={Colors.sft_blue} size="2x" />
                                                </Button>
                                            </div>
                                    </div>
                                </>
                            )}

                        </div>
                    )}

                {currentStep === 6 && (
                <div style={{ textAlign: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', padding: '10px' }}>
                    
                    {/* Testing Query */}
                    {testingExampleQuery ? (
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <TailSpin height="40" width="40" color={Colors.sft_blue} ariaLabel="loading" />
                            <span style={{ color: Colors.sft_blue, fontSize: '1.2rem' }}>Testing Query</span>
                        </div>
                        </div>
                    ) : (
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

                        {/* Report Fetching */}
                        {useReport && (
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', margin: '1rem' }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                {fetchingReports ? (
                                <TailSpin height="40" width="40" color={Colors.sft_blue} ariaLabel="loading" />
                                ) : (
                                <FontAwesomeIcon icon={faCheck} color={Colors.sft_blue} size="2x" />
                                )}
                                <span style={{ color: Colors.sft_blue, fontSize: '1.2rem' }}>{props.t('mail_fetch_report_data')}</span>
                            </div>
                            </div>
                        )}

                        {/* API Connection Status */}
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px', margin: '1rem' }}>
                            {checkingApiConnection ? (
                            <TailSpin height="40" width="40" color={Colors.sft_blue} ariaLabel="loading" />
                            ) : apiConnectionSuccess ? (
                            <FontAwesomeIcon icon={faCheck} color={Colors.sft_blue} size="2x" />
                            ) : (
                            <FontAwesomeIcon icon={faTimes} color={Colors.def_red} size="2x" />
                            )}
                            <span style={{ color: Colors.sft_blue, fontSize: '1.2rem' }}>{props.t('mail_test_api_connection')}</span>
                        </div>

                        {/* Retry API Connection */}
                        {!checkingApiConnection && (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                color="inherit"
                                variant="outlined"
                                sx={{
                                borderRadius: 3,
                                border: `2px solid ${Colors.sft_blue}`,
                                color: Colors.sft_blue,
                                }}
                                onClick={checkApiConnection}
                            >
                                {props.t('retry api connection')}
                            </Button>
                            </div>
                        )}

                        {/* Klkaart Types */}
                        <div style={{ display: 'flex', justifyContent: 'center', gap: '2rem', marginTop: '1rem' }}>
                            {checkingKlKaart ? (
                            <>
                                <TailSpin height="40" width="40" color={Colors.sft_blue} ariaLabel="loading" />
                                <span style={{ color: Colors.sft_blue, fontSize: '1.2rem' }}>{props.t('mail_check_klkaart_types')}</span>
                            </>
                            ) : (
                            <>
                                {/* Supported Klkaart Types */}
                                {klkaartTypes.filter((type) => allowedKlkaartTypes.includes(parseInt(type))).length > 0 && (
                                <div style={{ textAlign: 'left' }}>
                                    <span style={{ color: Colors.sft_blue, fontSize: '1.2rem', marginBottom: '0.5rem', display: 'block' }}>
                                    {props.t('mail_supported_klkaart_types')}: {klkaartTypes.filter((type) => allowedKlkaartTypes.includes(parseInt(type))).join(', ')}
                                    </span>
                                </div>
                                )}
                            {/* <div style={{ textAlign: 'left' }}>
                                        <span style={{ color: Colors.sft_blue, fontSize: '1.2rem', marginBottom: '0.5rem', display: 'block' }}>{props.t('mail_unsupported_klkaart_types')}</span>
                                        <ul style={{ listStyleType: 'disc', paddingLeft: '1rem' }}>
                                            {klkaartTypes.filter((type) => !allowedKlkaartTypes.includes(parseInt(type))).map((type, index) => (
                                                <li key={index}>{type}</li>
                                            ))}
                                        </ul>
                                    </div> */}
                            </>
                            )}
                        </div>
                        </div>
                    )}
                    </div>
                </div>
                )}



                    {currentStep === 7 && (
                        <div style={{ width: '100%' }}>
                            <h2 style={{ textAlign: 'center' }}>{props.t('mail_example_of_query')}</h2>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginLeft: '6rem', marginRight: '6rem' }}>

                                {exampleDataQuery.length > 0 ? (
                                    <DataGridPro
                                        className={props.theme}
                                        columns={gridIntegrationColumns}
                                        rows={exampleDataQuery.map((row, index) => {
                                            // Assuming `row` has fields that match those in `gridIntegrationColumns`
                                            return {
                                                id: index, // You may want to set a unique identifier if `id` is required
                                                ...row    // Spread `row` properties if they match your `gridIntegrationColumns` fields
                                            };
                                        })}
                                        loading={loading}
                                        rowHeight={38}
                                        autoHeight={true}
                                        pagination={true}
                                    />
                                ):
                                <div style={{ display: 'flex', justifyContent: 'center', gap: '2rem', marginTop: '1rem' }}>
                                    <TailSpin height="40" width="40" color={Colors.sft_blue} ariaLabel='loading' />
                                </div>
                            }

                            </div>
                        </div>
                    )}

                    {currentStep === 8 && (
                        <div style={{ width: '100%' }}>
                            <h2 style={{ textAlign: 'center' }}>{props.t('mail_finish')}</h2>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '1rem',
                                    margin: '2rem 6rem'
                                }}
                            >
                                <Button
                                    color="inherit"
                                    variant="outlined"
                                    sx={{
                                        borderRadius: 3,
                                        border: `2px solid ${Colors.sft_blue}`,
                                        color: Colors.sft_blue,
                                    }}
                                    onClick={createOrUpdateMailIntegration}
                                >
                                    {params.id === "+" ? props.t('mail_create') : props.t('mail_update')}
                                </Button>
                                <Button
                                    color="inherit"
                                    variant="outlined"
                                    sx={{
                                        borderRadius: 3,
                                        border: `2px solid ${Colors.sft_blue}`,
                                        color: Colors.sft_blue,
                                    }}
                                    onClick={handleOpenModal}
                                >
                                    {props.t('mail_show_full_query')}
                                </Button>
                                {!useReport && (
                                    <Button
                                        color="inherit"
                                        variant="outlined"
                                        sx={{
                                            borderRadius: 3,
                                            border: `2px solid ${Colors.sft_blue}`,
                                            color: Colors.sft_blue,
                                        }}
                                        onClick={createReport}
                                        disabled={params.id === "+" && mailIntegrationId === 0}
                                    >
                                        {props.t('mail_create_report')}
                                    </Button>
                                )}

                            </div>
                        </div>
                    )}

                    {/* Modal Component */}
                    <Dialog open={isModalOpen} onClose={handleCloseModal} fullWidth maxWidth="md">
                        <DialogTitle>{props.t('mail_full_query_title')}</DialogTitle>
                        <DialogContent>
                            {/* <div
                                style={{
                                    whiteSpace: 'pre-wrap',
                                    wordWrap: 'break-word',
                                    padding: '1rem', // Padding inside the query display area
                                    backgroundColor: '#f5f5f5', // Light background for contrast
                                    borderRadius: '4px', // Rounded corners
                                    maxHeight: '800px', // Max height for scroll
                                    overflowY: 'auto', // Scroll if content overflows
                                    fontFamily: 'monospace', // Monospace font for code-like appearance
                                    fontSize: '14px', // Set font size
                                    lineHeight: '1.5', // Line height for readability
                                    color: '#333', // Dark text for better contrast
                                }}
                            >
                                {fullQuery}
                            </div> */}

                            {(fullQuery !== "") && <CodeBlock 
                                // text={format(fullQuery)}
                                text={fullQuery}
                                language={'sql'}
                                theme={atomOneDark}
                                {...{ showLineNumbers: true, wrapLines: true, codeBlock: true }}
                                />}

                           
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseModal} color="primary">
                                {props.t('close')}
                            </Button>
                        </DialogActions>
                    </Dialog>

                </form>
            )}
        </div>
    );
}

export default MailIntegrationsSetup;

// Styles for light and dark themes
const lightTextStyle = {
    '& .MuiOutlinedInput-input': { color: Colors.light_text },
    '& .MuiInputLabel-root': { color: Colors.light_text_accent },
    borderRadius: 3,
    marginTop: 0,
};

const darkTextStyle = {
    '& .MuiOutlinedInput-input': { color: Colors.dark_text },
    '& .MuiInputLabel-root': { color: Colors.dark_text_accent },
    borderRadius: 3,
    marginTop: 0,
};
