import { useNavigate } from "react-router-dom";
import '../../styles/main.css';
import testLogo from '../../assets/Test.png';
import birthdayLogo from '../../assets/module-icons/ic_birthday.png'
import mobileLogo from '../../assets/module-icons/ic_mobile.png'
import mailIntegrationsLogo from '../../assets/module-icons/ic_mailintegrations.png'
import googleLogo from '../../assets/module-icons/ic_google.png'
import facebookLogo from '../../assets/module-icons/ic_facebook.png'
import integrationsLogo from '../../assets/module-icons/ic_integrations.png'
import excelLogo from '../../assets/module-icons/ic_excel.png'
import usersLogo from '../../assets/module-icons/ic_users.png'
import licenceIcon from '../../assets/module-icons/ic_licence.png';
import shippingIcon from '../../assets/module-icons/ic_shipping.png';
import eticketLogo from '../../assets/module-icons/ic_eticket.png';
import inventoryLogo from '../../assets/mobile-icons/ic_inventory.png'
import settingLogo from '../../assets/mobile-icons/ic_settings.png'
import exactLogo from '../../assets/module-icons/ic_exact.png';
import rmaLogo from '../../assets/module-icons/ic_rma.png';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

const SidebarItem = (props) => {  
    let navigate = useNavigate();
    
    let modIcon = inventoryLogo;
    switch(props.data.module){
        case 'mobile':
            modIcon = mobileLogo;
            break;
        case 'birthday':
            modIcon = birthdayLogo;
            break;
        case 'eticket':
            modIcon = eticketLogo;
            break;
        case 'exact':
            modIcon = exactLogo;    
            break;
        case 'excel':
            modIcon = excelLogo;
            break;
        case 'mail-integrations':
            modIcon = mailIntegrationsLogo;
            break;
        case 'integrations':
            modIcon = integrationsLogo;
            break;
        case 'google':
            modIcon = googleLogo;
            break;
        case 'facebook':
            modIcon = facebookLogo;
            break;
        case 'licence':
            modIcon = licenceIcon;
            break;
        case 'rma':
            modIcon = rmaLogo;
            break;
        case 'settings':
            modIcon = settingLogo;
            break;
        case 'shipping':
            modIcon = shippingIcon;
            break;
        case 'shipping-log':
            modIcon = shippingIcon;
            break;
        case 'users':
            modIcon = usersLogo;
            break;
        default:
            modIcon = testLogo;
            break;
    }

    const clickHandler = () => {
        props.toggleMobileOpen();
        return navigate("/"+props.lang+"/"+props.data.module);
    }

    return (        
        <ListItem className={'sidebaritem_item_' + props.theme} button onClick={()=>{clickHandler()}}>
            {<ListItemIcon className={'sidebaritem_item__img '}>
                {<img src={modIcon} width="30px" alt={props.data.module}/>}
            </ListItemIcon>}            
            <ListItemText className={'sidebaritem_item__title'} primary={props.title} />
      </ListItem>
    );
};

export default SidebarItem;