import React, { useState } from 'react';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Colors from '../../library/Colors';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { InputAdornment, TextField, Button } from '@mui/material';
import { useForm } from 'react-hook-form';

function EmailTableDetail({ row, theme, t }) {
  console.log("row:", row);
  const [pageSize, setPageSize] = useState(10);
  const apiRef = useGridApiRef();

  const gridColumnsDetail = [
    { field: 'id', headerName: 'ID', flex: 0.5, align: "left" },
    { field: 'company_name', headerName: t('customer'), flex: 2, align: "left" },
    { field: 'first_name', headerName: t('firstname'), flex: 1.5, align: "left" },
    { field: 'name', headerName: t('lastname'), flex: 1.5, align: "left" },
    { field: 'email', headerName: t('email'), flex: 2, align: "left" },
    { field: 'email2', headerName: t('email') + ' 2', flex: 2, align: "left" },
    {
      field: 'contains_zoho_mail',
      headerName: 'Contains zoho mail?',
      flex: 1.5,
      align: 'center',
      display: 'flex',
      renderCell: (params) => {
        if (params.row.contains_zoho_mail) {
          return <FontAwesomeIcon size={"2x"} icon={faCheck} color={Colors.def_green} />;
        } else {
          return <FontAwesomeIcon size={"2x"} icon={faTimes} color={Colors.def_red} />;
        }
      }
    },
  ];
  
  const formatRowData = (data) => {
    const client_id_matches = data?.client_id_matches?.split(',') || [];
    const company_name = data?.company_name?.split(',') || [];
    const last_name = data?.name?.split(',') || [];
    const first_name = data?.first_name?.split(',') || [];
    const email = data?.email?.split(',') || [];
    const email2 = data?.email2?.split(',') || [];
    const zoho_emails = data?.zoho_email?.split(',') || [];
  
    return client_id_matches.map((client_id, index) => {
      const trimmedClientId = client_id.trim();
      const companyName = company_name[index]?.trim() || '';
      const firstName = first_name[index]?.trim() || '';
      const name = last_name[index]?.trim() || '';
      const emailAddress = email[index]?.trim().toLowerCase() || '';
      const secondaryEmail = email2[index]?.trim().toLowerCase() || '';

      // Check if any zoho_emails is found in email or email2
      const hasZohoEmail = zoho_emails.some(zohoEmail => {
        // Handle case where zohoEmail might contain multiple emails separated by commas
        const trimmedZohoEmails = zohoEmail.split(',').map(email => email.trim().toLowerCase());
        return trimmedZohoEmails.some(trimmedZohoEmail => 
          [emailAddress, secondaryEmail].some(field => field.includes(trimmedZohoEmail))
        );
      });
  
      return {
        id: trimmedClientId,
        company_name: companyName,
        first_name: firstName,
        name: name,
        email: emailAddress,
        email2: secondaryEmail,
        contains_zoho_mail: hasZohoEmail
      };
    });
  };
  console.log("formatRowData:", formatRowData(row));

  return (
    <div style={{ paddingTop: 20, marginLeft: 50, marginRight: 50 }}>

      {!row.id?.includes('_no_match') &&
        <DataGridPro
          apiRef={apiRef}
          className={theme}
          columns={gridColumnsDetail}
          rows={formatRowData(row)}
          rowHeight={38}
          style={{ maxHeight: 1000 }}
          pagination={false}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 20, 30]}
          disableRowSelectionOnClick={true}
        />
      }
    </div>
  );
}

EmailTableDetail.propTypes = {
  row: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
};

export default EmailTableDetail;
