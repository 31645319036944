import React, { useState, useEffect }from 'react';
import './logging.css';
import axios from 'axios';
import SftGlobalData from '../../library/SftGlobalData';
import Colors from '../../library/Colors';
import { TailSpin } from 'react-loader-spinner';
import { Box, Button, Tab, Tabs, InputAdornment, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import HtmlIcon from '@mui/icons-material/Html';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 1 }}>
            <div height={300}>{children}</div>
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
  
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const LoggingCustomerInfoBlock = (props) =>  {
    const [tab, setTab] = useState(1);
    const [loading, setLoading] = useState(true);
    // Address
    const [customerInfo, setCustomerInfo] = useState({name: "", address: "", city: "", zip: "", country: "BE", tel: "", email: ""});
    // other
    const [memo, setMemo] = useState("");
    const [files, setFiles] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [searchInvoice, setSearchInvoice] = useState("");
    const initialGrid = getCookie("InitialStateFiles") === "{}" ? {columns: {columnVisibilityModel: {created_at: false, updated_at: false, sort: false, id: false},}} : JSON.parse(getCookie("InitialStateFiles"));
    const initialInvoiceGrid = getCookie("InitialStateInvoices") === "{}" ? {columns: {columnVisibilityModel: {updated_at: false, id: false, invoice: false},}} : JSON.parse(getCookie("InitialStateInvoices"));
    const gridColumns = [
        {
            field: 'id',
            headerName: 'ID',
            headerClassName: 'bold',
            minWidth: 50,
            maxWidth: 50,
            align: "left",
        },
        {
            field: 'name',
            headerName: props.t('title_translation'),
            headerClassName: 'bold',
            minWidth: 200,
            width: 200,
            align: "left",
        },
        {
            field: 'file_type',
            headerName: props.t('type'),
            headerClassName: 'bold',
            minWidth: 50,
            width: 50,
            align: "center",
            onClick: (params)=>{/*console.log("test", params);*/},
            renderCell: (params)=>{
                switch(params.row.file_type){
                    case "TXT":
                        return <TextSnippetIcon  style={{color: Colors.def_red}}/>
                    case "IMG":
                        return <ImageIcon style={{color: Colors.def_red}}/>
                    case "VID":
                        return <OndemandVideoIcon style={{color: Colors.def_red}}/>
                    case "PDF":
                        return <PictureAsPdfIcon style={{color: Colors.def_red}}/>
                    case "HTML":
                        return <HtmlIcon style={{color: Colors.def_red}}/>
                    default:
                        return <DownloadIcon style={{color: Colors.def_red}}/>
                }
            }
        },
        {
            field: 'tags',
            headerName: props.t('tags'),
            width: 100,
            align: "left",
        },
        {
            field: 'sort',
            headerName: props.t('sort'),
            width: 50,
            align: "left",
        },
        {
            field: 'created_at',
            headerName: props.t('created_at'),
            headerClassName: 'bold',
            width: 100,
            align: "left",
            //renderCell: (params)=>{return }
        },
        {
            field: 'updated_at',
            headerName: props.t('updated_at'),
            headerClassName: 'bold',
            width: 100,
            align: "left",
            //renderCell: (params)=>{return }
        },
        {
            field: 'delete',
            headerName: props.t('delete'),
            minWidth: 85,
            width: 85,
            align: "center",
            renderCell: (params)=>{return <Button /*onClick={() => {deleteFile(params.row.id);}}*/><DeleteIcon style={{color: Colors.def_red}}/></Button>}
        },
        
        
      ];
    const invoiceColumns = [
        {
            field: 'id',
            headerName: 'ID',
            headerClassName: 'bold',
            minWidth: 50,
            width: 50,
            align: "left",
        },
        {
            field: 'created_at',
            headerName: props.t('created_at'),
            headerClassName: 'bold',
            width: 100,
            align: "left",
            //renderCell: (params)=>{return }
        },
        {
            field: 'invoice',
            headerName: props.t('invoice'),
            headerClassName: 'bold',
            minWidth: 100,
            width: 100,
            align: "left",
        },
        {
            field: 'description',
            headerName: props.t('description'),
            headerClassName: 'bold',
            minWidth: 250,
            width: 250,
            align: "left",
        },
        {
            field: 'quantity',
            headerName: props.t('quantity_short'),
            headerClassName: 'bold',
            headerAlign: 'center',
            minWidth: 75,
            width: 75,
            align: "center",
        },
        {
            field: 'remark',
            headerName: props.t('remark'),
            headerClassName: 'bold',
            minWidth: 250,
            width: 250,
            align: "left",
        },
        {
            field: 'updated_at',
            headerName: props.t('updated_at'),
            headerClassName: 'bold',
            width: 100,
            align: "left",
            //renderCell: (params)=>{return }
        },
        
        
      ];
    const apiRef = useGridApiRef();
    const apiRef2 = useGridApiRef();
    
    
    useEffect(()=>{
        //console.log("adding subscriptions");
        try{
            apiRef.current.subscribeEvent(
                'columnOrderChange',
                ()=>{visibilityChangedHandler();},
            );
        }catch(ex){
    
        }
    }, [apiRef]);

    useEffect(()=>{
        //console.log("adding subscriptions");
        try{
            apiRef2.current.subscribeEvent(
                'columnOrderChange',
                ()=>{visibilityChangedHandler();},
            );
        }catch(ex){
    
        }
    }, [apiRef2]);


    const deleteFile = (id, filename) => {
        if (window.confirm(props.t('confirm_delete_file')) === true) {
            try{
                //console.log("props", props);
                let url = SftGlobalData.baseURL_API+'logging&action=deleteFile';
                let postData = {id: id, sft_id: props.customer?.cust_no, filename: filename, sft: props.login?.sft_status};
                axios.post(url, postData, {
                    headers : {
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                    }})
                    .then(response => {
                        if(SftGlobalData.debug){
                            console.log('url', url);
                            console.log('postData', postData);
                            console.log('resp', response);
                        }
                        props.onRefresh();
                    });
            } catch(e){
                console.error(e);
            }
        }
    }

    function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "{}";
    }

    function setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        //console.log("cookie", cname + "=" + cvalue + ";" + expires + ";path=/");
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    const visibilityChangedHandler = () => {
        let temp = apiRef.current.exportState();
        if(typeof temp.columns.columnVisibilityModel === "undefined"){
            temp.columns.columnVisibilityModel = {};
        }
        try{
            temp.preferencePanel.open = false;
        }catch(e){
    
        }
        try{
            delete temp.pagination;
        }catch(e){
            console.error(e);
        }
        setCookie("InitialStateFiles", JSON.stringify(temp), 100000);
    }
    
    const visibilityChangedHandler2 = () => {
        let temp = apiRef2.current.exportState();
        if(typeof temp.columns.columnVisibilityModel === "undefined"){
            temp.columns.columnVisibilityModel = {};
        }
        try{
            temp.preferencePanel.open = false;
        }catch(e){
    
        }
        try{
            delete temp.pagination;
        }catch(e){
            console.error(e);
        }
        setCookie("InitialStateInvoices", JSON.stringify(temp), 100000);
    }

    const handleOnCellClick = (params) => {
        if(params.field !== "delete"){
            props.openDataModal(params.row.file_type, params.row.filename);
        } else {
            deleteFile(params.row.id, params.row.filename);
        }
    }


    function DataGrid() {
        return (
          <div>
            <DataGridPro
                style={{height: 240, marginTop: 5}}
                apiRef={apiRef}
                className={props.theme}
                initialState={initialGrid}
                columns={gridColumns}
                onColumnWidthChange={()=>{visibilityChangedHandler();}}
                onColumnVisibilityModelChange={()=>{visibilityChangedHandler();}}
                onPreferencePanelClose={()=>{visibilityChangedHandler();}}
                onCellClick={handleOnCellClick}
                rows={files.filter(row => (row.name?.match(new RegExp(searchValue, "i")) || row.filename?.match(new RegExp(searchValue, "i")) || row.tags?.match(new RegExp(searchValue, "i"))))
                .map((row, index) => {return {
                        id: row.id,
                        name: row.name,
                        filename: row.filename,
                        file_type: row.file_type,
                        tags: row.tags,
                        sort: row.sort,
                        created_at: row.created_at,
                        updated_at: row.updated_at
                    }
                })}
                rowHeight={38}
                loading={loading}
                components={{
                    Footer: testcomponent,
                }}
                />
          </div>
        );
    }

    function DataGridInvoices() {
        return (
          <div>
            <DataGridPro
                style={{height: 240, marginTop: 5}}
                apiRef={apiRef2}
                className={props.theme}
                initialState={initialInvoiceGrid}
                columns={invoiceColumns}
                onColumnWidthChange={()=>{visibilityChangedHandler2();}}
                onColumnVisibilityModelChange={()=>{visibilityChangedHandler2();}}
                onPreferencePanelClose={()=>{visibilityChangedHandler2();}}
                rows={invoices.filter(row => (row.description?.match(new RegExp(searchInvoice, "i")) || row.remark?.match(new RegExp(searchInvoice, "i")) || row.invoice?.match(new RegExp(searchInvoice, "i"))))
                .map((row, index) => {return {
                        id: row.id,
                        description: row.description,
                        invoice: row.invoice,
                        remark: row.remark,
                        quantity: row.quantity,
                        created_at: row.created_at,
                        updated_at: row.updated_at
                    }
                })}
                //{...data}
                rowHeight={38}
                loading={loading}
                components={{
                    Footer: testcomponent,
                }}
                />
          </div>
        );
    }

    function testcomponent() {
        return <div></div>
    }

    useEffect (()=>{
        setLoading(true);
        
        axios.post(SftGlobalData.baseURL_API + 'client&action=fetchModules', {key: props.customer.encrypted_key, user: props.username, sftUserId: props.userId, sft: props.login?.sft_status},{
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
        .then(response => {
            if(SftGlobalData.debug){
                console.log('POST', SftGlobalData.baseURL_API + 'client&action=fetchModules');
                console.log('response', response);
            }
            try{
                setMemo(response.data.memo);
            }catch(e){
                console.error(e);
            }
            axios.post(SftGlobalData.baseURL_API + 'logging&action=fetchFiles', {sft_id: props.customer.cust_no, sft: props.login?.sft_status},{
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('POST', SftGlobalData.baseURL_API + 'logging&action=fetchFiles');
                    console.log("PostData", {sft_id: props.customer.cust_no, sft: props.login?.sft_status});
                    console.log('response', response);
                }
                try{
                    setFiles(response.data);
                }catch(e){
                    console.error(e);
                }
                axios.post(SftGlobalData.baseURL_API + 'logging&action=fetchInvoices', {sft_id: props.customer.cust_no, sft: props.login?.sft_status},{
                    headers : {
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                    }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('POST', SftGlobalData.baseURL_API + 'logging&action=fetchInvoices');
                        console.log("PostData", {sft_id: props.customer.cust_no, sft: props.login?.sft_status});
                        console.log('response', response);
                    }
                    try{
                        setInvoices(response.data);
                    }catch(e){
                        console.error(e);
                    }
                    axios.post(SftGlobalData.baseURL_API + 'logging&action=fetchCustomerInfo', {sft_id: props.customer.cust_no, sft: props.login?.sft_status},{
                        headers : {
                            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                        }})
                    .then(response => {
                        if(SftGlobalData.debug){
                            console.log('POST', SftGlobalData.baseURL_API + 'logging&action=fetchCustomerInfo');
                            console.log("PostData", {sft_id: props.customer.cust_no, sft: props.login?.sft_status});
                            console.log('response', response);
                        }
                        try{
                            setCustomerInfo(response.data);
                        }catch(e){
                            console.error(e);
                        }
                        setLoading(false);
                    });
                });
            });

        });
    }, [props.customer, props.refresh, props.login, props.username, props.userId]);

    const saveMemo = () => {
        let url = SftGlobalData.baseURL_API + 'client&action=updateMemo';
        axios.post(url, {
            key: props.customer.encrypted_key,
            memo: memo, user: props.username, 
            sftUserId: props.userId, 
            sft: props.login?.sft_status
        }, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        })
        .then(response => {
            if(SftGlobalData.debug){
                console.log('url', url);
                console.log('response', response);
            }
            if(response.data.message === "Done"){
                props.notify(props.t('savedChanges'), 2000, "top-right", props.theme, 1); 
            } else { 
                props.notify(props.t('errorOccured'), 2000, "top-right", props.theme, 1); 
            }
        });
    }



    return (
        <div style={{width: '100%'}}>
            {loading &&<div style={{display:"flex", justifyContent: "space-evenly", marginTop: 5}}>
                <TailSpin 
                    height="40"
                    width="40"
                    color={Colors.sft_blue}
                    ariaLabel='loading'
                />
            </div>}
            {!loading &&
            <div>
                {
                    // --------------------------------------------------------------------
                    // TABS
                    // --------------------------------------------------------------------
                }
                <Box className={props.theme + ' licence__enivronment'}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className={props.theme + ' container_tabs_below_subtitle'}>
                        <Tabs value={tab} onChange={(event, newValue) => {setTab(newValue);}} className={props.theme} variant="scrollable" scrollButtons="auto">
                            <Tab label={props.t('address')} {...a11yProps(0)}  className={props.theme}/>
                            <Tab label={props.t('memo')} {...a11yProps(1)}  className={props.theme}/>
                            <Tab label={props.t('invoices')} {...a11yProps(2)} className={props.theme}/>
                            <Tab label={props.t('files')} {...a11yProps(3)} className={props.theme}/>
                        </Tabs>
                    </Box>
                    
                    <TabPanel style={{minHeight: 300}} value={tab} index={0}>
                        { 
                            // Cust info
                        }
                        <TextField
                            disabled
                            autoComplete="off" 
                            className={props.theme+ '_accent log__100_perc margin_bottom_10px margin_top_10px'}
                            id="name"
                            label={props.t('name')}
                            size={'small'}
                            sx={(props.theme==='light') ? {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.light_text
                                  },
                                '& .MuiInputLabel-root': {
                                    color: Colors.light_text
                                 }, borderRadius: 3, marginTop: 0} 
                                :
                                {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.dark_text
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.dark_text_accent
                                    }, borderRadius: 3, marginTop: 0}}
                            value={customerInfo.name}
                            onChange={(event)=>{}}
                            />
                        <TextField
                            disabled
                            autoComplete="off" 
                            className={props.theme+ '_accent log__100_perc margin_bottom_10px '}
                            id="address"
                            label={props.t('address')}
                            size={'small'}
                            sx={(props.theme==='light') ? {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.light_text
                                  },
                                '& .MuiInputLabel-root': {
                                    color: Colors.light_text
                                 }, borderRadius: 3, marginTop: 0} 
                                :
                                {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.dark_text
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.dark_text_accent
                                    }, borderRadius: 3, marginTop: 0}}
                            value={customerInfo.address}
                            onChange={(event)=>{}}
                            />
                        <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>
                            <TextField
                                disabled
                                autoComplete="off" 
                                className={props.theme+ '_accent log__100_perc margin_bottom_10px margin_right__5px'}
                                id="zip"
                                label={props.t('zip')}
                                size={'small'}
                                sx={(props.theme==='light') ? {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.light_text
                                      },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.light_text
                                     }, borderRadius: 3, marginTop: 0} 
                                    :
                                    {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.dark_text
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: Colors.dark_text_accent
                                        }, borderRadius: 3, marginTop: 0}}
                                value={customerInfo.zip}
                                onChange={(event)=>{}}
                                />

                            <TextField
                                disabled
                                autoComplete="off" 
                                className={props.theme+ '_accent log__100_perc margin_bottom_10px margin_right__5px'}
                                id="city"
                                label={props.t('city')}
                                size={'small'}
                                sx={(props.theme==='light') ? {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.light_text
                                      },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.light_text
                                     }, borderRadius: 3, marginTop: 0} 
                                    :
                                    {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.dark_text
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: Colors.dark_text_accent
                                        }, borderRadius: 3, marginTop: 0}}
                                value={customerInfo?.city}
                                onChange={(event)=>{}}
                                />

                            <TextField
                                disabled
                                autoComplete="off" 
                                className={props.theme+ '_accent log__100_perc margin_bottom_10px '}
                                id="country"
                                label={props.t('country')}
                                size={'small'}
                                sx={(props.theme==='light') ? {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.light_text
                                      },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.light_text
                                     }, borderRadius: 3, marginTop: 0} 
                                    :
                                    {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.dark_text
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: Colors.dark_text_accent
                                        }, borderRadius: 3, marginTop: 0}}
                                value={customerInfo?.country}
                                onChange={(event)=>{}}
                                />
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>
                            <TextField
                                disabled
                                autoComplete="off" 
                                className={props.theme+ '_accent log__100_perc margin_bottom_10px margin_right__5px'}
                                id="email"
                                label={props.t('email')}
                                size={'small'}
                                sx={(props.theme==='light') ? {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.light_text
                                      },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.light_text
                                     }, borderRadius: 3, marginTop: 0} 
                                    :
                                    {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.dark_text
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: Colors.dark_text_accent
                                        }, borderRadius: 3, marginTop: 0}}
                                value={customerInfo?.email}
                                onChange={(event)=>{}}
                                />

                            <TextField
                                disabled
                                autoComplete="off" 
                                className={props.theme+ '_accent log__100_perc margin_bottom_10px '}
                                id="tel"
                                label={props.t('phone')}
                                size={'small'}
                                sx={(props.theme==='light') ? {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.light_text
                                      },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.light_text
                                     }, borderRadius: 3, marginTop: 0} 
                                    :
                                    {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.dark_text
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: Colors.dark_text_accent
                                        }, borderRadius: 3, marginTop: 0}}
                                value={customerInfo?.tel}
                                onChange={(event)=>{}}
                                />
                        </div>
                    </TabPanel>
                    <TabPanel style={{minHeight: 300}} value={tab} index={1}>
                        { 
                            // Memo
                        }
                        <textarea className={(props.theme==='light') ? 'light_accent_darker textarea' : 'dark_accent_darker textarea'} id="memo" onChange={(event)=>{setMemo(event.target.value)}} name="memo" value={memo} style={{resize: 'none', minHeight: 245, marginBottom: 21}}/>
                        <Button 
                                variant="outlined" 
                                sx={{ borderRadius: 3, border: '2px solid '+Colors.sft_blue, color: Colors.sft_blue, width: '100%'}}
                                className=" " 
                                onClick={()=>{saveMemo()}}>{props.t('save')}</Button>
                    </TabPanel>
                    <TabPanel style={{minHeight: 300}} value={tab} index={2}>
                        {
                            // Files
                        }
                        <div className='log__container_options' style={{flexDirection: 'column'}}>
                            <TextField
                                id="input-with-icon-textfield"
                                autoComplete="off" 
                                className={props.theme+ '_accent margin_right__10px'}
                                sx={{ borderRadius: 3, marginTop: 0 }}
                                size={'small'}
                                fullWidth
                                type="search"
                                placeholder={props.t('search')}
                                onChange={(e) => setSearchInvoice(e.target.value)}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <SearchIcon style={{color: Colors.sft_blue}} />
                                    </InputAdornment>
                                  ),
                                }}
                           />
                            {DataGridInvoices()}
                        </div>
                    </TabPanel>
                    <TabPanel style={{minHeight: 300}} value={tab} index={3}>
                        {
                            // Files
                        }
                        <div className='log__container_options' style={{flexDirection: 'column'}}>
                            <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>
                                <TextField
                                    id="input-with-icon-textfield"
                                    autoComplete="off" 
                                    className={props.theme+ '_accent margin_right__10px'}
                                    sx={{ borderRadius: 3, marginTop: 0 }}
                                    size={'small'}
                                    fullWidth
                                    type="search"
                                    placeholder={props.t('search')}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <SearchIcon style={{color: Colors.sft_blue}} />
                                        </InputAdornment>
                                      ),
                                    }}
                               />
                                <Button 
                                    variant="outlined" 
                                    tabIndex="-1"
                                    sx={{ borderRadius: 3, border: '2px solid '+Colors.sft_blue, color: Colors.sft_blue, paddingLeft: 3, paddingRight: 3}}
                                    className="log__small_button_width" 
                                    onClick={()=>{props.openFileModal();}}>
                                    <AddIcon style={{Color: Colors.sft_blue}}/>
                                </Button>
                               
                           </div>
                            {DataGrid()}
                        </div>
                    </TabPanel>
                </Box>
            </div>}
        </div>
    );
}

export default LoggingCustomerInfoBlock;
