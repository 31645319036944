import React, { useState, useEffect } from 'react';
import '../../screens/logging/logging.css';
import axios from 'axios';
import SftGlobalData from '../../library/SftGlobalData';
import Colors from '../../library/Colors';
import 'react-toastify/dist/ReactToastify.css';
import { DataGridPro, useGridApiContext, useGridApiRef } from '@mui/x-data-grid-pro';
import { Box, Button, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { styled } from '@mui/system';
import ModalUnstyled from '@mui/base/ModalUnstyled';

const StyledModal = styled(ModalUnstyled)`
    position: fixed;
    text-align: center;
    z-index: 1300;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    `;

const Backdrop = styled('div')`
    z-index: -1;
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
    `;

const style = {
    border: '0px solid #000',
    borderRadius: 6,
    p: 2,
    px: 4,
    pb: 3,
};

function CustomEditComponent(props) {
    const { id, field, value } = props;
    const apiRef = useGridApiContext();
  
    const handleValueChange = (event) => {
        if(props.row.user_id === props.user){
            const newValue = event.target.value; // The new value entered by the user
            apiRef.current.setEditCellValue({ id, field, value: newValue });
        }
    };

    const saveValueChange = () => {
        //console.log("orig", props.orig);
        //console.log("value", value);
        //if(props.orig !== value){
            if (window.confirm("Save changes?") === true) {
                try{
                    //console.log("props", props);
                    let url = SftGlobalData.baseURL_API+'logging&action=updateLog';
                    let postData = {id: props.row.id, field: props.sql, value: value.replaceAll("\\", "\\\\'").replaceAll("'", "\\'"), user_id: props.row.user_id, sft: props.login?.sft_status};
                    axios.post(url, postData, {
                        headers : {
                            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                        }})
                        .then(response => {
                            if(SftGlobalData.debug){
                                console.log('url', url);
                                console.log('postData', postData);
                                console.log('resp', response);
                            }
                            props.reload();
                        });
                } catch(e){
                    console.error(e);
                }
            } else {
                apiRef.current.setEditCellValue({ id, field, value: props.orig });
            }
        //}
    }

    const handleEnter = (e) => {
        if(e.keyCode === 13){
            saveValueChange();
        }
    }
  
    return <input type="text" value={value} onChange={handleValueChange} onKeyDown={(event)=>{handleEnter(event);}} onBlur={()=>{saveValueChange()}}/>;
  }

const LoggingTable = (props) =>  {
    const [pageSize, setPageSize] = useState(10);
    const [modal, setModal] = useState(false);
    const [selectedLog, setSelectedLog] = useState({});
    const [searchValue, setSearchValue] = useState("");
    const gridColumns = [
        {
            field: 'log_date',
            headerName: props.t('date'),
            headerClassName: 'bold',
            minWidth: 150,
            width: 150,
            align: "left",
            display: "flex",
            renderCell: (params)=>{return <div onClick={() => {setSelectedLog(params.row); setModal(true);}}>{params.row.log_date}</div>}
        },
        {
            field: 'customer',
            headerName: props.t('customer'),
            minWidth: 175,
            width: 225,
            align: "left",
            display: "flex",
        },
        {
            field: 'log_owner',
            headerName: props.t('employee'),
            minWidth: 100,
            width: 110,
            align: "left",
            display: "flex",
        },
        {
            field: 'log_tags',
            headerName: props.t('tags'),
            minWidth: 160,
            align: "left",
            display: "flex",
        },
        {
            field: 'cust_store',
            headerName: props.t('store'),
            minWidth: 175,
            width: 225,
            align: "left",
            display: "flex",
        },
        {
            field: 'log_remark',
            headerName: props.t('problem'),
            width: 500,
            align: "left",
            editable: true,
            display: "flex",
            renderEditCell: (params)=>{return <CustomEditComponent login={props.login} reload={()=>{props.loadLogs();}} orig={params.row.log_remark} sql={"remark"} user={props.userId} {...params}/>}
        },
        {
            field: 'log_time',
            headerName: props.t('duration'),
            minWidth: 75,
            width: 75,
            align: "left",
            display: "flex",
        },
        {
            field: 'caller',
            headerName: props.t('caller'),
            minWidth: 100,
            width: 100,
            align: "left",
            editable: true,
            display: "flex",
            renderEditCell: (params)=>{return <CustomEditComponent login={props.login} reload={()=>{props.loadLogs();}} orig={params.row.caller} sql={"caller"} user={props.userId} {...params}/>}
        },
        {
            field: 'phone',
            headerName: props.t('phone'),
            minWidth: 100,
            width: 100,
            align: "left",
            editable: true,
            display: "flex",
            renderEditCell: (params)=>{return <CustomEditComponent login={props.login} reload={()=>{props.loadLogs();}} orig={params.row.phone} sql={"phone"} user={props.userId} {...params}/>}
        },
        {
            field: 'teamviewer_id',
            headerName: props.t('teamviewer_id'),
            minWidth: 100,
            width: 100,
            align: "left",
            editable: true,
            display: "flex",
            renderEditCell: (params)=>{return <CustomEditComponent login={props.login} reload={()=>{props.loadLogs();}} orig={params.row.teamviewer_id} sql={"teamviewer_id"} user={props.userId} {...params}/>}
        },
        {
            field: 'teamviewer_pass',
            headerName: props.t('teamviewer_pass'),
            minWidth: 100,
            width: 100,
            align: "left",
            editable: true,
            display: "flex",
            renderEditCell: (params)=>{return <CustomEditComponent login={props.login} reload={()=>{props.loadLogs();}} orig={params.row.teamviewer_pass} sql={"teamviewer_ww"} user={props.userId} {...params}/>}
        },
        {
            field: 'priority',
            headerName: props.t('priority'),
            minWidth: 75,
            width: 75,
            align: "left",
            display: "flex",
        },
        {
            field: 'log_solution',
            headerName: props.t('solution'),
            width: 500,
            align: "left",
            editable: true,
            display: "flex",
            renderEditCell: (params)=>{return <CustomEditComponent login={props.login} reload={()=>{props.loadLogs();}} orig={params.row.log_solution} sql={"solution"} user={props.userId} {...params}/>}
        },
        {
            field: 'task',
            headerName: '',
            minWidth: 85,
            width: 85,
            align: "center",
            display: "flex",
            renderCell: (params)=>{return props.userId === params.row.user_id ? <Button onClick={() => {makeTask(params.row.id);}}>{(props.userId === params.row.user_id) && <AssignmentIcon />}</Button> : <div></div>}
        },
      ];
    const apiRef = useGridApiRef();

    const makeTask = (id) => {
        if (window.confirm(props.t('confirm_make_task')) === true) {
            try{
                //console.log("props", props);
                let url = SftGlobalData.baseURL_API+'logging&action=updateLog';
                let postData = {id: id, field: 'completed', value: 0, user_id: props.userId, sft: props.login?.sft_status};
                axios.post(url, postData, {
                    headers : {
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                    }})
                    .then(response => {
                        if(SftGlobalData.debug){
                            console.log('url', url);
                            console.log('postData', postData);
                            console.log('resp', response);
                        }
                        props.loadLogs();
                        props.loadTasks();
                    });
            } catch(e){
                console.error(e);
            }
        }
    }
    
    useEffect(()=>{
        try{
            apiRef.current.subscribeEvent(
                'columnOrderChange',
                ()=>{visibilityChangedHandler();},
            );
        }catch(ex){
    
        }
    }, [apiRef]);

    function setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    const visibilityChangedHandler = () => {
        let temp = apiRef.current.exportState();
        if(typeof temp.columns.columnVisibilityModel === "undefined"){
            temp.columns.columnVisibilityModel = {};
        }
        try{
            temp.preferencePanel.open = false;
        }catch(e){
    
        }
        try{
            delete temp.pagination;
        }catch(e){
            console.error(e);
        }
        setCookie("initialState", JSON.stringify(temp), 100000);
    }

    function DataGrid() {
        return (
          <div>
            <DataGridPro
                apiRef={apiRef}
                className={props.theme}
                initialState={props.initialGrid}
                columns={gridColumns}
                onColumnWidthChange={()=>{visibilityChangedHandler();}}
                onColumnVisibilityModelChange={()=>{visibilityChangedHandler();}}
                onPreferencePanelClose={()=>{visibilityChangedHandler();}}
                rows={props.data.filter(row => (row.customer?.match(new RegExp(searchValue, "i")) || row.log_remark?.match(new RegExp(searchValue, "i")) || row.log_date?.match(new RegExp(searchValue, "i")) || row.log_owner?.match(new RegExp(searchValue, "i")) || row.customer_store?.match(new RegExp(searchValue, "i")) || row.log_tags?.match(new RegExp(searchValue, "i"))))
                .map((row) => {return {
                        id: row.id,
                        customer: row.customer,
                        cust_store_id: row.customer_store_id,
                        cust_store: row.customer_store,
                        log_date: row.log_date,
                        log_tags: row.log_tags.replaceAll("[\"", "").replaceAll("\"", "").slice(0,-1).replaceAll(",", ", "),
                        log_remark: row.log_remark,
                        log_time: row.log_time,
                        log_owner: row.log_owner,
                        log_solution: row.log_solution,
                        caller: row.caller,
                        phone: row.phone,
                        priority: row.priority,
                        teamviewer_id: row.teamviewer_id,
                        teamviewer_pass: row.teamviewer_pass,
                        user_id: row.user_id
                    }
                })}
                rowHeight={38}
                autoHeight={true}
                pagination={true}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 20, 30]}
                loading={props.loading}                
                />
          </div>
        );
    }

    return (
        <div>
            <StyledModal
                aria-labelledby="unstyled-modal-title"
                aria-describedby="unstyled-modal-description"
                open={modal}
                onClose={()=>{setModal(false);}}
                BackdropComponent={Backdrop}
                >
                <Box 
                    sx={style} 
                    style={{minWidth: 750}} 
                    className={props.theme + "  box_fade-in"}>
                    <h2 className={props.theme} >{props.t('log_details')}</h2>
                    <hr/>
                    <div className='text_left log__container_options margin_bottom_5px height_30px'>
                        <p className='_25perc bold'>{props.t('date')}</p>
                        <p className='_75perc'>{selectedLog.log_date}</p>
                    </div>
                    <div className='text_left log__container_options margin_bottom_5px height_30px'>
                        <p className='_25perc bold'>{props.t('customer')}</p>
                        <p className='_75perc'>{selectedLog.customer}</p>
                    </div>
                    <div className='text_left log__container_options margin_bottom_5px height_30px'>
                        <p className='_25perc bold'>{props.t('store')}</p>
                        <p className='_75perc'>{selectedLog.cust_store}</p>
                    </div>
                    <div className='text_left log__container_options margin_bottom_5px height_30px'>
                        <p className='_25perc bold'>{props.t('user')}</p>
                        <p className='_75perc'>{selectedLog.log_owner}</p>
                    </div>
                    <div className='text_left log__container_options margin_bottom_5px height_30px'>
                        <p className='_25perc bold'>{props.t('tags')}</p>
                        <p className='_75perc'>{selectedLog.log_tags}</p>
                    </div>
                    <div className='text_left log__container_options margin_bottom_5px height_30px'>
                        <p className='_25perc bold'>{props.t('duration')}</p>
                        <p className='_75perc'>{selectedLog.log_time}</p>
                    </div>

                    {selectedLog.caller !== "" && <div className='text_left log__container_options margin_bottom_5px height_30px'>
                        <p className='_25perc bold'>{props.t('caller')}</p>
                        <p className='_75perc'>{selectedLog.caller}</p>
                    </div>}
                    {selectedLog.phone !== "" && <div className='text_left log__container_options margin_bottom_5px height_30px'>
                        <p className='_25perc bold'>{props.t('phone')}</p>
                        <p className='_75perc'>{selectedLog.phone}</p>
                    </div>}
                    {selectedLog.teamviewer_id !== "" && <div className='text_left log__container_options margin_bottom_5px height_30px'>
                        <p className='_25perc bold'>{props.t('teamviewer_id')}</p>
                        <p className='_75perc'>{selectedLog.teamviewer_id}</p>
                    </div>}
                    {selectedLog.teamviewer_pass !== "" && <div className='text_left log__container_options margin_bottom_5px height_30px'>
                        <p className='_25perc bold'>{props.t('teamviewer_pass')}</p>
                        <p className='_75perc'>{selectedLog.teamviewer_pass}</p>
                    </div>}

                    <hr/>
                    <div className='text_left margin_bottom_5px log__container_options'>
                        <p className='_25perc bold height_30px'>{props.t('problem')}</p>
                        <p className='_75perc height_30px'>{selectedLog.log_remark === "" ? " - " : selectedLog.log_remark}</p>
                    </div>
                    <div className='text_left margin_bottom_5px log__container_options'>
                        <p className='_25perc bold height_30px'>{props.t('solution')}</p>
                        <p className='_75perc height_30px'>{selectedLog.log_solution === "" ? " - " : selectedLog.log_solution}</p>
                    </div>
                    <hr/>
                </Box>
            </StyledModal>
            <div className='log__container_options'>
                <TextField
                id="input-with-icon-textfield"
                autoComplete="off" 
                className={props.theme+ '_accent ' + (props.screenWidth > 900 ? 'margin_right__10px' : '')}
                sx={{ borderRadius: 3, marginTop: 0 }}
                size={'small'}
                fullWidth
                type="search"
                placeholder={props.t('search')}
                onChange={(e) => setSearchValue(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={{color: Colors.sft_blue}} />
                    </InputAdornment>
                  ),
                }}
               />
                {props.screenWidth > 900 && <Button
                    sx={{ borderRadius: 3, border: '2px solid '+Colors.sft_blue, color: Colors.sft_blue}}
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={() => {props.openModal();}}
                  >
                    {props.t("add")}
                </Button>}                
            </div>            
            {props.screenWidth <= 900 && <Button
                    sx={{ borderRadius: 3, border: '2px solid '+Colors.sft_blue, color: Colors.sft_blue, width: '100%', marginBottom: '10px'}}
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={() => {props.openModal();}}
                  >
                    {props.t("add")}
                </Button>} 

            
            {DataGrid()}
        </div>
    );
}

export default LoggingTable;
