import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import SftGlobalData from '../library/SftGlobalData';
import ReactTooltip from 'react-tooltip';
import { format } from 'sql-formatter';
import { CodeBlock, atomOneDark} from 'react-code-blocks';
import Colors from '../library/Colors';
import { TailSpin } from  'react-loader-spinner';
import Button from '@mui/material/Button';
import FloatingBackButton from '../components/FloatingBackButton';
import FloatingSaveButton from '../components/FloatingSaveButton';


const BirthdayDetailScreen = (props) => {
    let navigate = useNavigate();
    let stores = "";
    let nameInput = "";
    let cc1 = "";
    let cc2 = "";
    let cc3 = "";
    let cc4 = "";
    let cc5 = "";
    let cc6 = "";
    let cc7 = "";
    let cc8 = "";
    let cc9 = "";
    let cc10 = "";
    let cc90 = "";

    const params = useParams();
    // top level
    const [loading, setLoading] = useState(params.listId !== '+');
    const [active, setActive] = useState(0);
    const [name, setName] = useState("List");
    const [smsBirthdayType, setSmsBirthdayType] = useState("DAY");
    const [smsBirthdayScheduled, setSmsBirthdayScheduled] = useState(1);
    const [smsBirthdayTime, setSmsBirthdayTime] = useState("10:00");
    const [smsBirthdayBody, setSmsBirthdayBody] = useState("");
    const [smsBirthdayCustom, setSmsBirthdayCustom] = useState(false);
    const [smsBirthdayReport, setSmsBirthdayReport] = useState(0);
    // sms_filters level
    const [minNumberVisits, setMinNumberVisits] = useState(0);
    const [visitedSinceDays, setVisitedSinceDays] = useState(0);
    const [storeVisited, setStoreVisited] = useState("");
    const [filterCustomerCodes, setFilterCustomerCodes] = useState(0);
    // customerCodes level
    const [CC1, setCC1] = useState("");
    const [CC1Equals, setCC1Equals] = useState(true);
    const [CC2, setCC2] = useState("");
    const [CC2Equals, setCC2Equals] = useState(true);
    const [CC3, setCC3] = useState("");
    const [CC3Equals, setCC3Equals] = useState(true);
    const [CC4, setCC4] = useState("");
    const [CC4Equals, setCC4Equals] = useState(true);
    const [CC5, setCC5] = useState("");
    const [CC5Equals, setCC5Equals] = useState(true);
    const [CC6, setCC6] = useState("");
    const [CC6Equals, setCC6Equals] = useState(true);
    const [CC7, setCC7] = useState("");
    const [CC7Equals, setCC7Equals] = useState(true);
    const [CC8, setCC8] = useState("");
    const [CC8Equals, setCC8Equals] = useState(true);
    const [CC9, setCC9] = useState("");
    const [CC9Equals, setCC9Equals] = useState(true);
    const [CC10, setCC10] = useState("");
    const [CC10Equals, setCC10Equals] = useState(true);
    const [CC90, setCC90] = useState("");
    const [CC90Equals, setCC90Equals] = useState(true);

    // out of scope level
    const [toolTipStores, setToolTipStores] = useState("");
    const [toolTipCC1, setToolTipCC1] = useState("");
    const [toolTipCC2, setToolTipCC2] = useState("");
    const [toolTipCC3, setToolTipCC3] = useState("");
    const [toolTipCC4, setToolTipCC4] = useState("");
    const [toolTipCC5, setToolTipCC5] = useState("");
    const [toolTipCC6, setToolTipCC6] = useState("");
    const [toolTipCC7, setToolTipCC7] = useState("");
    const [toolTipCC8, setToolTipCC8] = useState("");
    const [toolTipCC9, setToolTipCC9] = useState("");
    const [toolTipCC10, setToolTipCC10] = useState("");
    const [toolTipCC90, setToolTipCC90] = useState("");

    const [cc1EqualsColor, setCc1EqualsColor] = useState("green");
    const [cc1EqualsText, setCc1EqualsText] = useState("=");
    const [cc2EqualsColor, setCc2EqualsColor] = useState("green");
    const [cc2EqualsText, setCc2EqualsText] = useState("=");
    const [cc3EqualsColor, setCc3EqualsColor] = useState("green");
    const [cc3EqualsText, setCc3EqualsText] = useState("=");
    const [cc4EqualsColor, setCc4EqualsColor] = useState("green");
    const [cc4EqualsText, setCc4EqualsText] = useState("=");
    const [cc5EqualsColor, setCc5EqualsColor] = useState("green");
    const [cc5EqualsText, setCc5EqualsText] = useState("=");
    const [cc6EqualsColor, setCc6EqualsColor] = useState("green");
    const [cc6EqualsText, setCc6EqualsText] = useState("=");
    const [cc7EqualsColor, setCc7EqualsColor] = useState("green");
    const [cc7EqualsText, setCc7EqualsText] = useState("=");
    const [cc8EqualsColor, setCc8EqualsColor] = useState("green");
    const [cc8EqualsText, setCc8EqualsText] = useState("=");
    const [cc9EqualsColor, setCc9EqualsColor] = useState("green");
    const [cc9EqualsText, setCc9EqualsText] = useState("=");
    const [cc10EqualsColor, setCc10EqualsColor] = useState("green");
    const [cc10EqualsText, setCc10EqualsText] = useState("=");
    const [cc90EqualsColor, setCc90EqualsColor] = useState("green");
    const [cc90EqualsText, setCc90EqualsText] = useState("=");

    const [disableButton, setDisableButton] = useState(false);
    const [query, setQuery] = useState("");

    useEffect(() => {
        if(props.api !== ""){
            let url = SftGlobalData.baseURL_API + 'sms&action=getBirthdayLists';
            let postData = {
                key: props.api,
                user: props.username,
                sftUserId: props.userId,
                listId: params.listId
            };
    
            axios.post(url, postData, {
                    headers : {
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                    }
            })
    
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url',url);
                    console.log('response', response);
                }
                if(typeof response.data?.list?.name !== "undefined"){
                    setName(response.data.list.name);
                    setActive(response.data.list.active);
                    setSmsBirthdayBody(response.data.list.sms_birthday_body);
                    setSmsBirthdayCustom(response.data.list.sms_birthday_custom);
                    setSmsBirthdayReport(response.data.list.sms_birthday_report);
                    setSmsBirthdayScheduled(response.data.list.sms_birthday_scheduled);
                    setSmsBirthdayTime(response.data.list.sms_birthday_time);
                    setSmsBirthdayType(response.data.list.sms_birthday_type);
    
                    try{
                        setMinNumberVisits(response.data.list.sms_filters.minNumberVisits);
                        setVisitedSinceDays(response.data.list.sms_filters.visitedSinceDays);
                        setStoreVisited(response.data.list.sms_filters.storeVisited);
                        setFilterCustomerCodes(response.data.list.sms_filters.filterCustomerCodes);
                        setCC1(response.data.list.sms_filters.customerCodes.CC1);
                        setCC1Equals(response.data.list.sms_filters.customerCodes.CC1Equals);
                        setCC2(response.data.list.sms_filters.customerCodes.CC2);
                        setCC2Equals(response.data.list.sms_filters.customerCodes.CC2Equals);
                        setCC3(response.data.list.sms_filters.customerCodes.CC3);
                        setCC3Equals(response.data.list.sms_filters.customerCodes.CC3Equals);
                        setCC4(response.data.list.sms_filters.customerCodes.CC4);
                        setCC4Equals(response.data.list.sms_filters.customerCodes.CC4Equals);
                        setCC5(response.data.list.sms_filters.customerCodes.CC5);
                        setCC5Equals(response.data.list.sms_filters.customerCodes.CC5Equals);
                        setCC6(response.data.list.sms_filters.customerCodes.CC6);
                        setCC6Equals(response.data.list.sms_filters.customerCodes.CC6Equals);
                        setCC7(response.data.list.sms_filters.customerCodes.CC7);
                        setCC7Equals(response.data.list.sms_filters.customerCodes.CC7Equals);
                        setCC8(response.data.list.sms_filters.customerCodes.CC8);
                        setCC8Equals(response.data.list.sms_filters.customerCodes.CC8Equals);
                        setCC9(response.data.list.sms_filters.customerCodes.CC9);
                        setCC9Equals(response.data.list.sms_filters.customerCodes.CC9Equals);
                        setCC10(response.data.list.sms_filters.customerCodes.CC10);
                        setCC10Equals(response.data.list.sms_filters.customerCodes.CC10Equals);
                        setCC90(response.data.list.sms_filters.customerCodes.CC90);
                        setCC90Equals(response.data.list.sms_filters.customerCodes.CC90Equals);

                        setCc1EqualsColor(response.data.list.sms_filters.customerCodes.CC1Equals ? "green" : "red");
                        setCc1EqualsText(response.data.list.sms_filters.customerCodes.CC1Equals ? ("=") : ("≠"));
                        setCc2EqualsColor(response.data.list.sms_filters.customerCodes.CC2Equals ? "green" : "red");
                        setCc2EqualsText(response.data.list.sms_filters.customerCodes.CC2Equals ? ("=") : ("≠"));
                        setCc3EqualsColor(response.data.list.sms_filters.customerCodes.CC3Equals ? "green" : "red");
                        setCc3EqualsText(response.data.list.sms_filters.customerCodes.CC3Equals ? ("=") : ("≠"));
                        setCc4EqualsColor(response.data.list.sms_filters.customerCodes.CC4Equals ? "green" : "red");
                        setCc4EqualsText(response.data.list.sms_filters.customerCodes.CC4Equals ? ("=") : ("≠"));
                        setCc5EqualsColor(response.data.list.sms_filters.customerCodes.CC5Equals ? "green" : "red");
                        setCc5EqualsText(response.data.list.sms_filters.customerCodes.CC5Equals ? ("=") : ("≠"));
                        setCc6EqualsColor(response.data.list.sms_filters.customerCodes.CC6Equals ? "green" : "red");
                        setCc6EqualsText(response.data.list.sms_filters.customerCodes.CC6Equals ? ("=") : ("≠"));
                        setCc7EqualsColor(response.data.list.sms_filters.customerCodes.CC7Equals ? "green" : "red");
                        setCc7EqualsText(response.data.list.sms_filters.customerCodes.CC7Equals ? ("=") : ("≠"));
                        setCc8EqualsColor(response.data.list.sms_filters.customerCodes.CC8Equals ? "green" : "red");
                        setCc8EqualsText(response.data.list.sms_filters.customerCodes.CC8Equals ? ("=") : ("≠"));
                        setCc9EqualsColor(response.data.list.sms_filters.customerCodes.CC9Equals ? "green" : "red");
                        setCc9EqualsText(response.data.list.sms_filters.customerCodes.CC9Equals ? ("=") : ("≠"));
                        setCc10EqualsColor(response.data.list.sms_filters.customerCodes.CC10Equals ? "green" : "red");
                        setCc10EqualsText(response.data.list.sms_filters.customerCodes.CC10Equals ? ("=") : ("≠"));
                        setCc90EqualsColor(response.data.list.sms_filters.customerCodes.CC90Equals ? "green" : "red");
                        setCc90EqualsText(response.data.list.sms_filters.customerCodes.CC90Equals ? ("=") : ("≠"));
                    }catch(e){
                        if(SftGlobalData.debug){
                            console.log('error', e);
                        }
                    }
                    // Load in tooltip for store ids
                    url = SftGlobalData.baseURL_API + 'sftData';
                    axios.post(url, {key: props.api, type: "stores"}, {
                        headers : {
                            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                        }})
                    .then(response => {
                        if(SftGlobalData.debug){
                            console.log('url', url);
                            console.log('response', response);
                        }
                        let stores = response.data.data;
                        if(stores.length > 0){
                            let toolTipDescr = "";
                            for (let i = 0; i < stores.length; i++) {
                                toolTipDescr += stores[i].code + " - " + stores[i].oms;
                                if(i+1 !== stores.length){
                                    toolTipDescr += "<br/>";
                                }
                            }
                            setToolTipStores(toolTipDescr);
                            let url = SftGlobalData.baseURL_API + 'sftData';
                            let postData = { type: 'customerCodes', key: props.api };
                            axios.post(url, postData, {
                                headers : {
                                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                                }})
                                .then(response => {
                                    if (SftGlobalData.debug) {
                                        console.log("url", url);
                                        console.log("response customerCodes", response.data);
                                    }
                                    let customerCodes = response.data.data;
                                    console.log("customerCodes:", customerCodes);

                                    if(customerCodes.length > 0){
                                        let toolTipDescr1 = "";
                                        let toolTipDescr2 = "";
                                        let toolTipDescr3 = "";
                                        let toolTipDescr4 = "";
                                        let toolTipDescr5 = "";
                                        let toolTipDescr6 = "";
                                        let toolTipDescr7 = "";
                                        let toolTipDescr8 = "";
                                        let toolTipDescr9 = "";
                                        let toolTipDescr10 = "";
                                        let toolTipDescr90 = "";
                                        for (let i = 0; i < customerCodes.length; i++) {
                                            switch(customerCodes[i].codegroep){
                                                case "01":
                                                    toolTipDescr1 += customerCodes[i].totcode + " - " + customerCodes[i].codeoms + "<br/>";
                                                    break;
                                                case "02":
                                                    toolTipDescr2 += customerCodes[i].totcode + " - " + customerCodes[i].codeoms + "<br/>";
                                                    break;
                                                case "03":
                                                    toolTipDescr3 += customerCodes[i].totcode + " - " + customerCodes[i].codeoms + "<br/>";
                                                    break;
                                                case "04":
                                                    toolTipDescr4 += customerCodes[i].totcode + " - " + customerCodes[i].codeoms + "<br/>";
                                                    break;
                                                case "05":
                                                    toolTipDescr5 += customerCodes[i].totcode + " - " + customerCodes[i].codeoms + "<br/>";
                                                    break;
                                                case "06":
                                                    toolTipDescr6 += customerCodes[i].totcode + " - " + customerCodes[i].codeoms + "<br/>";
                                                    break;
                                                case "07":
                                                    toolTipDescr7 += customerCodes[i].totcode + " - " + customerCodes[i].codeoms + "<br/>";
                                                    break;
                                                case "08":
                                                    toolTipDescr8 += customerCodes[i].totcode + " - " + customerCodes[i].codeoms + "<br/>";
                                                    break;
                                                case "09":
                                                    toolTipDescr9 += customerCodes[i].totcode + " - " + customerCodes[i].codeoms + "<br/>";
                                                    break;
                                                case "10":
                                                    toolTipDescr10 += customerCodes[i].totcode + " - " + customerCodes[i].codeoms + "<br/>";
                                                    break;
                                                case "90":
                                                    toolTipDescr90 += customerCodes[i].totcode + " - " + customerCodes[i].codeoms + "<br/>";
                                                    break;
                                                default: break;
                                            }
                                        }
                                        setToolTipCC1(toolTipDescr1);
                                        setToolTipCC2(toolTipDescr2);
                                        setToolTipCC3(toolTipDescr3);
                                        setToolTipCC4(toolTipDescr4);
                                        setToolTipCC5(toolTipDescr5);
                                        setToolTipCC6(toolTipDescr6);
                                        setToolTipCC7(toolTipDescr7);
                                        setToolTipCC8(toolTipDescr8);
                                        setToolTipCC9(toolTipDescr9);
                                        setToolTipCC10(toolTipDescr10);
                                        setToolTipCC90(toolTipDescr90);
                                        ReactTooltip.rebuild();
                                    }
                                    setLoading(false);
                                })
                            .catch(error => {
                                if(SftGlobalData.debug){
                                    console.log('error', error);
                                }
                            })
                        

                        } else {
                            setLoading(false);
                        }
                    });
                } else {
                    setLoading(false);
                }
            });
        }
     },[props.api, params.listId]);


    const changeName = async (event) => {
        setName(event.target.value);
    }

    const changeType = (event) => {
        setSmsBirthdayType(event.target.value);
    }

    const changeDay = (event) => {
        setSmsBirthdayScheduled(event.target.value)
    }

    const changeTime = (event) => {
        setSmsBirthdayTime(event.target.value);
    }

    const changeReport = (event) => {
        setSmsBirthdayReport(event.target.value)
        if(event.target.value != "0"){
            //console.log("DEBUG", "CUSTOM BIRTHDAY");
            setSmsBirthdayCustom(true);
        } else {
            setSmsBirthdayCustom(false);
        }
    }

    const changeVisits = (event) => {
        setMinNumberVisits(event.target.value);
    }

    const changeStores = (event) => {
        setStoreVisited(event.target.value);
    }

    const changeSinceDays = (event) => {
        setVisitedSinceDays(event.target.value);
    }

    const CC1EqualsChangeListener = () => {
        if(!CC1Equals){ 
            setCC1Equals(true);
            setCc1EqualsText("=");
            setCc1EqualsColor("green");
        } else {
            setCC1Equals(false);
            setCc1EqualsText("≠");
            setCc1EqualsColor("red"); 
        }
    }

    const CC2EqualsChangeListener = () => {
        if(!CC2Equals){ 
            setCC2Equals(true);
            setCc2EqualsText("=");
            setCc2EqualsColor("green");
        } else {
            setCC2Equals(false);
            setCc2EqualsText("≠");
            setCc2EqualsColor("red");
        }
    }

    const CC3EqualsChangeListener = () => {
        if(!CC3Equals){ 
            setCC3Equals(true);
            setCc3EqualsText("=");
            setCc3EqualsColor("green");
        } else {
            setCC3Equals(false);
            setCc3EqualsText("≠");
            setCc3EqualsColor("red");
        }
    }
    const CC4EqualsChangeListener = () => {
        if(!CC4Equals){ 
            setCC4Equals(true);
            setCc4EqualsText("=");
            setCc4EqualsColor("green");
        } else {
            setCC4Equals(false);
            setCc4EqualsText("≠");
            setCc4EqualsColor("red");
        }
    }
    const CC5EqualsChangeListener = () => {
        if(!CC5Equals){ 
            setCC5Equals(true);
            setCc5EqualsText("=");
            setCc5EqualsColor("green");
        } else {
            setCC5Equals(false);
            setCc5EqualsText("≠");
            setCc5EqualsColor("red");
        }
    }
    const CC6EqualsChangeListener = () => {
        if(!CC6Equals){ 
            setCC6Equals(true);
            setCc6EqualsText("=");
            setCc6EqualsColor("green");
        } else {
            setCC6Equals(false);
            setCc6EqualsText("≠");
            setCc6EqualsColor("red");
        }
    }
    const CC7EqualsChangeListener = () => {
        if(!CC7Equals){ 
            setCC7Equals(true);
            setCc7EqualsText("=");
            setCc7EqualsColor("green");
        } else {
            setCC7Equals(false);
            setCc7EqualsText("≠");
            setCc7EqualsColor("red");
        }
    }
    const CC8EqualsChangeListener = () => {
        if(!CC8Equals){ 
            setCC8Equals(true);
            setCc8EqualsText("=");
            setCc8EqualsColor("green");
        } else {
            setCC8Equals(false);
            setCc8EqualsText("≠");
            setCc8EqualsColor("red");
        }
    }
    const CC9EqualsChangeListener = () => {
        if(!CC9Equals){ 
            setCC9Equals(true);
            setCc9EqualsText("=");
            setCc9EqualsColor("green");
        } else {
            setCC9Equals(false);
            setCc9EqualsText("≠");
            setCc9EqualsColor("red");
        }
    }
    const CC10EqualsChangeListener = () => {
        if(!CC10Equals){ 
            setCC10Equals(true);
            setCc10EqualsText("=");
            setCc10EqualsColor("green");
        } else {
            setCC10Equals(false);
            setCc10EqualsText("≠");
            setCc10EqualsColor("red");
        }
    }
    const CC90EqualsChangeListener = () => {
        if(!CC90Equals){ 
            setCC90Equals(true);
            setCc90EqualsText("=");
            setCc90EqualsColor("green");
        } else {
            setCC90Equals(false);
            setCc90EqualsText("≠");
            setCc90EqualsColor("red");
        }
    }

    const changeCC1 = (event) => {
        setCC1(event.target.value);
    }

    const changeCC2 = (event) => {
        setCC2(event.target.value);
    }

    const changeCC3 = (event) => {
        setCC3(event.target.value);
    }

    const changeCC4 = (event) => {
        setCC4(event.target.value);
    }

    const changeCC5 = (event) => {
        setCC5(event.target.value);
    }

    const changeCC6 = (event) => {
        setCC6(event.target.value);
    }

    const changeCC7 = (event) => {
        setCC7(event.target.value);
    }

    const changeCC8 = (event) => {
        setCC8(event.target.value);
    }

    const changeCC9 = (event) => {
        setCC9(event.target.value);
    }

    const changeCC10 = (event) => {
        setCC10(event.target.value);
    }

    const changeCC90 = (event) => {
        setCC90(event.target.value);
    }

    const changeBody = (event) => {
        //console.log("text length: ", getBodyLength(event.target.value));
        if(getBodyLength(event.target.value) < 160){
            setSmsBirthdayBody(event.target.value);
        }
    }

    const addToBody = (text) => {
        //console.log("text length: ", getBodyLength(smsBirthdayBody + text));
        if(getBodyLength(smsBirthdayBody + text) < 160){
            setSmsBirthdayBody(smsBirthdayBody + text);
        }
    }

    const getBodyLength = (text) => {        
        let count = text.length;
        let specialCharArray = ['€', '|',  '^', '{', '}', '[', ']', '~', '\\'];
        for (var i = 0; i < text.length; i++){
            if(specialCharArray.includes(text.charAt(i))){
                count++;
            }
        }

        if(text.includes("[title]")){
            count = count + 5;
        } else if(text.includes("[first_name]")){
            count = count + 15;
        } else if(text.includes("[last_name]")){
            count = count + 25;
        } else if(text.includes("[age]")){
            count = count + 3;
        }

        return count;
    }

    const changeUseCustomerCodes = (event) => {
        setFilterCustomerCodes(!filterCustomerCodes);
        ReactTooltip.rebuild();
    }

    const handleSubmit = () => {

        if(!disableButton){
            let url = SftGlobalData.baseURL_API + 'sms&action=createOrEditBirthdayList';
            let postData = {
                key: props.api
            };
            (params.listId === "+") ?  setDisableButton(true) :  postData.listId = params.listId;
            if(SftGlobalData.debug){
                console.log('url', url); 
                console.log("smsBirthdayCustom", smsBirthdayReport == 0 ? 'N' : 'Y');
            }
            postData.listData = {
                name: name,
                active: active,
                sms_birthday_type: smsBirthdayType,
                sms_birthday_scheduled: smsBirthdayScheduled,
                sms_birthday_time: smsBirthdayTime,
                sms_birthday_body: smsBirthdayBody,
                sms_birthday_custom: smsBirthdayReport == "0" ? 'N' : 'Y',
                sms_birthday_report: smsBirthdayReport,
                sms_filters:{
                    minNumberVisits: minNumberVisits,
                    visitedSinceDays: visitedSinceDays,
                    storeVisited: storeVisited,
                    filterCustomerCodes: filterCustomerCodes,
                    customerCodes: {
                        CC1: CC1,
                        CC1Equals: CC1Equals,
                        CC2: CC2,
                        CC2Equals: CC2Equals,
                        CC3: CC3,
                        CC3Equals: CC3Equals,
                        CC4: CC4,
                        CC4Equals: CC4Equals,
                        CC5: CC5,
                        CC5Equals: CC5Equals,
                        CC6: CC6,
                        CC6Equals: CC6Equals,
                        CC7: CC7,
                        CC7Equals: CC7Equals,
                        CC8: CC8,
                        CC8Equals: CC8Equals,
                        CC9: CC9,
                        CC9Equals: CC9Equals,
                        CC10: CC10,
                        CC10Equals: CC10Equals,
                        CC90: CC90,
                        CC90Equals: CC90Equals
                    }
                }
            }
            axios.post(url, postData , {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){console.log('response', response);}
                    if(response.data === "Birthday list updated."){
                        props.notify(props.t('savedChanges'), 7500, "top-right", props.theme, 1); 
                        navigate("/"+props.lang+"/birthday");
                    } else if(response.data === "Birthday list created."){
                        props.notify(props.t('listCreated'), 7500, "top-right", props.theme, 1); 
                        navigate("/"+props.lang+"/birthday");
                    } else { 
                        props.notify(props.t('errorOccured'), 7500, "top-right", props.theme, 1); 
                    }
                    
                });
        } else {
            props.notify(props.t('pleaseReturn'), 7500, "top-right", props.theme, 1); 
        }
        
        
    };

    const getQuery = () => {
        let url = SftGlobalData.baseURL_API + 'sms&action=getBirthdayListQuery';
        let postData = {
            key: props.api,
            data: {
                name: name,
                active: active,
                sms_birthday_type: smsBirthdayType,
                sms_birthday_scheduled: smsBirthdayScheduled,
                sms_birthday_time: smsBirthdayTime,
                sms_birthday_body: smsBirthdayBody,
                sms_birthday_custom: smsBirthdayCustom,
                sms_birthday_report: smsBirthdayReport,
                sms_filters:{
                    minNumberVisits: minNumberVisits,
                    visitedSinceDays: visitedSinceDays,
                    storeVisited: storeVisited,
                    filterCustomerCodes: filterCustomerCodes,
                    customerCodes: {
                        CC1: CC1,
                        CC1Equals: CC1Equals,
                        CC2: CC2,
                        CC2Equals: CC2Equals,
                        CC3: CC3,
                        CC3Equals: CC3Equals,
                        CC4: CC4,
                        CC4Equals: CC4Equals,
                        CC5: CC5,
                        CC5Equals: CC5Equals,
                        CC6: CC6,
                        CC6Equals: CC6Equals,
                        CC7: CC7,
                        CC7Equals: CC7Equals,
                        CC8: CC8,
                        CC8Equals: CC8Equals,
                        CC9: CC9,
                        CC9Equals: CC9Equals,
                        CC10: CC10,
                        CC10Equals: CC10Equals,
                        CC90: CC90,
                        CC90Equals: CC90Equals
                    }
                }
            }
        };

        axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }
        })
        .then(response => {
            if(SftGlobalData.debug){
                console.log('url', url);
                console.log('response get query', response);
            }
            if(response.data !== ""){
                setQuery(response.data.replace("[CUST_MESSAGE]", smsBirthdayBody.replace("'", "\\'")));
            }
        });
    };

    return (            
        <div className={(props.screenWidth) < 600 ? 'not_expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme : 'expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme}>
            <FloatingBackButton disabled={false} onClick={()=>{navigate("/"+props.lang+"/birthday");}} />
            <FloatingSaveButton disabled={loading} onClick={()=>{handleSubmit();}} />
            <form>   
                <div className={"container_list__title " + props.theme}><h2>{props.t('birthdaySMS')} {(name !== "") && <span> - {name}</span>}</h2></div>

                {loading && <div className={"container_list__title " + props.theme} style={{display:"flex", justifyContent: "space-evenly", marginTop: 5, border: 1}}>
                        <TailSpin 
                            height="40"
                            width="40"
                            color={Colors.sft_blue}
                            ariaLabel='loading'
                        />
                    </div>}
                
                {!loading && <div>
                    <fieldset className={(props.theme==='light') ? 'light container_list' : 'dark  container_list'}>
                        <div>
                            <legend className='_100perc'>
                                <span className="number">1</span> {props.t('settings')}
                                <label className="switch align_right">
                                    <input type="checkbox" checked={active} onChange={() => {setActive(!active);}}/>
                                    <span className={"slider slider_" + props.theme + " round"}></span>
                                </label>
                            </legend>
                        </div>
                        <label className='margin_bottom_5px' htmlFor="sms_name">{props.t('listName')}:</label>
                        <input className={(props.theme==='light') ? 'light_accent_darker' : 'dark_accent_darker'} type="text" onChange={changeName} id="sms_name" name="smsName" value={name}/>
                        <label className="margin_bottom_5px" htmlFor="sms_type">{props.t('type')}:</label>
                        <select className={(props.theme==='light') ? 'light_accent_darker _100perc' : 'dark_accent_darker _100perc'} id="sms_type" name="sms_type" onChange={changeType} value={smsBirthdayType}>
                            <option value="DAY">{props.t('DAY')}</option>
                            <option value="WEEK">{props.t('WEEK')}</option>
                            <option value="MONTH">{props.t('MONTH')}</option>       
                        </select>
                        
                        {(smsBirthdayType !== "DAY") && <label htmlFor="sms_day">{props.t('day')}:</label>}
                        {(smsBirthdayType !== "DAY") && <input type="number" onChange={changeDay} id="sms_day" name="sms_day" value={smsBirthdayScheduled}/>}
                        
                        <label htmlFor="sms_time">{props.t('time')}:</label>
                        <input className={(props.theme==='light') ? 'light_accent_darker' : 'dark_accent_darker'} type="time" onChange={changeTime} id="sms_time" name="sms_time" value={smsBirthdayTime}/>

                        <label htmlFor="sms_report">{props.t('report')}: </label>
                        <input className={(props.theme==='light') ? 'light_accent_darker' : 'dark_accent_darker'} type="number" onChange={changeReport} id="sms_report" name="sms_report" value={smsBirthdayReport}/>
                    
                        {smsBirthdayReport != "0" && <Button className="button_container__button _100perc" type="submit">{props.t('save')}</Button>}
                    </fieldset>

                    {smsBirthdayReport == "0" && <fieldset>
                        <legend><span className="number">2</span> {props.t('body')} ({getBodyLength(smsBirthdayBody)}/160)</legend>

                        <label htmlFor="sms_body">{props.t('text')}:</label>
                        <Button className="button_container__button_small width_120px dark_text margin_left__5px margin_bottom_10px" type="button" onClick={()=>{addToBody("[title]"); nameInput.focus();}}>{props.t('sms_title')}</Button>
                        <Button className="button_container__button_small width_120px dark_text margin_left__5px" type="button" onClick={()=>{addToBody("[first_name]"); nameInput.focus();}}>{props.t('sms_first_name')}</Button>
                        <Button className="button_container__button_small width_140px dark_text margin_left__5px" type="button" onClick={()=>{addToBody("[last_name]"); nameInput.focus();}}>{props.t('sms_last_name')}</Button>
                        <Button className="button_container__button_small width_120px dark_text margin_left__5px" type="button" onClick={()=>{addToBody("[age]"); nameInput.focus();}}>{props.t('sms_age')}</Button>
                        <textarea className={(props.theme==='light') ? 'light_accent_darker textarea' : 'dark_accent_darker textarea'} maxLength="160" ref={(input) => { nameInput = input; }}  id="sms_body" onChange={changeBody} name="sms_body" value={smsBirthdayBody}></textarea>
                    </fieldset>}

                    {smsBirthdayReport == "0" && <fieldset>
                        <legend><span className="number">3</span> {props.t('generalFilters')}</legend>

                        <label htmlFor="sms_filter_number_visits">{props.t('minNumberVisits')}:</label>
                        <input className={(props.theme==='light') ? 'light_accent_darker' : 'dark_accent_darker'} onChange={changeVisits} type="number" id="sms_filter_number_visits" name="sms_filter_number_visits" value={minNumberVisits}/>

                        <label htmlFor="sms_filter_days">{props.t('visitedSinceDays')}:</label>
                        <input className={(props.theme==='light') ? 'light_accent_darker' : 'dark_accent_darker'} onChange={changeSinceDays} type="number" id="sms_filter_days" name="sms_filter_days" value={visitedSinceDays}/>

                        <label ref={ref => stores = ref} htmlFor="sms_filter_stores" data-tip={toolTipStores}>{props.t('visitedStores')}:</label>
                        <input className={(props.theme==='light') ? 'light_accent_darker' : 'dark_accent_darker'} onFocus={() => { ReactTooltip.show(stores) }} onChange={changeStores} type="text" id="sms_filter_stores" name="sms_filter_stores" value={storeVisited}/>
                    </fieldset> }

                    {smsBirthdayReport == "0" && <fieldset>
                        <legend className='_100perc'>
                            <span className="number">4</span> {props.t('customerCodeFilters')}
                            <label className="switch align_right">
                                <input type="checkbox" checked={filterCustomerCodes} onChange={changeUseCustomerCodes}/>
                                <span className={"slider slider_" + props.theme + " round"}></span>
                            </label>
                        </legend>
                        

                        {(filterCustomerCodes || filterCustomerCodes === 1)  && <div>
                            <label ref={ref => cc1 = ref} htmlFor="sms_filter_klcode1" data-tip={toolTipCC1}><Button onClick={()=>{CC1EqualsChangeListener()}} className={(cc1EqualsColor === "green") ? "green toggleButtonSmall" : "red toggleButtonSmall"} type="button">{cc1EqualsText}</Button> {props.t('customer')} code 1:</label>
                            <input className={(props.theme==='light') ? 'light_accent_darker' : 'dark_accent_darker'} onFocus={() => { ReactTooltip.show(cc1) }} onChange={changeCC1} type="text" id="sms_filter_klcode1" name="sms_filter_klcode1" value={CC1}/>
                            <label ref={ref => cc2 = ref} data-tip={toolTipCC2} htmlFor="sms_filter_klcode2"><Button onClick={()=>{CC2EqualsChangeListener()}} className={(cc2EqualsColor === "green") ? "green toggleButtonSmall" : "red toggleButtonSmall"} type="button">{cc2EqualsText}</Button> {props.t('customer')} code 2:</label>
                            <input className={(props.theme==='light') ? 'light_accent_darker' : 'dark_accent_darker'} onFocus={() => { ReactTooltip.show(cc2) }} onChange={changeCC2} type="text" id="sms_filter_klcode2" name="sms_filter_klcode2" value={CC2}/>
                            <label ref={ref => cc3 = ref} data-tip={toolTipCC3} htmlFor="sms_filter_klcode3"><Button onClick={()=>{CC3EqualsChangeListener()}} className={(cc3EqualsColor === "green") ? "green toggleButtonSmall" : "red toggleButtonSmall"} type="button">{cc3EqualsText}</Button> {props.t('customer')} code 3:</label>
                            <input className={(props.theme==='light') ? 'light_accent_darker' : 'dark_accent_darker'} onFocus={() => { ReactTooltip.show(cc3) }} onChange={changeCC3} type="text" id="sms_filter_klcode3" name="sms_filter_klcode3" value={CC3}/>
                            <label ref={ref => cc4 = ref} data-tip={toolTipCC4} htmlFor="sms_filter_klcode4"><Button onClick={()=>{CC4EqualsChangeListener()}} className={(cc4EqualsColor === "green") ? "green toggleButtonSmall" : "red toggleButtonSmall"} type="button">{cc4EqualsText}</Button> {props.t('customer')} code 4:</label>
                            <input className={(props.theme==='light') ? 'light_accent_darker' : 'dark_accent_darker'} onFocus={() => { ReactTooltip.show(cc4) }} onChange={changeCC4} type="text" id="sms_filter_klcode4" name="sms_filter_klcode4" value={CC4}/>
                            <label ref={ref => cc5 = ref} data-tip={toolTipCC5} htmlFor="sms_filter_klcode5"><Button onClick={()=>{CC5EqualsChangeListener()}} className={(cc5EqualsColor === "green") ? "green toggleButtonSmall" : "red toggleButtonSmall"} type="button">{cc5EqualsText}</Button> {props.t('customer')} code 5:</label>
                            <input className={(props.theme==='light') ? 'light_accent_darker' : 'dark_accent_darker'} onFocus={() => { ReactTooltip.show(cc5) }} onChange={changeCC5} type="text" id="sms_filter_klcode5" name="sms_filter_klcode5" value={CC5}/>
                            <label ref={ref => cc6 = ref} data-tip={toolTipCC6} htmlFor="sms_filter_klcode6"><Button onClick={()=>{CC6EqualsChangeListener()}} className={(cc6EqualsColor === "green") ? "green toggleButtonSmall" : "red toggleButtonSmall"} type="button">{cc6EqualsText}</Button> {props.t('customer')} code 6:</label>
                            <input className={(props.theme==='light') ? 'light_accent_darker' : 'dark_accent_darker'} onFocus={() => { ReactTooltip.show(cc6) }} onChange={changeCC6} type="text" id="sms_filter_klcode6" name="sms_filter_klcode6" value={CC6}/>
                            <label ref={ref => cc7 = ref} data-tip={toolTipCC7} htmlFor="sms_filter_klcode7"><Button onClick={()=>{CC7EqualsChangeListener()}} className={(cc7EqualsColor === "green") ? "green toggleButtonSmall" : "red toggleButtonSmall"} type="button">{cc7EqualsText}</Button> {props.t('customer')} code 7:</label>
                            <input className={(props.theme==='light') ? 'light_accent_darker' : 'dark_accent_darker'} onFocus={() => { ReactTooltip.show(cc7) }} onChange={changeCC7} type="text" id="sms_filter_klcode7" name="sms_filter_klcode7" value={CC7}/>
                            <label ref={ref => cc8 = ref} data-tip={toolTipCC8} htmlFor="sms_filter_klcode8"><Button onClick={()=>{CC8EqualsChangeListener()}} className={(cc8EqualsColor === "green") ? "green toggleButtonSmall" : "red toggleButtonSmall"} type="button">{cc8EqualsText}</Button> {props.t('customer')} code 8:</label>
                            <input className={(props.theme==='light') ? 'light_accent_darker' : 'dark_accent_darker'} onFocus={() => { ReactTooltip.show(cc8) }} onChange={changeCC8} type="text" id="sms_filter_klcode8" name="sms_filter_klcode8" value={CC8}/>
                            <label ref={ref => cc9 = ref} data-tip={toolTipCC9} htmlFor="sms_filter_klcode9"><Button onClick={()=>{CC9EqualsChangeListener()}} className={(cc9EqualsColor === "green") ? "green toggleButtonSmall" : "red toggleButtonSmall"} type="button">{cc9EqualsText}</Button> {props.t('customer')} code 9:</label>
                            <input className={(props.theme==='light') ? 'light_accent_darker' : 'dark_accent_darker'} onFocus={() => { ReactTooltip.show(cc9) }} onChange={changeCC9} type="text" id="sms_filter_klcode9" name="sms_filter_klcode9" value={CC9}/>
                            <label ref={ref => cc10 = ref} data-tip={toolTipCC10} htmlFor="sms_filter_klcode10"><Button onClick={()=>{CC10EqualsChangeListener()}} className={(cc10EqualsColor === "green") ? "green toggleButtonSmall" : "red toggleButtonSmall"} type="button">{cc10EqualsText}</Button> {props.t('customer')} code 10:</label>
                            <input className={(props.theme==='light') ? 'light_accent_darker' : 'dark_accent_darker'} onFocus={() => { ReactTooltip.show(cc10) }} onChange={changeCC10} type="text" id="sms_filter_klcode10" name="sms_filter_klcode10" value={CC10}/>
                            <label ref={ref => cc90 = ref} data-tip={toolTipCC90} htmlFor="sms_filter_klcode90"><Button onClick={()=>{CC90EqualsChangeListener()}} className={(cc90EqualsColor === "green") ? "green toggleButtonSmall" : "red toggleButtonSmall"} type="button">{cc90EqualsText}</Button> {props.t('sms_title')}:</label>
                            <input className={(props.theme==='light') ? 'light_accent_darker' : 'dark_accent_darker'} onFocus={() => { ReactTooltip.show(cc90) }} onChange={changeCC90} type="text" id="sms_filter_klcode90" name="sms_filter_klcode90" value={CC90}/>
                                                    
                        </div>}

                        <Button className="button_container__button button_100perc" onClick={()=>{getQuery()}} type="button">{props.t('generate')} SQL</Button>
                    </fieldset>}
                </div>}
                {(query !== "") && <CodeBlock 
                    text={format(query)}
                    language={'sql'}
                    theme={atomOneDark}
                    {...{ showLineNumbers: true, wrapLines: true, codeBlock: true }}
                    />}
            </form>
                    
            <ReactTooltip multiline={true} className="toolTipClass"/>            
        </div>
    );
}

export default BirthdayDetailScreen;