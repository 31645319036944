import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Button, IconButton, InputAdornment, OutlinedInput, Switch, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { HexColorPicker } from "react-colorful";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import EmailEditor from 'react-email-editor';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTiktok, faXTwitter, faWhatsapp, faInstagram, faFacebook, faYoutube, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faShoppingCart, faGlobe, faEnvelope, faLock} from '@fortawesome/free-solid-svg-icons';

// Libraries
import SftGlobalData from '../../library/SftGlobalData';
import Colors from '../../library/Colors';
import './eticket.css';
import './steps.scss';
import ProgVarTable from './ProgVarTable';
import FloatingSaveButton from '../../components/FloatingSaveButton';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import FloatingBackButton from '../../components/FloatingBackButton';


const disclaimerMaxLength = 2049;

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  

const EticketDetailScreen = (props) =>  {  
    const [loading, setLoading] = useState(true);
    const [value, setValue] = useState(0);
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);   
    const [storeId, setStoreId] = useState("00");
    const [posId, setPosId] = useState("0000"); 
    const [disableControls, setDisableControls] = useState(false);
    const params = useParams();

    // First step
    const [template, setTemplate] = useState('2');
    const [font, setFont] = useState('Montserrat');
    const [color, setColor] = useState(Colors.sft_blue);
    const [textColor, setTextColor] = useState('#FFFFFF');
    const [discountColor, setDiscountColor] = useState('#FF5454');
    const [discountTextColor, setDiscountTextColor] = useState('#FFFFFF');
    const [showCustomerCardDiscountText, setShowCustomerCardDiscountText] = useState(true);
    const [templates, setTemplates] = useState([]);

    // Second step
    const [header, setHeader] = useState("");
    const [footer, setFooter] = useState("[kasticket_hoofd1] \n[kasticket_hoofd2] \n[kasticket_hoofd3] \n[kasticket_hoofd4] \n[kasticket_hoofd5] \n[kasticket_hoofd6] \n[kasticket_hoofd7] \n");
    const [progVars, setProgVars] = useState({});
    const [selectedHeaderTag, setSelectedHeaderTag] = useState("");
    const [selectedFooterTag, setSelectedFooterTag] = useState("");
    const [selectedHeaderTagValue, setSelectedHeaderTagValue] = useState("");
    const [selectedFooterTagValue, setSelectedFooterTagValue] = useState("");
    const [headerReservation, setHeaderReservation] = useState("");
    const [footerReservation, setFooterReservation] = useState("[kasticket_hoofd1] \n[kasticket_hoofd2] \n[kasticket_hoofd3] \n[kasticket_hoofd4] \n[kasticket_hoofd5] \n[kasticket_hoofd6] \n[kasticket_hoofd7] \n");
    const [selectedHeaderTagReservation, setSelectedHeaderTagReservation] = useState("");
    const [selectedFooterTagReservation, setSelectedFooterTagReservation] = useState("");
    const [selectedHeaderTagValueReservation, setSelectedHeaderTagValueReservation] = useState("");
    const [selectedFooterTagValueReservation, setSelectedFooterTagValueReservation] = useState("");

    // Third step
    const [article, setArticle] = useState({});
    const [detailUseImages, setDetailUseImages] = useState(true);
    const [detailLine1, setDetailLine1] = useState("[CODE_2_DESCRIPTION] [SUPPLIER_NAME] ");
    const [detailLine2, setDetailLine2] = useState("[ARTICLE_DETAIL_1] / [ARTICLE_COLOR_SUPPLIER] [COLOR_DESCRIPTION] ");
    const [detailLine3, setDetailLine3] = useState("Maat: [ARTICLE_SIZE_X_DESCRIPTION] [ARTICLE_SIZE_Y_DESCRIPTION] ");

    // Fourth step
    const [focusTarget, setFocusTarget] = useState("");
    const [disclaimerAdvance, setDisclaimerAdvance] = useState("");
    const [disclaimerCommercialVoucher, setDisclaimerCommercialVoucher] = useState("");
    const [disclaimerGiftVoucher, setDisclaimerGiftVoucher] = useState("");
    const [disclaimerRetouch, setDisclaimerRetouch] = useState("");
    const [disclaimerVoucher, setDisclaimerVoucher] = useState("");
    const [disclaimerReservation, setDisclaimerReservation] = useState("");
    const [disclaimerReceipt, setDisclaimerReceipt] = useState("");
    const [disclaimerCustomerOrder, setDisclaimerCustomerOrder] = useState("");
    const [disclaimerExternalOrder, setDisclaimerExternalOrder] = useState("");
    const [disclaimerCustomerCardVoucher, setDisclaimerCustomerCardVoucher] = useState("");
    const [disclaimerOffer, setDisclaimerOffer] = useState("");

    // Fifth step
    const [logo, setLogo] = useState("");
    const [logoOverwrite, setLogoOverwrite] = useState(false);

    // Sixth step
    const [urlFacebook, setUrlFacebook] = useState("");
    const [urlInstagram, setUrlInstagram] = useState("");
    const [urlTiktok, setUrlTiktok] = useState("");
    const [urlYoutube, setUrlYoutube] = useState("");
    const [urlTwitter, setUrlTwitter] = useState("");
    const [urlWebshop, setUrlWebshop] = useState("");
    const [urlWebsite, setUrlWebsite] = useState("");
    const [urlWhatsapp, setUrlWhatsapp] = useState("");
    const [urlEmail, setUrlEmail] = useState("");
    const [urlLinkedin, setUrlLinkedin] = useState("");

    // Seventh step
    const [smtpOptions, setSmtpOptions] = useState([]);
    const [smtpPreset, setSmtpPreset] = useState("0");
    const [smtpPresetId, setSmtpPresetId] = useState("1");
    const [smtpName, setSmtpName] = useState("");
    const [smtpHost, setSmtpHost] = useState("");
    const [smtpUser, setSmtpUser] = useState("");
    const [smtpPass, setSmtpPass] = useState("");
    const [smtpPort, setSmtpPort] = useState("");
    const [smtpSSL, setSmtpSSL] = useState(false);
    const [mailFromName, setMailFromName] = useState("");
    const [mailFromEmail, setMailFromEmail] = useState("");
    const [mailSubject, setMailSubject] = useState("");
    const [mailErrorEmail, setMailErrorEmail] = useState("");
    const [mailBccEmail, setMailBccEmail] = useState("");
    const [mailBccReservation, setMailBccReservation] = useState("");
    const [mailBccReceipt, setMailBccReceipt] = useState("");
    const [mailBccCustOrder, setMailBccCustOrder] = useState("");
    const [mailBccOffer, setMailBccOffer] = useState("");
    const [validateSmtpLoader, setValidateSmtpLoader] = useState(false);

    // Layout
    const [design, setDesign] = useState("");
    const [html, setHtml] = useState("");
    const emailEditor = useRef(null);
    const [applyTemplate, setApplyTemplate] = useState('2');
    const [applyTemplateLoading, setApplyTemplateLoading] = useState(false);

    const [remark, setRemark] = useState("");

    let navigate = useNavigate();

    useEffect(() => {
        props.changeScreen('Eticket');
        fetchHeaders()
     },[props.api]);

    const fetchHeaders = () => {
        let url = SftGlobalData.baseURL_API + 'eticket&action=fetch';
        let postData = {
            sft_id: props.login.sft_status,
            sft_group: props.login.sft_group,
            login: props.login.login,
            key: props.api,
            id: params.listId
        }
        axios.post(url, postData, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('postData', postData);
                    console.log('response', response);
                }
                
                
                setStoreId(response.data.settings[params.elementId].store_id);
                setPosId(response.data.settings[params.elementId].pos_id);

                setTemplate(response.data.settings[params.elementId].layout.template_id);
                setFont(response.data.settings[params.elementId].layout.font);
                setShowCustomerCardDiscountText(response.data.settings[params.elementId].layout?.show_customer_card_discount_text ?? true);
                setColor(response.data.settings[params.elementId].layout.accent_color);
                setTextColor(response.data.settings[params.elementId].layout.accent_text_color);
                setDiscountColor(response.data.settings[params.elementId].layout.discount_color);
                setDiscountTextColor(response.data.settings[params.elementId].layout.discount_text_color);
                try{setDesign(response.data.settings[params.elementId].layout.design);
                    try{
                        if(response.data.settings[params.elementId].layout.design){
                            emailEditor.current.loadDesign(response.data.settings[params.elementId].layout.design);
                        }
                    }catch(e){
                }}catch(e){}
                setHtml(response.data.settings[params.elementId].layout.html);

                setHeader(response.data.settings[params.elementId].program_vars.eticket_header.replaceAll("<br/>","\n"));
                setFooter(response.data.settings[params.elementId].program_vars.eticket_footer.replaceAll("<br/>","\n"));
                setHeaderReservation(response.data.settings[params.elementId].program_vars.eticket_header_reservation.replaceAll("<br/>","\n"));
                setFooterReservation(response.data.settings[params.elementId].program_vars.eticket_footer_reservation.replaceAll("<br/>","\n"));
                
                setDetailUseImages(response.data.settings[params.elementId].program_vars.eticket_use_images === "T");
                setDetailLine1(response.data.settings[params.elementId].program_vars.eticket_detail1);
                setDetailLine2(response.data.settings[params.elementId].program_vars.eticket_detail2);
                setDetailLine3(response.data.settings[params.elementId].program_vars.eticket_detail3);

                setDisclaimerAdvance(response.data.settings[params.elementId].program_vars.eticket_disclaimer_advance.replaceAll("<br/>","\n"));
                setDisclaimerCommercialVoucher(response.data.settings[params.elementId].program_vars.eticket_disclaimer_comm_voucher.replaceAll("<br/>","\n"));
                setDisclaimerGiftVoucher(response.data.settings[params.elementId].program_vars.eticket_disclaimer_gift_voucher.replaceAll("<br/>","\n"));
                setDisclaimerRetouch(response.data.settings[params.elementId].program_vars.eticket_disclaimer_retouch.replaceAll("<br/>","\n"));
                setDisclaimerVoucher(response.data.settings[params.elementId].program_vars.eticket_disclaimer_voucher.replaceAll("<br/>","\n"));
                setDisclaimerReservation(response.data.settings[params.elementId].program_vars.eticket_disclaimer_reservation.replaceAll("<br/>","\n"));
                setDisclaimerReceipt(response.data.settings[params.elementId].program_vars.eticket_disclaimer_receipt.replaceAll("<br/>","\n"));
                setDisclaimerCustomerOrder(response.data.settings[params.elementId].program_vars.eticket_disclaimer_cust_order.replaceAll("<br/>","\n"));
                setDisclaimerExternalOrder(response.data.settings[params.elementId].program_vars.eticket_disclaimer_external_order?.replaceAll("<br/>","\n"));
                setDisclaimerCustomerCardVoucher(response.data.settings[params.elementId].program_vars.eticket_disclaimer_cc_voucher.replaceAll("<br/>","\n"));
                setDisclaimerOffer(response.data.settings[params.elementId].program_vars.eticket_disclaimer_offer.replaceAll("<br/>","\n"));
             
                setLogo("data:image/jpeg;base64,"+response.data.settings[params.elementId].program_vars.ticket_logo);

                setUrlFacebook(response.data.settings[params.elementId].links.facebook);
                setUrlInstagram(response.data.settings[params.elementId].links.instagram);
                setUrlTiktok(response.data.settings[params.elementId].links.tiktok);
                setUrlYoutube(response.data.settings[params.elementId].links.youtube);
                setUrlTwitter(response.data.settings[params.elementId].links.twitter);
                setUrlWebshop(response.data.settings[params.elementId].links.webshop);
                setUrlWebsite(response.data.settings[params.elementId].links.website);
                setUrlWhatsapp(response.data.settings[params.elementId].links.whatsapp);
                setUrlEmail(response.data.settings[params.elementId].links.email);
                setUrlLinkedin(response.data.settings[params.elementId].links.linkedIn);

                
                setSmtpPresetId(response.data.settings[params.elementId].smtp.preset);
                
                setSmtpName(response.data.settings[params.elementId].smtp.name);
                setSmtpHost(response.data.settings[params.elementId].smtp.host);
                setSmtpUser(response.data.settings[params.elementId].smtp.user);
                setSmtpPass(response.data.settings[params.elementId].smtp.pass);
                setSmtpPort(response.data.settings[params.elementId].smtp?.port);
                setSmtpSSL(response.data.settings[params.elementId].smtp.SSL);
                setMailFromName(response.data.settings[params.elementId].mail_settings.sender_name);
                setMailFromEmail(response.data.settings[params.elementId].mail_settings.sender_mail);
                setMailSubject(response.data.settings[params.elementId].mail_settings.subject);
                setMailErrorEmail(response.data.settings[params.elementId].mail_settings.error_mail);
                setMailBccEmail(response.data.settings[params.elementId].mail_settings.bcc_mail);
                setMailBccReservation(response.data.settings[params.elementId].mail_settings.bcc_mail_reservation);
                setMailBccReceipt(response.data.settings[params.elementId].mail_settings.bcc_mail_receipt);
                setMailBccCustOrder(response.data.settings[params.elementId].mail_settings.bcc_mail_cust_order);
                setMailBccOffer(response.data.settings[params.elementId].mail_settings.bcc_mail_offer);

                setName(response.data.settings[params.elementId].name);
                if(typeof response.data.settings[params.elementId]?.remark !== "undefined")
                    setRemark(response.data.settings[params.elementId]?.remark);

                fetchRequiredData(response.data.settings[params.elementId].store_id, response.data.settings[params.elementId].pos_id, response.data.settings[params.elementId].smtp.preset);

            });
    }

    const fetchRequiredData = (store_id, pos_id, smtppresetid) => {
        let url = SftGlobalData.baseURL_API + 'eticket&action=loadVars';
        let postData = {
            sft_id: props.login.sft_status,
            sft_group: props.login.sft_group,
            login: props.login.login,
            key: props.api,
            store_id: store_id,
            pos_id: pos_id
        }
        axios.post(url, postData, {
        headers : {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
        }})
        .then(response => {
            if(SftGlobalData.debug){
                console.log('url', url);
                console.log('response', response);
            }
            setProgVars(response.data);
            let url2 = SftGlobalData.baseURL_API + 'templates&action=fetchHeaders';
            let postData2 = {
                sft_id: props.login.sft_status,
                sft_group: props.login.sft_group,
                login: props.login.login,
                key: props.api,
                type: "ETICKET",
                active: 1,
                store_id: store_id,
                pos_id: pos_id
            }
            axios.post(url2, postData2, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
            .then(resp => {
                if(SftGlobalData.debug){
                    console.log('url', url2);
                    console.log('response', resp);
                }
                setTemplates(resp.data);
                let url3 = SftGlobalData.baseURL_API+'client&action=fetchSchedulerSMTP';
                let postData3 = {key: props.api}
                axios.post(url3, postData3, {
                    headers : {
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                    }})
                    .then(respo => {
                        if(SftGlobalData.debug){
                            console.log('url', url3);
                            console.log('postData', postData3);
                            console.log('response', respo);
                        }
                        setSmtpOptions(respo.data.servers);
                        if(typeof respo.data?.servers?.length !== "undefined"){
                            for(let i = 0; i < respo.data.servers.length; i++){
                                if(respo.data.servers[i].smtpid === smtppresetid){
                                    setSmtpPreset(i+"");
                                }
                            }
                        }
                        let url4 = SftGlobalData.baseURL_API+'eticket&action=loadArticle';
                        let postData4 = {
                            sft_id: props.login.sft_status,
                            sft_group: props.login.sft_group,
                            login: props.login.login,
                            key: props.api,
                            store_id: store_id,
                            pos_id: pos_id
                        }
                        axios.post(url4, postData4, {
                            headers : {
                                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                            }})
                            .then(respon => {
                                if(SftGlobalData.debug){
                                    console.log('url', url4);
                                    console.log('postData', postData4);
                                    console.log('response', respon);
                                }
                                setArticle(respon.data);
                                setLoading(false);
                            });
                        //fetchMySftLogo();
                    });
            });
        });
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        exportHtml(false);
    };

    const exportHtml = (doSave) => {
        let design_temp  = "";
        let html_temp    = "";
        try{
            emailEditor.current.editor.exportHtml((data) => {
                const { design, html } = data;
                setHtml(html);
                setDesign(design);
                design_temp = design;
                html_temp = html;
                if(doSave)
                    save(design_temp, html_temp);
            });
        }catch(e){
            if(doSave){
                if(template === "0")
                    save(design, html)
                else 
                    save("", "")
            }
                
        }
    }
    
    const save = (des, htm) => {
        let url = SftGlobalData.baseURL_API + 'eticket&action=wizard';
        let postData = {
            sft_group: props.login.sft_group,
            sft_status: props.login.sft_status,
            sft_user_id: props.login.sft_user_id,
            login: props.login.login,
            key: props.api,
            store_id: storeId,
            pos_id: posId,

            id: params.listId,
            element: params.elementId,

            settings: [{
                store_id: storeId,
                pos_id: posId,
                smtp: {
                        preset: smtpPresetId,
                        name: smtpName,
                        host: smtpHost,
                        user: smtpUser,
                        pass: smtpPass,
                        port: smtpPort,
                        SSL: smtpSSL
                    },
                mail_settings: {
                        sender_mail: mailFromEmail,
                        sender_name: mailFromName,
                        subject: mailSubject,
                        error_mail: mailErrorEmail,
                        bcc_mail: mailBccEmail,
                        bcc_mail_receipt: mailBccReceipt,
                        bcc_mail_reservation: mailBccReservation,
                        bcc_mail_cust_order: mailBccCustOrder,
                        bcc_mail_offer: mailBccOffer
                    },
                layout: {
                        template_id: template,
                        design: des,
                        html: htm,
                        accent_color: color,
                        accent_text_color: textColor,
                        discount_color: discountColor,
                        discount_text_color: discountTextColor,
                        font: font,
                        show_customer_card_discount_text: showCustomerCardDiscountText
                    },
                query_settings: {
                        custom_joins: "",
                        custom_select: ""
                    },
                links: {
                        website: urlWebsite,
                        webshop: urlWebshop,
                        facebook: urlFacebook,
                        instagram: urlInstagram,
                        tiktok: urlTiktok,
                        twitter: urlTwitter,
                        linkedIn: urlLinkedin,
                        youtube: urlYoutube, 
                        email: urlEmail,
                        whatsapp: urlWhatsapp,
                        other: ""
                    }
            }],
            eticket_header: header.replaceAll("\n", "<br/>"),
            eticket_footer: footer.replaceAll("\n", "<br/>"),
            eticket_header_reservation: headerReservation.replaceAll("\n", "<br/>"),
            eticket_footer_reservation: footerReservation.replaceAll("\n", "<br/>"),
            eticket_detail1: detailLine1, 
            eticket_detail2: detailLine2, 
            eticket_detail3: detailLine3, 
            eticket_use_images: detailUseImages, 
            eticket_disclaimer_advance: disclaimerAdvance.replaceAll("\n", "<br/>"),
            eticket_disclaimer_retouch: disclaimerRetouch.replaceAll("\n", "<br/>"),
            eticket_disclaimer_voucher: disclaimerVoucher.replaceAll("\n", "<br/>"),
            eticket_disclaimer_gift_voucher: disclaimerGiftVoucher.replaceAll("\n", "<br/>"),
            eticket_disclaimer_comm_voucher: disclaimerCommercialVoucher.replaceAll("\n", "<br/>"),
            eticket_disclaimer_cc_voucher: disclaimerCustomerCardVoucher.replaceAll("\n", "<br/>"),
            eticket_disclaimer_receipt: disclaimerReceipt.replaceAll("\n", "<br/>"),
            eticket_disclaimer_reservation: disclaimerReservation.replaceAll("\n", "<br/>"),
            eticket_disclaimer_cust_order: disclaimerCustomerOrder.replaceAll("\n", "<br/>"),
            eticket_disclaimer_external_order: disclaimerExternalOrder?.replaceAll("\n", "<br/>"),
            eticket_disclaimer_offer: disclaimerOffer.replaceAll("\n", "<br/>"),
            ticket_logo: logo ,
        }
        axios.post(url, postData, {
        headers : {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
        }})
        .then(response => {
            if(SftGlobalData.debug){
                console.log('url', url);
                console.log('postData', postData);
                console.log('response', response);
            }
            setDisableControls(false);
            props.notify(props.t('savedChanges'), 7500, "top-right", props.theme, 1); 
            // TODO: Error handling
        });
    
    }

    const checkPw = (pw) => {
        if(pw === SftGlobalData.superUltraSecretLockPassword){
            console.log("SUPER SECRET PASSWORD UNLOCK");
            setTemplate("0");
            try{
                if(design){
                    emailEditor.current.loadDesign(design);
                }
            }catch(e){}
        }
    }

    const onLoad = () => {
        // editor instance is created
        // you can load your template here;
        // const templateJson = {};
        // emailEditorRef.current.editor.loadDesign(templateJson);
    }

    const onReady = () => {
        // editor is ready
        //console.log('design', designNL);
        try{
            if(design){
                emailEditor.current.loadDesign(design);
            }
        }catch(e){
            console.error(e);
        }
    }
    
/*************************************************************************************
*
*                             TEMPLATE / PERSONALISATION
*
*************************************************************************************/

const setDefaultsNL = () =>{
    setHeader("Beste [CUST_FIRST_NAME], \n\nBedankt voor uw aankoop bij [kasticket_hoofd1]! We hopen dat u tevreden bent met uw gekozen artikelen en dat ze aan uw verwachtingen voldoen. \n\nNogmaals dank voor uw vertrouwen in [kasticket_hoofd1]. We kijken ernaar uit u binnenkort weer in onze winkel te mogen verwelkomen.");
    setHeaderReservation("Beste  [CUST_FIRST_NAME],\n\nWe zijn blij te kunnen bevestigen dat de volgende artikelen bij [kasticket_hoofd1] voor u zijn gereserveerd.\n\nHoud er rekening mee dat deze reservatie voor [aantal] dagen zal gelden. Als de artikelen voor deze datum niet zijn opgehaald, worden ze weer beschikbaar gesteld voor verkoop aan andere klanten.");
    setFooter("[kasticket_hoofd1] \n[kasticket_hoofd2] \n[kasticket_hoofd3] \n[kasticket_hoofd4] \n[kasticket_hoofd5] \n[kasticket_hoofd6] \n[kasticket_hoofd7] \n");
    setFooterReservation("[kasticket_hoofd1] \n[kasticket_hoofd2] \n[kasticket_hoofd3] \n[kasticket_hoofd4] \n[kasticket_hoofd5] \n[kasticket_hoofd6] \n[kasticket_hoofd7] \n");
    setDisclaimerAdvance("Door een voorschot te betalen, bevestigt de klant de intentie om een nog niet beschikbaar artikel bij [kasticket_hoofd1] aan te schaffen. Dit voorschot garandeert de reservering van het betreffende artikel zodra het beschikbaar wordt. [kasticket_hoofd1] verbindt zich ertoe om het artikel te leveren zodra het beschikbaar is, met dien verstande dat leveringsdata onderhevig kunnen zijn aan verandering door factoren buiten onze controle./nIn geval van annulering van de bestelling door de klant vóór de levering van het artikel, zal het voorschot worden omgezet in een tegoed bij [kasticket_hoofd1], dat gebruikt kan worden voor toekomstige aankopen. Dit tegoed is niet inwisselbaar voor contant geld.");
    setDisclaimerCommercialVoucher("De commerciële waardebon geeft de houder recht op een korting van bepaalde % of een vast bedrag van korting op geselecteerde artikelen bij [kasticket_hoofd1]. De bon is beperkt geldig en moet binnen deze periode worden gebruikt.\nDe waardebon is enkel geldig voor aankopen bij [kasticket_hoofd1] en kan niet worden ingewisseld voor contant geld. Deze waardebon kan niet worden gecombineerd met andere acties, aanbiedingen of kortingen. De waardebon moet in één keer worden besteed; er wordt geen restwaarde in contant geld of als nieuwe bon uitgegeven.");
    setDisclaimerGiftVoucher("Een cadeaubon is geldig tot [CadeauChequeAantalDagen] dagen na de datum van uitgifte. Na deze periode vervalt de geldigheid van de bon en kan deze niet meer worden ingewisseld. Deze bon kan niet worden ingewisseld voor contant geld en kan alleen worden gebruikt voor aankopen bij [kasticket_hoofd1]. Het is niet toegestaan om deze bon te kopiëren, te wijzigen of te reproduceren op welke manier dan ook. [kasticket_hoofd1] is niet verantwoordelijk voor verloren, gestolen of beschadigde bonnen. Het gebruik van deze bon impliceert acceptatie van de bovengenoemde voorwaarden.");
    setDisclaimerRetouch("Bij het aannemen van kledingartikelen voor retouche, streeft [kasticket_hoofd1] ernaar om de gewenste aanpassingen met de hoogst mogelijke zorg en vakmanschap uit te voeren. Klanten dienen bij het inleveren van de kleding duidelijk aan te geven welke retouches gewenst zijn.\nRetouches kunnen de oorspronkelijke staat van een kledingstuk veranderen. Klanten dienen zich bewust te zijn van het risico op mogelijke veranderingen in pasvorm, stijl, en comfort van het kledingstuk.");
    setDisclaimerVoucher("Een waardebon is geldig tot [WaardeBonAantalDagen] dagen na de datum van uitgifte. Na deze periode vervalt de geldigheid van de bon en kan deze niet meer worden ingewisseld. Deze bon kan niet worden ingewisseld voor contant geld en kan alleen worden gebruikt voor aankopen bij [kasticket_hoofd1]. Het is niet toegestaan om deze bon te kopiëren, te wijzigen of te reproduceren op welke manier dan ook. [kasticket_hoofd1] is niet verantwoordelijk voor verloren, gestolen of beschadigde bonnen. Het gebruik van deze bon impliceert acceptatie van de bovengenoemde voorwaarden.");
    setDisclaimerReservation("Door het plaatsen van een reservatie bij [kasticket_hoofd1] en het betalen van een voorschot, gaat de klant akkoord met het volgende beleid:\n1.	De gereserveerde artikelen worden voor de klant opzij gehouden voor een periode van [aantal] dagen vanaf de datum van de reservatie.\n2.	Indien de klant de gereserveerde artikelen niet binnen deze periode ophaalt, behoudt [kasticket_hoofd1] zich het recht voor om de artikelen weer beschikbaar te stellen voor verkoop aan andere klanten.\n3.	In het geval dat de klant de gereserveerde artikelen niet binnen de vastgestelde periode ophaalt, kan het betaalde voorschot bedrag verbeurd worden verklaard ter compensatie van de gereserveerde opslag en potentiële gemiste verkoopkansen.\n4.	Voorschotten op reservaties zijn niet terugbetaalbaar in het geval van niet tijdig ophalen van de gereserveerde artikelen\nDeze voorwaarden zijn bedoeld om een eerlijke en efficiënte service te garanderen aan al onze klanten. ");
    setDisclaimerReceipt("Artikelen gekocht bij [kasticket_hoofd1] kunnen binnen [x aantal] dagen na aankoop worden omgeruild, op voorwaarde dat ze worden teruggebracht met de originele labels en zonder enige beschadiging. Om een omruiling te kunnen verwerken, dient het originele kassaticket overlegd te worden.\nLet op: Solden of afgeprijsde artikelen zijn uitgesloten van omruiling.");
    setDisclaimerCustomerOrder("Wanneer een klant bij [kasticket_hoofd1] een bestelling plaatst voor een artikel dat momenteel niet op voorraad is, gelden de volgende voorwaarden:\n1.	[kasticket_hoofd1] zal alle redelijke inspanningen leveren om het bestelde artikel zo spoedig mogelijk beschikbaar te stellen. De verwachte levertijd wordt aan de klant meegedeeld, maar dient slechts als schatting. Deze kan variëren door factoren buiten onze controle.\n2.	In het geval dat de levering van het bestelde artikel aanzienlijk wordt vertraagd of onmogelijk blijkt, zal [kasticket_hoofd1] de klant hierover informeren en de optie bieden om de bestelling te annuleren met een volledige terugbetaling van eventuele vooraf betaalde bedragen.\n3.	Annuleringen van de bestelling door de klant na [aantal dagen] dagen vanaf de besteldatum kunnen onderhevig zijn aan administratieve kosten of een gedeeltelijke terugbetaling, afhankelijk van de voortgang van de bestelling.\n4.	[kasticket_hoofd1] is niet aansprakelijk voor enig ongemak of kosten die voortvloeien uit vertragingen in de levering of de onbeschikbaarheid van het product.\nDoor een bestelling te plaatsen voor een niet-beschikbaar artikel, gaat de klant akkoord met deze voorwaarden.");
    setDisclaimerExternalOrder("Let op. Dit kasticket bevat artikelen die moeten worden besteld bij onze leverancier(s). Hierdoor is het mogelijk dat niet alle artikelen direct in uw bezit zijn. Indien alle artikelen geleverd moeten worden, kan het voorkomen dat sommige artikelen apart of later verzonden worden. Wij danken u voor uw begrip.");
    setDisclaimerCustomerCardVoucher("De kortingsbon is geldig voor een periode van [aantal] dagen vanaf de datum van uitgifte. Na het verstrijken van deze periode verliest de bon zijn geldigheid en kan niet meer worden gebruikt.\nDeze kortingsbon kan enkel worden gebruikt voor aankopen op artikelen zonder reeds bestaande korting bij [kasticket_hoofd1]. De bon is niet inwisselbaar voor contant geld en kan niet worden gecombineerd met andere lopende aanbiedingen of promoties. De kortingsbon is persoonlijk en niet overdraagbaar.");
    setDisclaimerOffer("Deze offerte is een vrijblijvend voorstel van [kasticket_hoofd1] en is geldig voor een periode van [aantal] dagen vanaf de datum van uitgifte. Na het verstrijken van deze periode behoudt [kasticket_hoofd1] zich het recht voor om de offerte te herzien of in te trekken.\nHet presenteren van deze offerte impliceert geen verplichting voor [kasticket_hoofd1] om een overeenkomst aan te gaan. Eventuele aanpassingen of aanvullingen op deze offerte dienen schriftelijk te worden overeengekomen door beide partijen. [kasticket_hoofd1] is niet verantwoordelijk voor enige misverstanden of verwachtingen gebaseerd op de inhoud van de offerte, tenzij deze schriftelijk zijn bevestigd.");
    setMailSubject("Bedankt voor uw [TRANSACTION_TYPE]!");
}

const setDefaultsFR = () =>{
    setHeader("Cher [CUST_FIRST_NAME], \n\nMerci pour votre achat chez [kasticket_hoofd1] ! Nous espérons que vous êtes satisfait des articles que vous avez choisis et qu'ils répondent à vos attentes. \n\nMerci encore pour la confiance que vous accordez à [kasticket_hoofd1]. Nous espérons avoir le plaisir de vous accueillir à nouveau dans notre boutique prochainement.");
    setHeaderReservation("Cher [CUST_FIRST_NAME],\n\nNous avons le plaisir de vous confirmer que les articles suivants ont été réservés pour vous chez [kasticket_hoofd1].\n\nVeuillez noter que cette réservation sera valable pendant [aantal] jours. Si les articles n'ont pas été retirés à cette date, ils seront remis en vente pour d'autres clients.");
    setFooter("[kasticket_hoofd1] \n[kasticket_hoofd2] \n[kasticket_hoofd3] \n[kasticket_hoofd4] \n[kasticket_hoofd5] \n[kasticket_hoofd6] \n[kasticket_hoofd7] \n");
    setFooterReservation("[kasticket_hoofd1] \n[kasticket_hoofd2] \n[kasticket_hoofd3] \n[kasticket_hoofd4] \n[kasticket_hoofd5] \n[kasticket_hoofd6] \n[kasticket_hoofd7] \n");
    setDisclaimerAdvance("En payant un acompte, le client confirme l'intention d'acquérir un article encore indisponible auprès de [kasticket_hoofd1]. Cet acompte garantit la réservation de l'article en question dès qu'il sera disponible. [kasticket_hoofd1] s'engage à livrer l'article dès sa disponibilité, étant entendu que les dates de livraison peuvent être modifiées en raison de facteurs indépendants de notre volonté.  \nEn cas d'annulation de la commande par le client avant la livraison de l'article, l'acompte sera converti en un crédit auprès de [kasticket_hoofd1], qui pourra être utilisé pour de futurs achats. Ce crédit n'est pas échangeable contre des espèces.");
    setDisclaimerCommercialVoucher("Le bon commercial donne droit à une réduction d'un certain % ou d'un montant fixe sur une sélection d'articles chez [kasticket_hoofd1]. Le bon a une durée de validité limitée et doit être utilisé pendant cette période. \n\nLe bon n'est valable que pour les achats effectués chez [kasticket_hoofd1] et ne peut être échangé contre de l'argent. Ce bon n'est pas cumulable avec d'autres promotions, offres ou réductions. Le bon doit être dépensé en une seule fois ; aucune valeur résiduelle ne sera remise en espèces ou sous la forme d'un nouveau bon.");
    setDisclaimerGiftVoucher("Un chèque-cadeau est valable jusqu'à [CadeauChequeAantalDagen] jours après la date d'émission. Passé ce délai, la validité du chèque-cadeau expire et il ne peut plus être utilisé. Ce bon ne peut être échangé contre de l'argent et ne peut être utilisé que pour des achats chez [kasticket_hoofd1]. Il est interdit de copier, modifier ou reproduire ce bon de quelque manière que ce soit. [kasticket_hoofd1] n'est pas responsable des bons perdus, volés ou endommagés. L'utilisation de ce bon implique l'acceptation des conditions susmentionnées.");
    setDisclaimerVoucher("Lorsque nous acceptons des vêtements à retoucher, [kasticket_hoofd1] s'efforce d'effectuer les ajustements souhaités avec le plus grand soin et le plus grand savoir-faire possible. Les clients doivent indiquer clairement les retouches souhaitées lorsqu'ils déposent les vêtements. \n\nLes retouches peuvent altérer l'état d'origine d'un vêtement. Les retouches peuvent altérer l'état d'origine d'un vêtement. Les clients doivent être conscients du risque de modification de la coupe, du style et du confort du vêtement.");
    setDisclaimerRetouch("Un bon est valable jusqu'à [WaardeBonAantalDagen] jours après la date d'émission. Passé ce délai, le bon expire et ne peut plus être utilisé. Ce bon ne peut être échangé contre de l'argent et ne peut être utilisé que pour des achats chez [kasticket_hoofd1]. Il est interdit de copier, modifier ou reproduire ce bon de quelque manière que ce soit. [kasticket_hoofd1] n'est pas responsable des bons perdus, volés ou endommagés. L'utilisation de ce bon implique l'acceptation des conditions susmentionnées.");
    setDisclaimerReservation("En effectuant une réservation auprès de [kasticket_hoofd1] et en versant un acompte, le client accepte les conditions suivantes:\n1.    Les articles réservés seront mis de côté pour le client pendant une période de [aantal] jours à compter à partir de la date de la réservation.\n2.    Si le client ne récupère pas les articles réservés dans ce délai, [kasticket_hoofd1] se réserve le droit de remettre les articles en vente pour d'autres clients.\n3.    Si le client ne récupère pas les articles réservés dans le délai imparti, le montant de l'avance versée peut être perdu pour compenser le stockage réservé et les éventuelles opportunités de vente manquées.\n4.    Les avances sur les réservations ne sont pas remboursables si les articles réservés ne sont pas enlevés à temps.\n Ces conditions générales sont conçues pour garantir un service équitable et efficace à tous nos clients.");
    setDisclaimerReceipt("Les articles achetés chez [kasticket_hoofd1] peuvent être échangés dans les [x aantal] jours suivant l'achat, à condition qu'ils soient retournés avec les étiquettes d'origine et sans aucun dommage. Pour procéder à un échange, le ticket de caisse original doit être présenté. \nAttention : Les articles soldés ou en promotion sont exclus de l'échange.");
    setDisclaimerCustomerOrder("Lorsqu'un client passe une commande auprès de [kasticket_hoofd1] pour un article qui est actuellement en rupture de stock, les conditions suivantes s'appliquent:\n1.    [kasticket_hoofd1] fera tous les efforts raisonnables pour rendre l'article commandé disponible dès que possible. Le délai de livraison prévu sera communiqué au client, mais ne servira que d'estimation. Il peut varier en raison de facteurs indépendants de notre volonté.\n2.    Dans le cas où la livraison de l'article commandé est significativement retardée ou s'avère impossible, [kasticket_hoofd1] en informera le client et lui offrira la possibilité d'annuler la commande avec un remboursement intégral des sommes prépayées.\n3.    L'annulation de la commande par le client après [aantal] jours à compter de la date de la commande peut être soumise à des frais administratifs ou à un remboursement partiel, en fonction de l'avancement de la commande.\n4.    [kasticket_hoofd1] n'est pas responsable des inconvéniences ou des coûts résultant de retards de livraison ou de l'indisponibilité du produit.\nEn passant une commande pour un article indisponible, le client accepte les présentes conditions générales.");
    setDisclaimerExternalOrder("Attention. Ce billet contient des articles qui doivent être commandés auprès de notre (nos) fournisseur(s). Par conséquent, il se peut que vous ne soyez pas en possession de tous les articles immédiatement. Si tous les articles doivent être livrés, certains articles peuvent être expédiés séparément ou plus tard. Nous vous remercions de votre compréhension.");
    setDisclaimerCustomerCardVoucher("Cette offre est une proposition non contraignante de [kasticket_hoofd1] et est valable pour une période de [aantal] jours à compter de la date d'émission. À l'expiration de ce délai, [kasticket_hoofd1] se réserve le droit de réviser ou de retirer son offre. \nLa présentation de cette offre n'implique aucune obligation pour [kasticket_head1] de conclure un contrat. Toute modification ou tout ajout à cette offre doit faire l'objet d'un accord écrit entre les deux parties. [kasticket_hoofd1] n'est pas responsable des éventuels malentendus ou attentes fondés sur le contenu de l'offre, à moins qu'ils ne soient confirmés par écrit.");
    setDisclaimerOffer("Le bon de réduction est valable pendant une période de [aantal] jours à compter de la date d'émission. À l'expiration de cette période, le bon perd sa validité et ne peut plus être utilisé. \nCe bon ne peut être utilisé que pour des achats sur des articles sans remise préexistante chez [kasticket_hoofd1]. Le bon de réduction n'est pas monnayable et n'est pas cumulable avec d'autres offres ou promotions en cours. Le bon de réduction est personnel et non transférable.");
    setMailSubject("Merci pour votre [TRANSACTION_TYPE]!");
}

const onSelectSmtpOption = (option) => {
    if(option === "-1"){
        setSmtpName("");
        setSmtpHost("");
        setSmtpUser("");
        setSmtpPass("");
        setSmtpPort("");
        setSmtpSSL(false);
        setSmtpPresetId("0");
    } else {
        setSmtpName(smtpOptions[option].smtpname);
        setSmtpHost(smtpOptions[option].smtphost);
        setSmtpUser("**********");
        setSmtpPass("**********");
        setSmtpPort(smtpOptions[option].smtpport);
        setSmtpSSL(smtpOptions[option].sslStartMode);
        setSmtpPresetId(smtpOptions[option].smtpid);
        //setMailFrom(smtpOptions[option].frommail);
    }
}

const fetchMySftLogo = () => {
    let urlLogo = SftGlobalData.baseURL_API+'client&action=fetchLogo';
    let postDataLogo = {key: props.api}
    axios.post(urlLogo, postDataLogo, {
        headers : {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
        }})
        .then(responseLogo => {
            if(SftGlobalData.debug){
                console.log('url', urlLogo);
                console.log('postData', urlLogo);
                console.log('response', responseLogo);
            }
            setLogo(responseLogo.data);
            setLogoOverwrite(false);
        });
}

const onLogoChange = (e) => {
    let files = e.target.files;
    let fileReader = new FileReader();
    if(files[0].size > 1048576){
        props.notify(props.t('fileTooLarge'), 7500, "top-right", props.theme, 1); 
        e.target.value = '';
        return;
    }
    fileReader.readAsDataURL(files[0]);

    fileReader.onload = (event) => {
        if(SftGlobalData.debug){
            console.log("logo", event.target.result);
        }
        setLogo(event.target.result);
        setLogoOverwrite(true);
    }
}

const onSaveLogo = async () => {
    try{
        let url = SftGlobalData.baseURL_API+'client&action=updateLogo';
        let postData = {
            key: props.api,
            logo: logo, user: props.username, sftUserId: props.userId
        }
        axios.post(url, postData, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('postData', postData);
                    console.log('resp', response);
                }
                props.notify(props.t('savedChanges'), 7500, "top-right", props.theme, 1); 
                setLogoOverwrite(false);
            });
    } catch(e){
        console.error(e);
        props.notify(props.t('errorOccured'), 7500, "top-right", props.theme, 1); 
    }
}

const fetchArticleTags = () => {
    let arr = [];
    for(let x in article){
        if(x !== "ARTICLE_PHOTO")
            arr.push(x);
    }
    return arr.map((a) => (
        <tr key={a}  onClick={()=>{navigator.clipboard.writeText("["+a+"] ");}}>
            <td onClick={()=>{navigator.clipboard.writeText("["+a+"] ");}}>{a}</td>
            <td onClick={()=>{navigator.clipboard.writeText("["+a+"] ");}}>{article[a]}</td>
        </tr>
    ));

}

const fetchProgVarOptions = () => {
    let arr = [];
    for(let x in progVars){
        arr.push(x);
    }
    arr.push("CUST_FIRST_NAME");
    arr.push("CUST_LAST_NAME");
    arr.push("CUST_TITLE");
    arr.push("CUST_CC_LINES");
    arr.push("CUST_CC_VALUE");
    arr.push("CUST_FREQUENCY");
    arr.push("CUST_CC_TOTAL");
    arr.push("CUST_CC_POINTS");
    arr.push("STORE_DESCRIPTION");
    arr.push("STORE_SHIPPING_NAME");
    arr.push("STORE_SHIPPING_COMPANY");
    arr.push("STORE_SHIPPING_STREET");
    arr.push("STORE_SHIPPING_STREET_NUMBER");
    arr.push("STORE_SHIPPING_ZIP");
    arr.push("STORE_SHIPPING_CITY");
    arr.push("STORE_SHIPPING_COUNTRY");
    arr.push("STORE_SHIPPING_EMAIL");
    arr.push("STORE_SHIPPING_PHONE");
    arr.push("TRANSACTION_ID");
    arr.push("TRANSACTION_TYPE");
    arr.push("TRANSACTION_POINTS");
    arr.push("CUST_ID");
    arr.push("DATE");
    arr.push("HOUR");
    arr.push("SEAL");
    arr.push("USER_NAME");
    arr.push("POS_DESCRIPTION");
    arr.push("DISCLAIMER_EXTERNAL_ORDER");
    //console.log("progVars", progVars);
    //console.log("arr", arr);
    return arr.map((a) => (
        <MenuItem
        value={a}
        >
            {a}
        </MenuItem>
    ));

}

const getTagsPreview = (text) => {
    let resp = text;

    try{resp = resp.replaceAll('[Kasticket_hoofd]', progVars['Kasticket_hoofd']);}catch(exception){console.error(exception);}
    try{resp = resp.replaceAll('[Kasticket_voet]', progVars['Kasticket_voet']);}catch(exception){console.error(exception);}
    resp = resp.replaceAll('[B]', '<b>');
    resp = resp.replaceAll('[/B]', '</b>');
    resp = resp.replaceAll('[AlignCenter]', '<center>');
    resp = resp.replaceAll('[/AlignCenter]', '</center>');
    resp = resp.replaceAll('[TR]', '');
    resp = resp.replaceAll('[/TR]', '');
    resp = resp.replaceAll('[ReverseColor]', '');
    resp = resp.replaceAll('[/ReverseColor]', '');
    resp = resp.replaceAll('[CharacterWidth2]', '');
    resp = resp.replaceAll('[/CharacterWidth2]', '');
    resp = resp.replaceAll('[CharacterHeight2]', '');
    resp = resp.replaceAll('[/CharacterHeight2]', '');
    resp = resp.replaceAll('[CharacterWidthHeight2]', '');
    resp = resp.replaceAll('[/CharacterWidthHeight2]', '');
    resp = resp.replaceAll('[CharacterNormal]', '');
    resp = resp.replaceAll('[/CharacterNormal]', '');
    resp = resp.replaceAll('[CharacterBig]', '');
    resp = resp.replaceAll('[/CharacterBig]', '');
    resp = resp.replaceAll('[CharacterLarge]', '');
    resp = resp.replaceAll('[/CharacterLarge]', '');
    resp = resp.replaceAll('[AlignLeft]', '');
    resp = resp.replaceAll('[/AlignLeft]', '');
    resp = resp.replaceAll('[AlignRight]', '');
    resp = resp.replaceAll('[/AlignRight]', '');
    resp = resp.replaceAll('[Line1]', '<hr/>');
    resp = resp.replaceAll('[Line2]', '<hr/>');
    resp = resp.replaceAll('[Line3]', '<hr/>');
    resp = resp.replaceAll('[Line4]', '<hr/>');
    resp = resp.replaceAll('[Line5]', '<hr/>');
    resp = resp.replaceAll('[Line6]', '<hr/>');
    resp = resp.replaceAll('[ReceiptHeader1]', '[kasticket_hoofd1]');
    resp = resp.replaceAll('[ReceiptHeader2]', '[kasticket_hoofd2]');
    resp = resp.replaceAll('[ReceiptHeader3]', '[kasticket_hoofd3]');
    resp = resp.replaceAll('[ReceiptHeader4]', '[kasticket_hoofd4]');
    resp = resp.replaceAll('[ReceiptHeader5]', '[kasticket_hoofd5]');
    resp = resp.replaceAll('[ReceiptHeader6]', '[kasticket_hoofd6]');
    resp = resp.replaceAll('[ReceiptHeader7]', '[kasticket_hoofd7]');
    resp = resp.replaceAll('[ReceiptFooter1]', '[kasticket_voet1]');
    resp = resp.replaceAll('[ReceiptFooter2]', '[kasticket_voet2]');
    resp = resp.replaceAll('[ReceiptFooter3]', '[kasticket_voet3]');
    resp = resp.replaceAll('[ReceiptFooter4]', '[kasticket_voet4]');
    resp = resp.replaceAll('[ReceiptFooter5]', '[kasticket_voet5]');
    resp = resp.replaceAll('[ReceiptFooter6]', '[kasticket_voet6]');
    resp = resp.replaceAll('[ReceiptFooter7]', '[kasticket_voet7]');
    resp = resp.replaceAll('[ReceiptFooter8]', '[kasticket_voet8]');
    resp = resp.replaceAll('[ReceiptFooter9]', '[kasticket_voet9]');
    resp = resp.replaceAll('[ReceiptFooter10]', '[kasticket_voet10]');
    resp = resp.replaceAll('[ReceiptFooter11]', '[kasticket_voet11]');
    resp = resp.replaceAll('[ReceiptFooter12]', '[kasticket_voet10]');
    resp = resp.replaceAll('[ReceiptFooter13]', '[kasticket_voet13]');
    resp = resp.replaceAll('[ReceiptFooter14]', '[kasticket_voet14]');
    resp = resp.replaceAll('[ReceiptFooter15]', '[kasticket_voet15]');
    resp = resp.replaceAll('[ReceiptFooter16]', '[kasticket_voet16]');
    resp = resp.replaceAll('[ReceiptFooter17]', '[kasticket_voet17]');
    resp = resp.replaceAll('[ReceiptFooter18]', '[kasticket_voet18]');
    resp = resp.replaceAll('[ReceiptFooter19]', '[kasticket_voet19]');
    resp = resp.replaceAll('[ReceiptFooter20]', '[kasticket_voet20]');

    for(let x in progVars){
        //console.log('['+x+']', progVars[x]);
        resp = resp.replaceAll('['+x+']', progVars[x]);
    }
    return resp;
}

const getArticlePreviewLine = (text) => {
    let resp = text;

    for(let x in article){
        resp = resp.replaceAll('['+x+']', article[x]);
    }
    return resp;
}

const addTagToHeader = (tag) => {
    if(tag !== ""){
        setHeader(header + "[" + tag+"] ");
    }
}

const addTagToFooter = (tag) => {
    if(tag !== ""){
        setFooter(footer + "[" + tag+"] ");
    }
}

const addTagToHeaderReservation = (tag) => {
    if(tag !== ""){
        setHeaderReservation(headerReservation + "[" + tag+"] ");
    }
}

const addTagToFooterReservation = (tag) => {
    if(tag !== ""){
        setFooterReservation(footerReservation + "[" + tag+"] ");
    }
}

const firstStep = () => {
    return (
        <div>
            <div className="rowContainerEvenly" style={{width: '100%'}} >
                <div className='margin_top_10px columnContainerBetween slide-in-blurred-left' style={{width: '25%'}}>
                    {props?.login?.sft_status !== "0" && <FormControl className={props.theme+ '_accent _100perc'}>
                      <InputLabel id="demo-simple-select-label">{props.t('template')}</InputLabel>
                      <Select
                        className={props.theme+"_accent "+props.theme+"_text _100perc"}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={template}
                        label="Template"
                        onChange={(event)=>{const {target: { value },} = event; setTemplate(value);}}
                        style={{height: 40, paddingTop: 8, paddingBottom: 8}}>
                            {(password === SftGlobalData.superUltraSecretLockPassword || template === "0") && 
                            <MenuItem
                                key={0}
                                value={0}
                                >
                                    CUSTOM
                                </MenuItem>}
                        {templates.map((template) => (
                            <MenuItem
                            key={template.id}
                            value={template.id}
                            >
                                {template.title}
                            </MenuItem>
                        ))}
                        
                      </Select>
                    </FormControl>}
                    <FormControl className={props.theme+ '_accent _100perc'}>
                      <InputLabel id="demo-simple-select-label">{props.t('font')}</InputLabel>
                      <Select
                        className={props.theme+"_accent "+props.theme+"_text _100perc"}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={font}
                        label={props.t('font')}
                        onChange={(event)=>{const {target: { value },} = event; setFont(value);}}
                        style={{height: 40, paddingTop: 8, paddingBottom: 8}}>
                        <MenuItem value={"Arial"}>Arial</MenuItem>
                        <MenuItem value={"Brush Script MT"}>Brush Script MT</MenuItem>
                        <MenuItem value={"Comic Sans MS"}>Comic Sans MS</MenuItem>
                        <MenuItem value={"Courier New"}>Courier New</MenuItem>
                        <MenuItem value={"Georgia"}>Georgia</MenuItem>
                        <MenuItem value={"Garamond"}>Garamond</MenuItem>
                        <MenuItem value={"Montserrat"}>Montserrat</MenuItem>
                        <MenuItem value={"Open Sans"}>Open Sans</MenuItem>
                        <MenuItem value={"'Times New Roman'"}>Times New Roman</MenuItem>
                        <MenuItem value={"Verdana"}>Verdana</MenuItem>
                      </Select>
                    </FormControl>
                    <p style={{fontFamily: font, textAlign: 'center', backgroundColor: color, color: textColor, width: '100%', marginBottom: 1}}> <br/> {props.t('example_text')} <br/> &nbsp;</p>
                </div>
                <div className='margin_top_10px columnContainerBetween slide-in-blurred-top' style={{width: '25%'}}>
                    <TextField
                        minRows={1}
                        autoComplete="off" 
                        className={props.theme+ '_accent _100perc'}
                        id="accent_color"
                        label={props.t('accent_color')}
                        size={'small'}
                        sx={(props.theme==='light') ? {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.light_text
                              },
                            '& .MuiInputLabel-root': {
                                color: Colors.light_text_accent
                             }, borderRadius: 3, marginTop: 0} 
                            :
                            {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.dark_text_accent
                                }, borderRadius: 3, marginTop: 0}}
                        value={color.toUpperCase()}
                        onChange={(event)=>{setColor(event.target.value)}}
                        required
                        />  
                    <section className="resposive example margin_top_15px">
                        <HexColorPicker className="colorpicker" color={color} onChange={(event)=>{setColor(event);}} />
                    </section>
                </div>
                <div className='margin_top_10px columnContainerBetween slide-in-blurred-right' style={{width: '25%'}}>
                    <TextField
                        minRows={1}
                        autoComplete="off" 
                        className={props.theme+ '_accent _100perc'}
                        id="text_color"
                        label={props.t('text_color')}
                        size={'small'}
                        sx={(props.theme==='light') ? {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.light_text
                              },
                            '& .MuiInputLabel-root': {
                                color: Colors.light_text_accent
                             }, borderRadius: 3, marginTop: 0} 
                            :
                            {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.dark_text_accent
                                }, borderRadius: 3, marginTop: 0}}
                        value={textColor.toUpperCase()}
                        onChange={(event)=>{setTextColor(event.target.value)}}
                        required
                        />  
                    <section className="resposive example margin_top_15px">
                        <HexColorPicker className="colorpicker" color={textColor} onChange={(event)=>{setTextColor(event);}} />
                    </section>
                </div>
            </div>
            <div className="rowContainerEvenly" style={{width: '100%', marginTop: 10}} >
                <div className='margin_top_10px columnContainerBetween slide-in-blurred-right' style={{width: '25%', alignItems: 'bottom'}}>
                    <p style={{fontFamily: font, textAlign: 'left', backgroundColor: discountColor, color: discountTextColor, width: '100%', marginTop: 'auto', marginBottom: 3, paddingTop: 5, paddingBottom: 5}}> &nbsp;&nbsp;&nbsp;<b>{props.t('solden')}:      € 10.00 - € 2.00 </b> &nbsp;</p>
                </div>
                <div className='margin_top_10px columnContainerBetween slide-in-blurred-left' style={{width: '25%'}}>
                    <TextField
                        minRows={1}
                        autoComplete="off" 
                        className={props.theme+ '_accent _100perc'}
                        id="accent_color_discount"
                        label={props.t('accent_color_discount')}
                        size={'small'}
                        sx={(props.theme==='light') ? {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.light_text
                              },
                            '& .MuiInputLabel-root': {
                                color: Colors.light_text
                             }, borderRadius: 3, marginTop: 0} 
                            :
                            {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.dark_text_accent
                                }, borderRadius: 3, marginTop: 0}}
                        value={discountColor.toUpperCase()}
                        onChange={(event)=>{setDiscountColor(event.target.value)}}
                        required
                        />  
                    <section className="resposive example margin_top_15px">
                        <HexColorPicker className="colorpicker" color={discountColor} onChange={(event)=>{setDiscountColor(event);}} />
                    </section>
                </div>
                <div className='margin_top_10px columnContainerBetween slide-in-blurred-top' style={{width: '25%'}}>
                    <TextField
                        minRows={1}
                        autoComplete="off" 
                        className={props.theme+ '_accent _100perc'}
                        id="text_color_discount"
                        label={props.t('text_color_discount')}
                        size={'small'}
                        sx={(props.theme==='light') ? {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.light_text
                              },
                            '& .MuiInputLabel-root': {
                                color: Colors.light_text
                             }, borderRadius: 3, marginTop: 0} 
                            :
                            {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.dark_text_accent
                                }, borderRadius: 3, marginTop: 0}}
                        value={discountTextColor.toUpperCase()}
                        onChange={(event)=>{setDiscountTextColor(event.target.value)}}
                        required
                        />  
                    <section className="resposive example margin_top_15px">
                        <HexColorPicker className="colorpicker" color={discountTextColor} onChange={(event)=>{setDiscountTextColor(event);}} />
                    </section>
                </div>
                    
               </div>
            </div>
    )
}

/*************************************************************************************
*
*                                HEADER & FOOTER
*
*************************************************************************************/
const secondStep = () => {
    return (
        <div>
        <div className='rowContainerBetween'>
            <div className="_45perc slide-in-blurred-left">
                <h4>{props.t('header')}</h4>
                <div className="rowContainerStart">
                    <textarea className={"textareaBorderRight "+ ((props.theme==='light') ? 'light_accent_darker textarea' : 'dark_accent textarea')} id="memo" onChange={(event)=>{setHeader(event.target.value)}} name="header" value={header} style={{resize: 'none', minHeight: 245}}/>
                    <textarea className={"textareaBorderLeft "+ ((props.theme==='light') ? 'light_accent_darkest textarea' : 'dark_accent_darker textarea')} id="memo" disabled name="header_preview" value={getTagsPreview(header)} style={{resize: 'none', minHeight: 245}}/>
                </div>
                <div className="rowContainerBetween margin_bottom_10px" style={{alignItems: 'center'}}>
                    <FormControl className={props.theme+ '_accent _80perc'}>
                      <InputLabel id="demo-simple-select-label">Tags</InputLabel>
                      <Select
                        className={props.theme+"_accent "+props.theme+"_text _100perc"}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedHeaderTag}
                        label="Tags"
                        onChange={(event)=>{const {target: { value },} = event; setSelectedHeaderTag(value); try{setSelectedHeaderTagValue(progVars[value]);}catch(exception){console.error(exception);}}}
                        style={{height: 40, paddingTop: 8, paddingBottom: 8}}>
                        {fetchProgVarOptions()}
                      </Select>
                    </FormControl>
                    <Button type="button" className={"button_container__button_no_margin width_120px maxHeight "} onClick={()=>{addTagToHeader(selectedHeaderTag)}}>&nbsp;{props.t('add')}&nbsp;</Button>
                </div>
                <p>{selectedHeaderTagValue}</p>
            </div>
            <div className="_45perc slide-in-blurred-right">
                <h4>{props.t('footer')}</h4>
                <div className="rowContainerEnd">
                    <textarea className={"textareaBorderRight "+ ((props.theme==='light') ? 'light_accent_darker textarea' : 'dark_accent textarea')} id="memo" onChange={(event)=>{setFooter(event.target.value)}} name="footer" value={footer} style={{resize: 'none', minHeight: 245}}/>
                    <textarea className={"textareaBorderLeft "+ ((props.theme==='light') ? 'light_accent_darkest textarea' : 'dark_accent_darker textarea')} id="memo" disabled name="footer_preview" value={getTagsPreview(footer)} style={{resize: 'none', minHeight: 245}}/>
                </div>
                <div className="rowContainerBetween margin_bottom_10px" style={{alignItems: 'center'}}>
                    <FormControl className={props.theme+ '_accent _80perc'}>
                      <InputLabel id="demo-simple-select-label">Tags</InputLabel>
                      <Select
                        className={props.theme+"_accent "+props.theme+"_text _100perc"}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedFooterTag}
                        label="Tags"
                        onChange={(event)=>{const {target: { value },} = event; setSelectedFooterTag(value);  try{setSelectedFooterTagValue(progVars[value]);}catch(exception){console.error(exception);}}}
                        style={{height: 40, paddingTop: 8, paddingBottom: 8}}>
                        {fetchProgVarOptions()}
                      </Select>
                    </FormControl>
                    <Button type="button" className={"button_container__button_no_margin width_120px maxHeight "} onClick={()=>{addTagToFooter(selectedFooterTag)}}>&nbsp;{props.t('add')}&nbsp;</Button>
                </div>
                <p>{selectedFooterTagValue}</p>
            </div>
        </div>
        <div className='rowContainerBetween'>
            <div className="_45perc slide-in-blurred-left">
                <h4>{props.t('header')} ({props.t('reservation')})</h4>
                <div className="rowContainerStart">
                    <textarea className={"textareaBorderRight "+ ((props.theme==='light') ? 'light_accent_darker textarea' : 'dark_accent textarea')} id="memo" onChange={(event)=>{setHeaderReservation(event.target.value)}} name="headerReservation" value={headerReservation} style={{resize: 'none', minHeight: 245}}/>
                    <textarea className={"textareaBorderLeft "+ ((props.theme==='light') ? 'light_accent_darkest textarea' : 'dark_accent_darker textarea')} id="memo" disabled name="header_preview" value={getTagsPreview(headerReservation)} style={{resize: 'none', minHeight: 245}}/>
                </div>
                <div className="rowContainerBetween margin_bottom_10px" style={{alignItems: 'center'}}>
                    <FormControl className={props.theme+ '_accent _80perc'}>
                      <InputLabel id="demo-simple-select-label">Tags</InputLabel>
                      <Select
                        className={props.theme+"_accent "+props.theme+"_text _100perc"}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedHeaderTagReservation}
                        label="Tags"
                        onChange={(event)=>{const {target: { value },} = event; setSelectedHeaderTagReservation(value); try{setSelectedHeaderTagValueReservation(progVars[value]);}catch(exception){console.error(exception);}}}
                        style={{height: 40, paddingTop: 8, paddingBottom: 8}}>
                        {fetchProgVarOptions()}
                      </Select>
                    </FormControl>
                    <Button type="button" className={"button_container__button_no_margin width_120px maxHeight "} onClick={()=>{addTagToHeaderReservation(selectedHeaderTagReservation)}}>&nbsp;{props.t('add')}&nbsp;</Button>
                </div>
                <p>{selectedHeaderTagValueReservation}</p>
            </div>
            <div className="_45perc slide-in-blurred-right">
                <h4>{props.t('footer')} ({props.t('reservation')})</h4>
                <div className="rowContainerEnd">
                    <textarea className={"textareaBorderRight "+ ((props.theme==='light') ? 'light_accent_darker textarea' : 'dark_accent textarea')} id="memo" onChange={(event)=>{setFooterReservation(event.target.value)}} name="footer" value={footerReservation} style={{resize: 'none', minHeight: 245}}/>
                    <textarea className={"textareaBorderLeft "+ ((props.theme==='light') ? 'light_accent_darkest textarea' : 'dark_accent_darker textarea')} id="memo" disabled name="footer_preview" value={getTagsPreview(footerReservation)} style={{resize: 'none', minHeight: 245}}/>
                </div>
                <div className="rowContainerBetween margin_bottom_10px" style={{alignItems: 'center'}}>
                    <FormControl className={props.theme+ '_accent _80perc'}>
                      <InputLabel id="demo-simple-select-label">Tags</InputLabel>
                      <Select
                        className={props.theme+"_accent "+props.theme+"_text _100perc"}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedFooterTagReservation}
                        label="Tags"
                        onChange={(event)=>{const {target: { value },} = event; setSelectedFooterTagReservation(value);  try{setSelectedFooterTagValueReservation(progVars[value]);}catch(exception){console.error(exception);}}}
                        style={{height: 40, paddingTop: 8, paddingBottom: 8}}>
                        {fetchProgVarOptions()}
                      </Select>
                    </FormControl>
                    <Button type="button" className={"button_container__button_no_margin width_120px maxHeight "} onClick={()=>{addTagToFooterReservation(selectedFooterTagReservation)}}>&nbsp;{props.t('add')}&nbsp;</Button>
                </div>
                <p>{selectedFooterTagValueReservation}</p>
            </div>
        </div>
    </div>
    );
}

/*************************************************************************************
*
*                                   DETAIL
*
*************************************************************************************/
const thirdStep = () => {
    return (
        <div className='rowContainerBetween'>
            <div className={"_50perc slide-in-blurred-left"}>
            <TextField
                    minRows={1}
                    autoComplete="off" 
                    inputProps={{ maxLength: 255 }}
                    className={props.theme+ '_accent _100perc margin_top_10px'}
                    id="detail_line1"
                    label={props.t('detail_line')+" 1"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={detailLine1}
                    onChange={(event)=>{setDetailLine1(event.target.value)}}
                    />  
                <TextField
                    minRows={1}
                    autoComplete="off" 
                    inputProps={{ maxLength: 255 }}
                    className={props.theme+ '_accent _100perc margin_top_10px'}
                    id="detail_line2"
                    label={props.t('detail_line')+" 2"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={detailLine2}
                    onChange={(event)=>{setDetailLine2(event.target.value)}}
                    /> 
                <TextField
                    minRows={1}
                    autoComplete="off" 
                    inputProps={{ maxLength: 255 }}
                    className={props.theme+ '_accent _100perc margin_top_10px'}
                    id="detail_line3"
                    label={props.t('detail_line')+" 3"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={detailLine3}
                    onChange={(event)=>{setDetailLine3(event.target.value)}}
                    />  
                <div className='rowContainerBetween alignCenterInContainer'>
                    <div style={{marginLeft: 5}}>{props.t('use_images')}</div>
                    <Switch
                      checked={detailUseImages}
                      onChange={(event)=>{setDetailUseImages(event.target.checked)}}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                </div>
                <div className='margin_top_15px rowContainerStart sftBorder'>
                    {detailUseImages && <div className='columnContainerCenter'><img alt="Article" src={"data:image/jpeg;base64,"+article["ARTICLE_PHOTO"]} style={{width: 100, height: 100, objectFit: 'contain'}}/></div>}
                    <div className='columnContainerCenter margin_left__10px'>
                        <div><b>{getArticlePreviewLine(detailLine1)}</b></div>
                        <div className='margin_top_5px'>{getArticlePreviewLine(detailLine2)}</div>
                        <div className='margin_top_5px'>{getArticlePreviewLine(detailLine3)}</div>
                    </div>
                </div>
                <div className={"rowContainerBetween alignCenterInContainer"} style={{ marginTop: '8.5rem' }}>
                        <div style={{ marginLeft: 5 }}>{props.t('show_customer_card_discount_text')}</div>
                        <Switch
                            checked={showCustomerCardDiscountText}
                            onChange={(event) => { setShowCustomerCardDiscountText(event.target.checked) }}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                </div>
            </div>
            <div className={"_45perc columnContainerStart"}>
                <div className='slide-in-blurred-right styled-table margin_top_10px'>
                    <table className='_100perc textAlignLeft '>
                        <thead>
                            <tr className='sftBorderBottom'>
                                <th className='sftBorderBottom textAlignLeft width_250px'>&nbsp; Tag</th>
                                <th className='sftBorderBottom textAlignLeft'>&nbsp; {props.t('Value')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fetchArticleTags()}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

/*************************************************************************************
*
*                                   DISCLAIMER
*
*************************************************************************************/
const fourthStep = () => {
    return (
        <div className='rowContainerBetween'>
            <div className={"_50perc"}>
                <TextField 
                    multiline
                    rows={5}
                    autoComplete="off" 
                    inputProps={{ maxLength: disclaimerMaxLength }}
                    className={props.theme+ '_accent _100perc margin_top_10px scale-up-center'}
                    id="advance"
                    label={props.t('disclaimer') + " " + props.t('advance')}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={disclaimerAdvance}
                    onChange={(event)=>{setDisclaimerAdvance(event.target.value)}}
                    onFocus={()=>{setFocusTarget("advance")}}
                />  
                <TextField 
                    multiline
                    rows={5}
                    autoComplete="off" 
                    inputProps={{ maxLength: disclaimerMaxLength }}
                    className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-2'}
                    id="retouch"
                    label={props.t('disclaimer') + " " + props.t('retouch')}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={disclaimerRetouch}
                    onChange={(event)=>{setDisclaimerRetouch(event.target.value)}}
                    onFocus={()=>{setFocusTarget("retouch")}}
                />  

                <TextField 
                    multiline
                    rows={5}
                    autoComplete="off" 
                    inputProps={{ maxLength: disclaimerMaxLength }}
                    className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-3'}
                    id="voucher"
                    label={props.t('disclaimer') + " " + props.t('voucher')}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={disclaimerVoucher}
                    onChange={(event)=>{setDisclaimerVoucher(event.target.value)}}
                    onFocus={()=>{setFocusTarget("voucher")}}
                />  
                <TextField 
                    multiline
                    rows={5}
                    autoComplete="off" 
                    inputProps={{ maxLength: disclaimerMaxLength }}
                    className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-4'}
                    id="giftvoucher"
                    label={props.t('disclaimer') + " " + props.t('gift_voucher')}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={disclaimerGiftVoucher}
                    onChange={(event)=>{setDisclaimerGiftVoucher(event.target.value)}}
                    onFocus={()=>{setFocusTarget("giftvoucher")}}
                />  
                <TextField 
                    multiline
                    rows={5}
                    autoComplete="off" 
                    inputProps={{ maxLength: disclaimerMaxLength }}
                    className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-5'}
                    id="commvoucher"
                    label={props.t('disclaimer') + " " + props.t('commercial_voucher')}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={disclaimerCommercialVoucher}
                    onChange={(event)=>{setDisclaimerCommercialVoucher(event.target.value)}}
                    onFocus={()=>{setFocusTarget("commercialvoucher")}}
                />  
                <TextField 
                    multiline
                    rows={5}
                    autoComplete="off" 
                    inputProps={{ maxLength: disclaimerMaxLength }}
                    className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-6'}
                    id="ccvoucher"
                    label={props.t('disclaimer') + " " + props.t('cc_voucher')}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={disclaimerCustomerCardVoucher}
                    onChange={(event)=>{setDisclaimerCustomerCardVoucher(event.target.value)}}
                    onFocus={()=>{setFocusTarget("customercardvoucher")}}
                />  
                <TextField 
                    multiline
                    rows={5}
                    autoComplete="off" 
                    inputProps={{ maxLength: disclaimerMaxLength }}
                    className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-7'}
                    id="receipt"
                    label={props.t('disclaimer') + " " + props.t('receipt')}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={disclaimerReceipt}
                    onChange={(event)=>{setDisclaimerReceipt(event.target.value)}}
                    onFocus={()=>{setFocusTarget("receipt")}}
                />  
                <TextField 
                    multiline
                    rows={5}
                    autoComplete="off" 
                    inputProps={{ maxLength: disclaimerMaxLength }}
                    className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-8'}
                    id="reservation"
                    label={props.t('disclaimer') + " " + props.t('reservation')}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={disclaimerReservation}
                    onChange={(event)=>{setDisclaimerReservation(event.target.value)}}
                    onFocus={()=>{setFocusTarget("reservation")}}
                />  
                <TextField 
                    multiline
                    rows={5}
                    autoComplete="off" 
                    inputProps={{ maxLength: disclaimerMaxLength }}
                    className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-9'}
                    id="offer"
                    label={props.t('disclaimer') + " " + props.t('offer')}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={disclaimerOffer}
                    onChange={(event)=>{setDisclaimerOffer(event.target.value)}}
                    onFocus={()=>{setFocusTarget("offer")}}
                />  
                <TextField 
                    multiline
                    rows={5}
                    autoComplete="off" 
                    inputProps={{ maxLength: disclaimerMaxLength }}
                    className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-10'}
                    id="custorder"
                    label={props.t('disclaimer') + " " + props.t('customer_order')}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={disclaimerCustomerOrder}
                    onChange={(event)=>{setDisclaimerCustomerOrder(event.target.value)}}
                    onFocus={()=>{setFocusTarget("customerorder")}}
                /> 
                <TextField 
                    multiline
                    rows={5}
                    autoComplete="off" 
                    inputProps={{ maxLength: disclaimerMaxLength }}
                   className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-11'}
                    id="externalorder"
                    label={props.t('disclaimer') + " " + props.t('cc_external_order')}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                            },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text
                            }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={disclaimerExternalOrder}
                    onChange={(event)=>{setDisclaimerExternalOrder(event.target.value)}}
                    onFocus={()=>{setFocusTarget("externalorder")}}
                />  
            </div>
            <div className={"_45perc columnContainerStart"}>
                {focusTarget !== "" && <ProgVarTable type={focusTarget} progVars={progVars} t={props.t} />}
            </div>
            
        </div>
    );
}

/*************************************************************************************
*
*                                   LOGO
*
*************************************************************************************/
const fifthStep = () => {
    return (
        <div className='rowContainerCenter'>
            <div className='_50perc slide-in-blurred-top'>
                <h4>Logo</h4>
                <hr/>
                <div className='flex_center_column'>
                    <br/>
                    <img alt="Customer logo" src={(logo !== "data:image/jpeg;base64," ? logo : SftGlobalData.noimage)} style={{width: 300, height: 100, objectFit: 'contain'}}/>
                </div>
                <br/>
                <label className="text-white">{props.t('select_file')}</label>
                <input type="file" className="form-control" name="image" onChange={onLogoChange}  accept="image/jpeg, image/png, image/jpg"/>
                {props.login?.sft_status !== "0" && <Button className={"modal_button_container_no_margin__button margin_top_10px _100perc"} onClick={()=>{fetchMySftLogo()}}>{props.t('get_from_licence')}</Button>}
                {props.login?.sft_status !== "0" && <Button className={"modal_button_container_no_margin__button margin_top_10px _100perc"} onClick={()=>{onSaveLogo();}} disabled={!logoOverwrite}>{props.t('save_to_licence')}</Button>}
            </div>
        </div>
    );
}

/*************************************************************************************
*
*                                   URLS
*
*************************************************************************************/
const sixthStep = () => {
    return (
        <div>
            <TextField 
                minRows={1}
                autoComplete="off" 
                className={props.theme+ '_accent _100perc scale-up-center'}
                id="facebook"
                label={"Facebook"}
                size={'small'}
                InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ width: 20 }}>
                        <FontAwesomeIcon icon={faFacebook} size="lg" />
                      </InputAdornment>
                    ),
                  }}
                sx={(props.theme==='light') ? {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.light_text
                      },
                    '& .MuiInputLabel-root': {
                        color: Colors.light_text
                     }, borderRadius: 3, marginTop: 0} 
                    :
                    {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.dark_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.dark_text_accent
                        }, borderRadius: 3, marginTop: 0}}
                value={urlFacebook}
                onChange={(event)=>{setUrlFacebook(event.target.value)}}
            />  
            <TextField 
                minRows={1}
                autoComplete="off" 
                className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-2'}
                id="instagram"
                label={"Instagram"}
                size={'small'}
                InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ width: 20 }}>
                        <FontAwesomeIcon icon={faInstagram} size="lg" />
                      </InputAdornment>
                    ),
                  }}
                sx={(props.theme==='light') ? {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.light_text
                      },
                    '& .MuiInputLabel-root': {
                        color: Colors.light_text
                     }, borderRadius: 3, marginTop: 0} 
                    :
                    {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.dark_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.dark_text_accent
                        }, borderRadius: 3, marginTop: 0}}
                value={urlInstagram}
                onChange={(event)=>{setUrlInstagram(event.target.value)}}
            />  
            <TextField 
                minRows={1}
                autoComplete="off" 
                className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-3'}
                id="tiktok"
                label={"TikTok"}
                size={'small'}
                InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ width: 20 }}>
                        <FontAwesomeIcon icon={faTiktok} size="lg" />
                      </InputAdornment>
                    ),
                  }}
                sx={(props.theme==='light') ? {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.light_text
                      },
                    '& .MuiInputLabel-root': {
                        color: Colors.light_text
                     }, borderRadius: 3, marginTop: 0} 
                    :
                    {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.dark_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.dark_text_accent
                        }, borderRadius: 3, marginTop: 0}}
                value={urlTiktok}
                onChange={(event)=>{setUrlTiktok(event.target.value)}}
            />  
            <TextField 
                minRows={1}
                autoComplete="off" 
                className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-3'}
                id="twitter"
                label={"Twitter / X"}
                size={'small'}
                InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ width: 20 }}>
                        <FontAwesomeIcon icon={faXTwitter} size="lg" />
                      </InputAdornment>
                    ),
                  }}
                sx={(props.theme==='light') ? {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.light_text
                      },
                    '& .MuiInputLabel-root': {
                        color: Colors.light_text
                     }, borderRadius: 3, marginTop: 0} 
                    :
                    {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.dark_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.dark_text_accent
                        }, borderRadius: 3, marginTop: 0}}
                value={urlTwitter}
                onChange={(event)=>{setUrlTwitter(event.target.value)}}
            />  
            <TextField 
                minRows={1}
                autoComplete="off" 
                className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-4'}
                id="webshop"
                label={"Webshop"}
                size={'small'}
                InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ width: 20 }}>
                        <FontAwesomeIcon icon={faShoppingCart} size="lg" />
                      </InputAdornment>
                    ),
                  }}
                sx={(props.theme==='light') ? {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.light_text
                      },
                    '& .MuiInputLabel-root': {
                        color: Colors.light_text
                     }, borderRadius: 3, marginTop: 0} 
                    :
                    {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.dark_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.dark_text_accent
                        }, borderRadius: 3, marginTop: 0}}
                value={urlWebshop}
                onChange={(event)=>{setUrlWebshop(event.target.value)}}
            />  
            <TextField 
                minRows={1}
                autoComplete="off" 
                className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-5'}
                id="website"
                label={"Website"}
                size={'small'}
                InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ width: 20 }}>
                        <FontAwesomeIcon icon={faGlobe} size="lg" />
                      </InputAdornment>
                    ),
                  }}
                sx={(props.theme==='light') ? {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.light_text
                      },
                    '& .MuiInputLabel-root': {
                        color: Colors.light_text
                     }, borderRadius: 3, marginTop: 0} 
                    :
                    {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.dark_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.dark_text_accent
                        }, borderRadius: 3, marginTop: 0}}
                value={urlWebsite}
                onChange={(event)=>{setUrlWebsite(event.target.value)}}
            />  
            <TextField 
                minRows={1}
                autoComplete="off" 
                className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-6'}
                id="youtube"
                label={"Youtube"}
                size={'small'}
                InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ width: 20 }}>
                        <FontAwesomeIcon icon={faYoutube} size="lg" />
                      </InputAdornment>
                    ),
                  }}
                sx={(props.theme==='light') ? {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.light_text
                      },
                    '& .MuiInputLabel-root': {
                        color: Colors.light_text
                     }, borderRadius: 3, marginTop: 0} 
                    :
                    {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.dark_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.dark_text_accent
                        }, borderRadius: 3, marginTop: 0}}
                value={urlYoutube}
                onChange={(event)=>{setUrlYoutube(event.target.value)}}
            />  
            <TextField 
                minRows={1}
                autoComplete="off" 
                className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-7'}
                id="linkedin"
                label={"LinkedIn"}
                size={'small'}
                InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ width: 20 }}>
                        <FontAwesomeIcon icon={faLinkedin} size="lg" />
                      </InputAdornment>
                    ),
                  }}
                sx={(props.theme==='light') ? {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.light_text
                      },
                    '& .MuiInputLabel-root': {
                        color: Colors.light_text
                     }, borderRadius: 3, marginTop: 0} 
                    :
                    {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.dark_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.dark_text_accent
                        }, borderRadius: 3, marginTop: 0}}
                value={urlLinkedin}
                onChange={(event)=>{setUrlLinkedin(event.target.value)}}
            />  
            <TextField 
                minRows={1}
                autoComplete="off" 
                className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-8'}
                id="email"
                label={"Email"}
                size={'small'}
                InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ width: 20 }}>
                        <FontAwesomeIcon icon={faEnvelope} size="lg" />
                      </InputAdornment>
                    ),
                  }}
                sx={(props.theme==='light') ? {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.light_text
                      },
                    '& .MuiInputLabel-root': {
                        color: Colors.light_text
                     }, borderRadius: 3, marginTop: 0} 
                    :
                    {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.dark_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.dark_text_accent
                        }, borderRadius: 3, marginTop: 0}}
                value={urlEmail}
                onChange={(event)=>{setUrlEmail(event.target.value)}}
            />  
            <TextField 
                minRows={1}
                autoComplete="off" 
                className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-9'}
                id="whatsapp"
                label={"WhatsApp"}
                size={'small'}
                InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ width: 20 }}>
                        <FontAwesomeIcon icon={faWhatsapp} size="lg" />
                      </InputAdornment>
                    ),
                  }}
                sx={(props.theme==='light') ? {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.light_text
                      },
                    '& .MuiInputLabel-root': {
                        color: Colors.light_text
                     }, borderRadius: 3, marginTop: 0} 
                    :
                    {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.dark_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.dark_text_accent
                        }, borderRadius: 3, marginTop: 0}}
                value={urlWhatsapp}
                onChange={(event)=>{setUrlWhatsapp(event.target.value)}}
            />  
        </div>
    );
}

/*************************************************************************************
*
*                                   SMTP / MAIL
*
*************************************************************************************/
const seventhStep = () => {
    return (
        <div className="rowContainerEvenly" style={{width: '100%'}} >
            {props?.login?.sft_status !== "0" && <div className='margin_top_10px slide-in-blurred-left' style={{width: '45%'}}>
                <h4>SMTP {props.t('settings')}</h4>
                <hr/>
                <FormControl className={props.theme+ '_accent _100perc'}>
                  <InputLabel id="demo-simple-select-label">Preset</InputLabel>
                  <Select
                    className={props.theme+"_accent "+props.theme+"_text _100perc"}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={smtpPreset}
                    label="SMTP preset"
                    onChange={(event)=>{const {target: { value },} = event; setSmtpPreset(value); onSelectSmtpOption(value);}}
                    style={{height: 40, paddingTop: 8, paddingBottom: 8}}>
                        <MenuItem value={"-1"}>---</MenuItem>
                        {smtpOptions.map((row, index) => (
                            <MenuItem
                            key={index}
                            value={index}
                            >
                                {row.smtpid} - {row.smtpname}
                            </MenuItem>
                        ))}
                  </Select>
                </FormControl>
                <TextField
                    disabled={smtpPreset!=="-1"}
                    minRows={1}
                    autoComplete="off" 
                    className={props.theme+ '_accent _100perc margin_top_10px'}
                    id="smtp_name"
                    label={"SMTP name"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={smtpName}
                    onChange={(event)=>{setSmtpName(event.target.value)}}
                    />  
                <TextField
                    disabled={smtpPreset!=="-1"}
                    minRows={1}
                    autoComplete="off" 
                    className={props.theme+ '_accent _100perc margin_top_10px'}
                    id="smtp_host"
                    label={"SMTP host"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={smtpHost}
                    onChange={(event)=>{setSmtpHost(event.target.value)}}
                    required
                    />  
                <TextField
                    disabled={smtpPreset!=="-1"}
                    minRows={1}
                    autoComplete="off" 
                    className={props.theme+ '_accent _100perc margin_top_10px'}
                    id="smtp_user"
                    label={"SMTP username"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={smtpUser}
                    onChange={(event)=>{setSmtpUser(event.target.value)}}
                    required
                    /> 
                <TextField
                    disabled={smtpPreset!=="-1"}
                    minRows={1}
                    autoComplete="off" 
                    className={props.theme+ '_accent _100perc margin_top_10px'}
                    id="smtp_pass"
                    label={"SMTP password"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={smtpPass}
                    onChange={(event)=>{setSmtpPass(event.target.value)}}
                    required
                    />  
                <TextField
                    disabled={smtpPreset!=="-1"}
                    minRows={1}
                    autoComplete="off" 
                    className={props.theme+ '_accent _100perc margin_top_10px'}
                    id="smtp_port"
                    label={"SMTP port"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={smtpPort}
                    onChange={(event)=>{setSmtpPort(event.target.value)}}
                    required
                    />  
                <div className='rowContainerBetween alignCenterInContainer'>
                    <div style={{marginLeft: 5}}>SSL</div>
                    <Switch
                      disabled={smtpPreset!=="-1"}
                      checked={smtpSSL}
                      onChange={(event)=>{setSmtpSSL(event.target.checked)}}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                </div>

                {smtpPreset==="-1" && <LoadingButton 
                    loading={validateSmtpLoader}
                    type="button" 
                    className='login__button _100perc'
                    size="large"
                    onClick={()=>{validateSmtp()}}>&nbsp;Test connection&nbsp;</LoadingButton>}
            </div>}
            <div className='margin_top_10px slide-in-blurred-right' style={{width: '45%'}}>
                <h4>{props.t('mail_settings')}</h4>
                <hr/>
                <TextField
                    minRows={1}
                    autoComplete="off" 
                    className={props.theme+ '_accent _100perc'}
                    id="mailFromName"
                    label={props.t('Sender_name')}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={mailFromName}
                    onChange={(event)=>{setMailFromName(event.target.value)}}
                    required
                    />  
                <TextField
                    minRows={1}
                    autoComplete="off" 
                    className={props.theme+ '_accent _100perc margin_top_10px'}
                    id="mailFromEmail"
                    label={props.t('Sender_email')}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={mailFromEmail}
                    onChange={(event)=>{setMailFromEmail(event.target.value)}}
                    required
                    />  
                <TextField
                    minRows={1}
                    autoComplete="off" 
                    className={props.theme+ '_accent _100perc margin_top_10px'}
                    id="mailSubject"
                    label={props.t('Subject')}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={mailSubject}
                    onChange={(event)=>{setMailSubject(event.target.value)}}
                    required
                    />  
                <TextField
                    minRows={1}
                    autoComplete="off" 
                    className={props.theme+ '_accent _100perc margin_top_10px'}
                    id="mailError"
                    label={props.t('Error_email')}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={mailErrorEmail}
                    onChange={(event)=>{setMailErrorEmail(event.target.value)}}
                    />  
                <TextField
                    minRows={1}
                    autoComplete="off" 
                    className={props.theme+ '_accent _100perc margin_top_10px'}
                    id="mailbcc"
                    label={"BCC ("+props.t('general')+")"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={mailBccEmail}
                    onChange={(event)=>{setMailBccEmail(event.target.value)}}
                    />  
                <TextField
                    minRows={1}
                    autoComplete="off" 
                    className={props.theme+ '_accent _100perc margin_top_10px'}
                    id="mailbccreceipt"
                    label={"BCC ("+props.t('receipt')+")"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={mailBccReceipt}
                    onChange={(event)=>{setMailBccReceipt(event.target.value)}}
                    />  
                <TextField
                    minRows={1}
                    autoComplete="off" 
                    className={props.theme+ '_accent _100perc margin_top_10px'}
                    id="mailbccreservation"
                    label={"BCC ("+props.t('reservation')+")"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={mailBccReservation}
                    onChange={(event)=>{setMailBccReservation(event.target.value)}}
                    />  
                <TextField
                    minRows={1}
                    autoComplete="off" 
                    className={props.theme+ '_accent _100perc margin_top_10px'}
                    id="mailbcccustorder"
                    label={"BCC ("+props.t('customer_order')+")"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={mailBccCustOrder}
                    onChange={(event)=>{setMailBccCustOrder(event.target.value)}}
                    />  
                <TextField
                    minRows={1}
                    autoComplete="off" 
                    className={props.theme+ '_accent _100perc margin_top_10px'}
                    id="mailbccoffer"
                    label={"BCC ("+props.t('offer')+")"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={mailBccOffer}
                    onChange={(event)=>{setMailBccOffer(event.target.value)}}
                    />  
            </div>
        </div>
    );
}

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const saveHandler = () => {
        if(smtpHost !== "" && typeof smtpHost !== "undefined" && 
           smtpPass !== "" && typeof smtpPass !== "undefined" && 
           smtpUser !== "" && typeof smtpUser !== "undefined" && 
           smtpPort !== "" && typeof smtpPort !== "undefined" && 
           mailFromEmail !== "" && typeof mailFromEmail !== "undefined" && 
           mailFromName !== "" && typeof mailFromName !== "undefined" && 
           mailSubject !== "" &&  typeof mailSubject !== "undefined" && 
           color !== "" && typeof color !== "undefined" && 
           textColor !== "" && typeof textColor !== "undefined" && 
           discountColor !== "" && typeof discountColor !== "undefined" && 
           discountTextColor !== "" && typeof discountTextColor !== "undefined"){
            saveConfirmation();
        } else {
            props.notify(props.t('missing_fields'), 7500, "top-right", props.theme, 1); 
        }
        //console.log("port", smtpPort);
        //console.log("SAVE TIME");
        
    }


    const saveConfirmation = () => {
        if (window.confirm(props.t('confirm_save_changes')) === true) {
            setDisableControls(true);
            exportHtml(true);
        }
    }

    const applyTemplateHandler = () => {
        setApplyTemplateLoading(true);
        let url = SftGlobalData.baseURL_API + 'templates&action=fetchTemplate';
        let postData = {
            sft_id: props.login.sft_status,
            sft_group: props.login.sft_group,
            login: props.login.login,
            key: props.api,
            one_lang: true,
            id: applyTemplate
        }
        axios.post(url, postData, {
        headers : {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
        }})
        .then(resp => {
            if(SftGlobalData.debug){
                console.log('url', url);
                console.log('postData', postData);
                console.log('response', resp);
            }
            setDesign(JSON.parse(resp.data.design));
            setHtml(resp.data.html);
            try{
                if(resp.data.design){
                    emailEditor.current.loadDesign(JSON.parse(resp.data.design));
                }
            }catch(e){}
            setApplyTemplateLoading(false);
        });
        
    }

    const validateSmtp = () => {
        setValidateSmtpLoader(true);
        let url = SftGlobalData.baseURL_API + 'eticket&action=validateSmtp';
        let postData = {
            sft_id: props.login.sft_status,
            sft_group: props.login.sft_group,
            login: props.login.login,
            key: props.api,
            host: smtpHost,
            user: smtpUser,
            port: smtpPort,
            pass: smtpPass,
            ssl: smtpSSL
        }
        axios.post(url, postData, {
        headers : {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
        }})
        .then(resp => {
            if(SftGlobalData.debug){
                console.log('url', url);
                console.log('postData', postData);
                console.log('response', resp);
            }
            props.notify(resp.data.message, 2000, "bottom-center", props.theme, 5456886); 
            setValidateSmtpLoader(false);
        });
    }

    return (
        <div className={(props.screenWidth) < 600 ? 'not_expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme : 'expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme}>
            <FloatingSaveButton disabled={disableControls} onClick={()=>{saveHandler();}} />
            <FloatingBackButton disabled={disableControls} onClick={()=>{navigate("/"+props.lang+"/eticket");}} />
            {/*<FloatingMailButton disabled={disableControls} onClick={()=>{testMailHandler();}} />*/}
            <div className={"container_list__title " + props.theme}><h2>{props.t('eticket')} {(name !== "") && <span> - {name}</span>}</h2></div>
            {loading && <div className={"container_list__title " + props.theme} style={{display:"flex", justifyContent: "space-evenly", marginTop: 5, border: 1}}>
                <TailSpin 
                    height="40"
                    width="40"
                    color={Colors.sft_blue}
                    ariaLabel='loading'
                />
            </div>}
            <div className='rowContainerCenter _100perc'>
                {(!loading  && <Box className={props.theme + ' licence__enivronment'}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className={props.theme + ' container_tabs_below_subtitle'}>
                      <Tabs value={value} onChange={handleChange} className={props.theme} variant="scrollable" scrollButtons="auto">
                        <Tab label={"Data "} {...a11yProps(0)} className={props.theme}/>
                        <Tab label={"Logo"} {...a11yProps(1)} className={props.theme}/>                
                        <Tab label={"E-mail "} {...a11yProps(2)} className={props.theme}/>
                        <Tab label={props.t('template')+" "} {...a11yProps(3)} className={props.theme}/>      
                        <Tab label={"Extra"} {...a11yProps(4)} className={props.theme}/>         
                      </Tabs>
                  </Box>
                  
                  <TabPanel value={value} index={0}>
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button type="button" className={"button_container__button_no_margin maxHeight margin_right__10px"} onClick={(event) => { if (SftGlobalData.confirmWindow(props.t('confirm_default_nl'))) { setDefaultsNL() } }}>{props.t('default_nl')}</Button>
                                <Button type="button" className={"button_container__button_no_margin maxHeight "} onClick={(event) => { if (SftGlobalData.confirmWindow(props.t('confirm_default_fr'))) { setDefaultsFR() } }}>{props.t('default_fr')}</Button>
                            </div>
                            {secondStep()}
                            <hr/>
                            <h4>Detail</h4>
                            <br/>
                            {thirdStep()}
                            <hr/>
                            <h4>{props.t('disclaimer')}</h4>
                            <br/>
                            {fourthStep()}
                            <hr/>
                            <h4>URL</h4>
                            <br/>
                            {sixthStep()}
                            <br/>
                        </div>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                        <div>
                            {fifthStep()}
                        </div>
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                        <div>
                            {seventhStep()}
                            <br/>
                        </div>
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                        <div>
                            <br/>
                            {firstStep()}
                            {props?.login?.sft_status !== "0" && <hr/>}
                            {(props?.login?.sft_status !== "0" && password !== SftGlobalData.superUltraSecretLockPassword) && <div>
                            <div className='columnContainerCenter alignCenterInContainer'>
                                <br/>
                                <FontAwesomeIcon icon={faLock} className='heartbeat' style={{color: "#dd0e0e",}} size={"7x"} />
                                <br/>
                            </div>
                            <div className='rowContainerCenter'>
                                <FormControl sx={{ m: 1, width: '25%', margin: '0' }}>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={showPassword ? 'text' : 'password'}
                                        name="txt_password" 
                                        className={props.theme+"_accent"}
                                        value={password}
                                        onChange={(event)=>{setPassword(event.target.value); checkPw(event.target.value);}}
                                        placeholder={props.t('password')}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                                >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                
                            </div></div>}
                            {password === SftGlobalData.superUltraSecretLockPassword &&
                                <div className='columnContainerStart'>
                                    <div className='rowContainerBetween _50perc'>
                                    <FormControl className={props.theme+ '_accent _100perc margin_right__10px'}>
                                      <InputLabel id="demo-simple-select-label">{props.t('template')}</InputLabel>
                                      <Select
                                        className={props.theme+"_accent "+props.theme+"_text _100perc"}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={applyTemplate}
                                        label="Template"
                                        onChange={(event)=>{const {target: { value },} = event; setApplyTemplate(value);}}
                                        style={{height: 40, paddingTop: 8, paddingBottom: 8}}>
                                        {templates.map((template) => (
                                            <MenuItem
                                            key={template.id}
                                            value={template.id}
                                            >
                                                {template.title}
                                            </MenuItem>
                                        ))}
                                        
                                      </Select>
                                    </FormControl>
                                    <LoadingButton 
                                        loading={applyTemplateLoading}
                                        type="button" 
                                        className='login__button width_140px'
                                        size="large"
                                        onClick={()=>{applyTemplateHandler()}}>&nbsp;{props.t('apply')}&nbsp;</LoadingButton>
                                    </div>
                                    <br/>
                                    <div className='rowContainerCenter'>
                                    <EmailEditor
                                        minHeight={700}
                                        ref={emailEditor}
                                        onLoad={onLoad}
                                        onReady={onReady}
                                        appearance={
                                            {
                                                theme: props.theme,
                                                panels: {
                                                  tools: {
                                                    dock: 'right'
                                                  }
                                                }
                                            }
                                        }
                                        options={{
                                            displayMode: "mail", 
                                           
                                        }}
                                    />
                                    </div>
                                </div>}
                            <br/>
                        </div>
                  </TabPanel>
                  <TabPanel value={value} index={4}>
                        <div className='rowContainerCenter'>
                            <div className='_50perc slide-in-blurred-top'>
                                <h4>{props.t('remark')}</h4>
                                <hr/><br/>
                                {remark}
                                <br/><br/><br/>
                            </div>
                        </div>
                  </TabPanel>
                  
              </Box>)}
          </div>
        </div>
    );
}

export default EticketDetailScreen;
