import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import './EnvironmentScreen.css';
import CardWithIcon from './CardWithIcon';
import CardWithImage from './CardWithImage';
import SftGlobalData from '../../library/SftGlobalData';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faDatabase, faHammer, faServer, faStop, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Refresh } from '@mui/icons-material';
import { Box, Tab, Tabs, styled } from '@mui/material';
import PropTypes from 'prop-types';
import axios from 'axios';
import Colors from '../../library/Colors';
import { DataGridPro, GridActionsCellItem } from '@mui/x-data-grid-pro';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ModalUnstyled from '@mui/base/ModalUnstyled';


const StyledModal = styled(ModalUnstyled)`
    position: fixed;
    text-align: center;
    z-index: 1300;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    `;

const Backdrop = styled('div')`
    z-index: -1;
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
    `;

const style = {
    bgcolor: 'background.paper',
    border: '0px solid #000',
    borderRadius: 6,
    p: 2,
    px: 4,
    pb: 3,
};

function TabPanel(props) {
    const { children, value, index, maxHeight, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        style={{maxHeight: props.maxHeight-200, overflowY: "auto", overflowX: "hidden"}}
      >
        {value === index && (
          <Box>
            <div style={{paddingTop: 0}}>{children}</div>
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
  
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const EnvironmentScreen = (props) =>  {
    const [selectedDb, setSelectedDb] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [includeTest, setIncludeTest] = useState(true);
    const [includeLocal, setIncludeLocal] = useState(true);
    const [includeStopped, setIncludeStopped] = useState(true);
    const [onlyTest, setOnlyTest] = useState(false);
    const [onlyLocal, setOnlyLocal] = useState(false);
    const [onlyStopped, setOnlyStopped] = useState(false);
    const [inputFinish, setInputFinish] = useState(false);

    const [loading, setLoading] = useState(true);
    const [apiAccounts, setApiAccounts] = useState([]);
    const [apiUsers, setApiUsers] = useState([]);
    const [value, setValue] = useState(0);
    const [apiModal, setApiModal] = useState(false);
    const [selectedAPI, setSelectedAPI] = useState({id: 0, accounts_array: [], fullname: ""});


    let navigate = useNavigate();
    const inputEl = useRef();


    //---------------------------------------------------------------------------------------------
    //    FUNCTIONS
    //---------------------------------------------------------------------------------------------
    const getDbs = props.dbs.filter(db => (onlyTest ? (db.cust_is_test === "1" && ((searchValue.substring(searchValue.length - 1) !== "*" && db.description.match(new RegExp(searchValue, "i"))) || (searchValue.substring(searchValue.length - 1) !== "*" && db.alt_name.match(new RegExp(searchValue, "i"))) || db.cust_no.match(new RegExp("^" + searchValue.slice(0, -1) + "$", 'i')) || (searchValue.substring(searchValue.length - 1) !== "*" && db.cust_no.match(new RegExp(searchValue, "i"))) || (searchValue.substring(searchValue.length - 1) !== "*" && db.search_string.match(new RegExp(searchValue, "i"))))) : 
                                            onlyLocal ? (db.cust_is_local === "1" && ((searchValue.substring(searchValue.length - 1) !== "*" && db.description.match(new RegExp(searchValue, "i"))) || (searchValue.substring(searchValue.length - 1) !== "*" && db.alt_name.match(new RegExp(searchValue, "i"))) || db.cust_no.match(new RegExp("^" + searchValue.slice(0, -1) + "$", 'i')) || (searchValue.substring(searchValue.length - 1) !== "*" && db.cust_no.match(new RegExp(searchValue, "i"))) || (searchValue.substring(searchValue.length - 1) !== "*" && db.search_string.match(new RegExp(searchValue, "i"))))) : 
                                            onlyStopped ? ((db.description.match(new RegExp("stop", "i")) || db.alt_name.match(new RegExp("stop", "i")))  && ((searchValue.substring(searchValue.length - 1) !== "*" && db.description.match(new RegExp(searchValue, "i"))) || (searchValue.substring(searchValue.length - 1) !== "*" && db.alt_name.match(new RegExp(searchValue, "i"))) || db.cust_no.match(new RegExp("^" + searchValue.slice(0, -1) + "$", 'i')) || (searchValue.substring(searchValue.length - 1) !== "*" && db.cust_no.match(new RegExp(searchValue, "i"))) || (searchValue.substring(searchValue.length - 1) !== "*" && db.search_string.match(new RegExp(searchValue, "i"))))) : 
                                            ((
                                            (searchValue.substring(searchValue.length - 1) !== "*" && db.description.match(new RegExp(searchValue, "i"))) ||
                                            (searchValue.substring(searchValue.length - 1) !== "*" && db.alt_name.match(new RegExp(searchValue, "i"))) ||
                                            (searchValue.substring(searchValue.length - 1) === "*" && db.cust_no.match(new RegExp("^" + searchValue.slice(0, -1) + "$", 'i'))) || 
                                            (searchValue.substring(searchValue.length - 1) !== "*" && db.cust_no.match(new RegExp("^" + searchValue + "$", 'i'))) || 
                                            (searchValue.substring(searchValue.length - 1) !== "*" && db.cust_no.match(new RegExp(searchValue, "i"))) ||
                                            (searchValue.substring(searchValue.length - 1) !== "*" && db.search_string.match(new RegExp(searchValue, "i")))
                                        ) && (includeTest ? true : db.cust_is_test === "0") && (includeLocal ? true : db.cust_is_local === "0")  && (includeStopped ? true : !(db.description.match(new RegExp("stop", "i")) || db.alt_name.match(new RegExp("stop", "i")))) )))
       .sort(function(a, b) {
        if(a.description.toLowerCase() < b.description.toLowerCase()) return -1;
        if(a.description.toLowerCase() > b.description.toLowerCase()) return 1;
        return 0;
       }).map(function(data, idx) {
           if(data.logo !== ""){
                return <CardWithImage key={data.id} type={"environment"} local={data.cust_is_local} test={data.cust_is_test} zone={data.cust_zone} title={data.description} subtitle={data.alt_name + " ("+data.cust_no+")"} src={data.logo} theme={props.theme} data={data} func={()=>{if(SftGlobalData.debug){console.log('environment pressed '+data.description); console.log(data);} setSelectedDb(data);}} />
           } else {
                return <CardWithIcon key={data.id} type={"environment"} local={data.cust_is_local} test={data.cust_is_test} zone={data.cust_zone} title={data.description} subtitle={data.alt_name + " ("+data.cust_no+")"} icon={faDatabase} theme={props.theme} data={data} func={()=>{if(SftGlobalData.debug){console.log('environment pressed '+data.description); console.log(data);} setSelectedDb(data);}} />
           }
    });

    useEffect(() => {
        if(props.dbs.length === 0){
            props.changeScreen('login');
            navigate("/"+props.lang+"/login");
        } else {
            if (selectedDb !== ""){
                sessionStorage.removeItem('key');
                sessionStorage.setItem('key', selectedDb.encrypted_key);
                
                props.selectDb(selectedDb, selectedDb.encrypted_key);
                props.changeScreen('home');
                return props.sftStatus !== "0" ? navigate("/"+props.lang+"/licence") : navigate("/"+props.lang+"/home");
            } else {
                if(SftGlobalData.debug){
                    console.log('dbs length', props.dbs.length);
                }
                if(props.dbs.length === 1){
                    if(!props.noAutoRedirect){
                        setSelectedDb(props.dbs[0]);
                        props.selectDb(props.dbs[0], props.dbs[0].encrypted_key);
        
                        sessionStorage.removeItem('key');
                        sessionStorage.setItem('key', props.dbs[0].encrypted_key);
        
                        props.changeScreen('home');
                        props.sftStatus !== "0" ? navigate("/"+props.lang+"/licence") : navigate("/"+props.lang+"/home");
                    } else {
                        if(props.dbs.length < 15){
                            setSearchValue(props.dbs[0].cust_no + "*");
                        }
                        props.changeScreen('environment');
                    }
                } else{
                    props.changeScreen('environment');
                    let sessionTestEnv = sessionStorage.getItem('test');
                    let sessionLocalEnv = sessionStorage.getItem('local');
                    let sessionStoppedEnv = sessionStorage.getItem('stopped');
                    let sessionSearchEnv = sessionStorage.getItem('search');
                    if(sessionTestEnv !== null){setIncludeTest(sessionTestEnv === 'true');}
                    if(sessionLocalEnv !== null){setIncludeLocal(sessionLocalEnv === 'true');}
                    if(sessionStoppedEnv !== null){setIncludeStopped(sessionStoppedEnv === 'true');}
                    if(sessionSearchEnv !== null){setSearchValue(sessionSearchEnv);}
                    try{inputEl.current.select();}catch(e){}
                    if(props.group === "DEV" || props.group === "ADMIN" || props.group === "SUPER"){
                        loadApi();
                    }
                }
            }
        }
        
     },[selectedDb]);

     const loadApi = () => {
        let url = SftGlobalData.baseURL_SFT_API + '1/users?display=stats';
        axios.get(url, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
                'Authorization' : 'bearer 701b5e919682a3c890fc112c83c07e8b6824ee1d'
            }}) 
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('response', response);
                }
                setApiUsers(response.data);
                let url2 = SftGlobalData.baseURL_SFT_API + '1/accounts';
                axios.get(url2, {
                    headers : {
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
                        'Authorization' : 'bearer 701b5e919682a3c890fc112c83c07e8b6824ee1d'
                    }}) 
                    .then(response2 => {
                        if(SftGlobalData.debug){
                            console.log('url2', url2);
                            console.log('response2', response2);
                        }
                        setApiAccounts(response2.data);
                        setLoading(false);
                    });
            });
     }


     useEffect(()=>{
        if(!inputFinish){
            if(searchValue.length > 1){
                inputEl.current.select();
            }
            if(searchValue.length !== 0){
                setInputFinish(true);
            }
        }
        
     }, [searchValue]);


    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          if(props.dbs.filter(db => (onlyTest ? (db.cust_is_test === "1" && ((searchValue.substring(searchValue.length - 1) !== "*" && db.description.match(new RegExp(searchValue, "i"))) || (searchValue.substring(searchValue.length - 1) !== "*" && db.alt_name.match(new RegExp(searchValue, "i"))) || db.cust_no.match(new RegExp("^" + searchValue.slice(0, -1) + "$", 'i')) || (searchValue.substring(searchValue.length - 1) !== "*" && db.cust_no.match(new RegExp(searchValue, "i"))) || (searchValue.substring(searchValue.length - 1) !== "*" && db.search_string.match(new RegExp(searchValue, "i"))))) : 
          onlyLocal ? (db.cust_is_local === "1" && ((searchValue.substring(searchValue.length - 1) !== "*" && db.description.match(new RegExp(searchValue, "i"))) || (searchValue.substring(searchValue.length - 1) !== "*" && db.alt_name.match(new RegExp(searchValue, "i"))) || db.cust_no.match(new RegExp("^" + searchValue.slice(0, -1) + "$", 'i')) || (searchValue.substring(searchValue.length - 1) !== "*" && db.cust_no.match(new RegExp(searchValue, "i"))) || (searchValue.substring(searchValue.length - 1) !== "*" && db.search_string.match(new RegExp(searchValue, "i"))))) : 
          onlyStopped ? ((db.description.match(new RegExp("stop", "i")) || db.alt_name.match(new RegExp("stop", "i")))  && ((searchValue.substring(searchValue.length - 1) !== "*" && db.description.match(new RegExp(searchValue, "i"))) || (searchValue.substring(searchValue.length - 1) !== "*" && db.alt_name.match(new RegExp(searchValue, "i"))) || db.cust_no.match(new RegExp("^" + searchValue.slice(0, -1) + "$", 'i')) || (searchValue.substring(searchValue.length - 1) !== "*" && db.cust_no.match(new RegExp(searchValue, "i"))) || (searchValue.substring(searchValue.length - 1) !== "*" && db.search_string.match(new RegExp(searchValue, "i"))))) : 
          ((
          (searchValue.substring(searchValue.length - 1) !== "*" && db.description.match(new RegExp(searchValue, "i"))) ||
          (searchValue.substring(searchValue.length - 1) !== "*" && db.alt_name.match(new RegExp(searchValue, "i"))) ||
          db.cust_no.match(new RegExp("^" + searchValue.slice(0, -1) + "$", 'i')) ||
          (searchValue.substring(searchValue.length - 1) !== "*" && db.cust_no.match(new RegExp(searchValue, "i"))) ||
          (searchValue.substring(searchValue.length - 1) !== "*" && db.search_string.match(new RegExp(searchValue, "i")))
      ) && (includeTest ? true : db.cust_is_test === "0") && (includeLocal ? true : db.cust_is_local === "0")  && (includeStopped ? true : !(db.description.match(new RegExp("stop", "i")) || db.alt_name.match(new RegExp("stop", "i")))) ))).length === 1){
                let newSelection = props.dbs.filter(db => (onlyTest ? (db.cust_is_test === "1" && ((searchValue.substring(searchValue.length - 1) !== "*" && db.description.match(new RegExp(searchValue, "i"))) || (searchValue.substring(searchValue.length - 1) !== "*" && db.alt_name.match(new RegExp(searchValue, "i"))) || db.cust_no.match(new RegExp("^" + searchValue.slice(0, -1) + "$", 'i')) || (searchValue.substring(searchValue.length - 1) !== "*" && db.cust_no.match(new RegExp(searchValue, "i"))) || (searchValue.substring(searchValue.length - 1) !== "*" && db.search_string.match(new RegExp(searchValue, "i"))))) : 
                onlyLocal ? (db.cust_is_local === "1" && ((searchValue.substring(searchValue.length - 1) !== "*" && db.description.match(new RegExp(searchValue, "i"))) || (searchValue.substring(searchValue.length - 1) !== "*" && db.alt_name.match(new RegExp(searchValue, "i"))) || db.cust_no.match(new RegExp("^" + searchValue.slice(0, -1) + "$", 'i')) || (searchValue.substring(searchValue.length - 1) !== "*" && db.cust_no.match(new RegExp(searchValue, "i"))) || (searchValue.substring(searchValue.length - 1) !== "*" && db.search_string.match(new RegExp(searchValue, "i"))))) : 
                onlyStopped ? ((db.description.match(new RegExp("stop", "i")) || db.alt_name.match(new RegExp("stop", "i")))  && ((searchValue.substring(searchValue.length - 1) !== "*" && db.description.match(new RegExp(searchValue, "i"))) || (searchValue.substring(searchValue.length - 1) !== "*" && db.alt_name.match(new RegExp(searchValue, "i"))) || db.cust_no.match(new RegExp("^" + searchValue.slice(0, -1) + "$", 'i')) || (searchValue.substring(searchValue.length - 1) !== "*" && db.cust_no.match(new RegExp(searchValue, "i"))) || (searchValue.substring(searchValue.length - 1) !== "*" && db.search_string.match(new RegExp(searchValue, "i"))))) : 
                ((
                (searchValue.substring(searchValue.length - 1) !== "*" && db.description.match(new RegExp(searchValue, "i"))) ||
                (searchValue.substring(searchValue.length - 1) !== "*" && db.alt_name.match(new RegExp(searchValue, "i"))) ||
                db.cust_no.match(new RegExp("^" + searchValue.slice(0, -1) + "$", 'i')) ||
                (searchValue.substring(searchValue.length - 1) !== "*" && db.cust_no.match(new RegExp(searchValue, "i"))) ||
                (searchValue.substring(searchValue.length - 1) !== "*" && db.search_string.match(new RegExp(searchValue, "i")))
            ) && (includeTest ? true : db.cust_is_test === "0") && (includeLocal ? true : db.cust_is_local === "0")  && (includeStopped ? true : !(db.description.match(new RegExp("stop", "i")) || db.alt_name.match(new RegExp("stop", "i")))) )));
                SftGlobalData.easter2 = false;
                setSelectedDb(newSelection[0]);
            } else if(searchValue.toLowerCase() === "vibrator"){
                SftGlobalData.easter2 = true;
            } else {
                SftGlobalData.easter3 = false;
                SftGlobalData.easter2 = false;
                SftGlobalData.easter1 = false;
            }
        }
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const deleteAccount = (id) => {
        let url = SftGlobalData.baseURL_SFT_API + '1/accounts/'+id;
        axios.delete(url, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
                'Authorization' : 'bearer 701b5e919682a3c890fc112c83c07e8b6824ee1d'
            }}) 
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('response', response);
                }
                setLoading(true);
                loadApi();
            });
    }

    const deleteUser = (id) => {
        let url = SftGlobalData.baseURL_SFT_API + '1/users/'+id;
        axios.delete(url, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
                'Authorization' : 'bearer 701b5e919682a3c890fc112c83c07e8b6824ee1d'
            }}) 
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('response', response);
                }
                setLoading(true);
                loadApi();
            });
    }

    const removeAccount = (user, accountId) => {
        let url = SftGlobalData.baseURL_SFT_API + '1/accounts/'+accountId+"/users/removeUser?email="+user;
        let postData = { email: user }
        axios.post(url, postData, 
        {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
                'Authorization' : 'bearer 701b5e919682a3c890fc112c83c07e8b6824ee1d'
            }
        }) 
        .then(response => {
            if(SftGlobalData.debug){
                console.log('url', url);
                console.log("postData", postData);
                console.log('response', response);
            }
            try{
                props.notify(props.t('savedChanges'), 7500, "top-right", props.theme, 1); 
                removeAccountFromList(accountId)
            } catch(e){
                props.notify(response.data.message, 7500, "top-right", props.theme, 1); 
            }

        }).catch(error => {
            props.notify(error.response.data.message, 7500, "top-right", props.theme, 1); 
        });
    }

    const removeAccountFromList = (accountId) => {
        const updatedAccounts = selectedAPI?.uses_accounts ? selectedAPI.accounts_array.filter(account => account.id !== accountId) : selectedAPI.accounts_array.filter(account => account.account_id !== accountId);;
        setSelectedAPI(prevState => ({
          ...prevState,
          accounts_array: updatedAccounts
        }));
      };

    const editUser = (row) => {
        setSelectedAPI(row);
        setApiModal(true);
    }

    useEffect(()=>{
        if(!apiModal && selectedAPI.id !== 0){
            setLoading(true);
            loadApi();
        }
    }, [apiModal])

    //---------------------------------------------------------------------------------------------
    //    OUTPUT
    //---------------------------------------------------------------------------------------------
    return (
        <div className={"environment " + (searchValue === "stefan" ? "stefan" : "")}>  
            <StyledModal
                aria-labelledby="unstyled-modal-title"
                aria-describedby="unstyled-modal-description"
                open={apiModal}
                onClose={()=>{setApiModal(false)}}
                BackdropComponent={Backdrop}
                >
                <Box sx={style}  style={{minWidth: 750, minHeight: 500, width: "90%"}} className={props.theme + "  box_fade-in"}>
                    <h2>API user edit ({selectedAPI?.uses_accounts ? selectedAPI?.fullname : selectedAPI?.email})</h2>
                    <hr/>
                    <div className={'environment__card_body_container '}>
                        {/* MUI Datagrid with accounts */}
                        <div className='rowContainerCenter _100perc' style={{maxHeight: 500}}>
                            <DataGridPro
                                  //apiRef={apiRef}
                                  //initialState={initialGrid}
                                  className={'excel_grid ' + props.theme}
                                  columns={[
                                    {
                                        field: 'id',
                                        headerName: 'Account ID',
                                        minWidth: 100,
                                        maxWidth: 100,
                                        width: 100,
                                        align: "center",
                                    },
                                    {
                                        field: 'name',
                                        headerName: "Name",
                                        minWidth: 200,
                                        align: "left",
                                    },
                                    {
                                        field: 'last_call',
                                        headerName: "Last call date",
                                        minWidth: 200,
                                        align: "left",
                                    },
                                    {
                                        field: 'calls',
                                        headerName: "# calls",
                                        minWidth: 100,
                                        align: "center",
                                        headerAlign: "center"
                                    },
                                    {
                                        field: 'actions',
                                        type: 'actions',
                                        width: 40,
                                        maxWidth: 40,
                                        minWidth: 40,
                                        getActions: (params) => [
                                           <GridActionsCellItem
                                            icon={<DeleteIcon  sx={{ color: Colors.def_red }} />}
                                            label="Remove"
                                            onClick={()=>{removeAccount(selectedAPI.email, params.row.id);}}
                                          />
                                        ],
                                    },
                                  ]}
                                  rows={selectedAPI.accounts_array.map((row, index) => {return {
                                          id: selectedAPI?.uses_accounts ? row.id : row.account_id,
                                          name: row.name,
                                          calls: selectedAPI?.uses_accounts ? 0 : row.calls,
                                          last_call: selectedAPI?.uses_accounts ? "0000-00-00 00:00:00" : row.last_call,
                                          actions: "",
                                      }                          
                                  })} 
                                  //onColumnWidthChange={()=>{visibilityChangedHandler();}}
                                  //onColumnVisibilityModelChange={()=>{visibilityChangedHandler();}}
                                  //onPreferencePanelClose={()=>{visibilityChangedHandler();}}
                                  rowThreshold={0}
                                  rowHeight={38}
                                  loading={loading}
                                  pagination={true}
                            /> 
                      </div>
                    </div>
                    
                </Box>
            </StyledModal>
            {(props.dbs.length > 2 || props.sftStatus !== "0") && <div className={props.theme + " environment__search"} >
                <Button className={"environment__search_button margin_right__5px " + (searchValue === "vibrator" ? "shaker " : "") } onClick={()=>{props.onRefresh(); setLoading(true); loadApi();}}><Refresh /></Button>
                <input 
                    ref={inputEl}
                    type="text"
                    name="search"
                    className={props.theme+"_accent environment__search_input"}
                    placeholder={props.t('search')}
                    value={searchValue}
                    autoFocus
                    onChange={e => {setSearchValue(e.target.value.replace("+", "").replace("\\", "")); sessionStorage.removeItem('search'); sessionStorage.setItem('search', e.target.value.replace("+", "").replace("\\", ""));}}
                    onKeyDown={handleKeyDown}
                    onFocus={(event) => event.target.select()}
                    
                />
                <Button className={"environment__toggle_button " + (searchValue === "vibrator" ? "shaker " : "") + (onlyTest ? "yellow" : includeTest ? "green" : "red")} onDoubleClick={()=>{setOnlyTest(true);}} onClick={()=>{setOnlyTest(false); setIncludeTest(!includeTest); sessionStorage.removeItem('test'); sessionStorage.setItem('test', !includeTest);}}><FontAwesomeIcon icon={faHammer} color={'white'} size="1x" /></Button>
                <Button className={"environment__toggle_button " + (searchValue === "vibrator" ? "shaker " : "") + (onlyLocal ? "yellow" : includeLocal ? "green" : "red")} onDoubleClick={()=>{setOnlyLocal(true);}} onClick={()=>{setOnlyLocal(false); setIncludeLocal(!includeLocal); sessionStorage.removeItem('local'); sessionStorage.setItem('local', !includeLocal);}}><FontAwesomeIcon icon={faServer} color={'white'} size="1x" /></Button>
                <Button className={"environment__toggle_button " + (searchValue === "vibrator" ? "shaker " : "") + (onlyStopped ? "yellow" : includeStopped ? "green" : "red")} onDoubleClick={()=>{setOnlyStopped(true);}} onClick={()=>{setOnlyStopped(false); setIncludeStopped(!includeStopped); sessionStorage.removeItem('stopped'); sessionStorage.setItem('stopped', !includeStopped);}}><FontAwesomeIcon icon={faStop} color={'white'} size="1x" /></Button>
                {(props.group === "ADMIN" || props.group === "SUPER") && props.screenWidth > 900 && <Button className={"environment__search_button " + (searchValue === "vibrator" ? "shaker " : "")} onClick={()=>{if(searchValue !== "") {navigate("/"+props.lang+"/environment/"+searchValue);} else { navigate("/"+props.lang+"/environment/+");}}}>+</Button>}
                {/*(props.group === "ADMIN" || props.group === "SUPER") && <Button className={"environment__toggle_button blue"} onClick={()=>{navigate("/"+props.lang+"/admin-stats");}}><FontAwesomeIcon icon={faChartLine} color={'white'} size="1x" /></Button>*/}
            </div>}  
            <Box className="environment__card_header_margin environment__search_width" style={{maxHeight: props.maxHeight}} >
                {(props.group === "DEV" || props.group === "ADMIN" || props.group === "SUPER") && (<Box sx={{ borderBottom: 1, borderColor: 'divider' }} className={' _100perc'}>
                    <Tabs value={value} onChange={handleChange} className={props.theme} variant="scrollable" scrollButtons="auto">
                        <Tab label={"DATABASES "} {...a11yProps(0)} className={props.theme}/>
                        <Tab label={"API ACCOUNTS "} {...a11yProps(1)} className={props.theme}/>  
                        <Tab label={"API USERS "} {...a11yProps(2)} className={props.theme}/>  
                    </Tabs>
                </Box>)}
                <TabPanel value={value} index={0} maxHeight={props.maxHeight}>
                    {(props.dbs.length >= 1) && <div className='environment__card_body_container' style={{maxHeight: props.maxHeight}} >{getDbs}</div>}
                </TabPanel>
                <TabPanel value={value} index={1} maxHeight={props.maxHeight}>
                    <div className={'environment__card_body_container '}>
                        {/* MUI Datagrid with accounts */}
                        <div className='rowContainerCenter _100perc' style={{maxHeight: props.maxHeight-300}}>
                            <DataGridPro
                                  //apiRef={apiRef}
                                  //initialState={initialGrid}
                                  className={'excel_grid ' + props.theme}
                                  style={{minHeight: 500}}
                                  columns={[
                                    {
                                        field: 'id',
                                        headerName: 'Account ID',
                                        minWidth: 100,
                                        maxWidth: 100,
                                        width: 100,
                                        align: "center",
                                        display: 'flex',      
                                    },
                                    {
                                        field: 'name',
                                        headerName: "Name",
                                        minWidth: 200,
                                        align: "left",
                                        display: 'flex',      
                                    },
                                    {
                                        field: 'users',
                                        headerName: 'Users',
                                        minWidth: 60,
                                        maxWidth: 60,
                                        width: 60,
                                        align: "center",
                                        display: 'flex',      
                                    },
                                    {
                                        field: 'active',
                                        headerName: 'Active?',
                                        minWidth: 60,
                                        align: 'center',
                                        display: 'flex',      
                                        renderCell: (params) => {
                                            if(params.row.active){
                                                return <FontAwesomeIcon size={"2x"} icon={faCheck} color={Colors.def_green} />
                                            } else {
                                                return <FontAwesomeIcon size={"2x"} icon={faTimes} color={Colors.def_red} />
                                            }
                                        }
                                    },
                                    {
                                        field: 'actions',
                                        type: 'actions',
                                        width: 40,
                                        maxWidth: 40,
                                        minWidth: 40,
                                        display: 'flex',      
                                        getActions: (params) => [
                                            params.row.users > 0 ? <div></div> : <GridActionsCellItem
                                            icon={<DeleteIcon  sx={{ color: Colors.def_red }} />}
                                            label="Remove"
                                            onClick={()=>{deleteAccount(params.row.id);}}
                                          />
                                        ],
                                    },
                                  ]}
                                  rows={apiAccounts.filter(row => (row.name.match(new RegExp(searchValue, "i")) || row.id == searchValue )).map((row, index) => {return {
                                          id: row.id,
                                          name: row.name,
                                          active: (row.active && row.has_gates),
                                          users: row.users?.length,
                                          actions: "",
                                      }                          
                                  })} 
                                  //onColumnWidthChange={()=>{visibilityChangedHandler();}}
                                  //onColumnVisibilityModelChange={()=>{visibilityChangedHandler();}}
                                  //onPreferencePanelClose={()=>{visibilityChangedHandler();}}
                                  rowThreshold={0}
                                  rowHeight={38}
                                  loading={loading}
                            /> 
                      </div>
                    </div>
                </TabPanel>
                <TabPanel value={value} index={2} maxHeight={props.maxHeight}>
                    <div className={'environment__card_body_container '}>
                        {/* MUI Datagrid with users */}
                        <div className='rowContainerCenter _100perc' style={{maxHeight: props.maxHeight-300}}>
                            <DataGridPro
                                  //apiRef={apiRef}
                                  //initialState={initialGrid}
                                  className={'excel_grid ' + props.theme}
                                  style={{minHeight: 500}}
                                  columns={[
                                    {
                                        field: 'avatar',
                                        headerName: '',
                                        width: 45,
                                        maxWidth: 45,
                                        minWidth: 45,
                                        align: 'center',
                                        display: 'flex',      
                                        renderCell: (params) => {
                                            if(params.row.avatar !== null)
                                                return <img alt="Avatar" style={{scale: 'fitXY', width: 38, height: 38, borderWidth: 0}} src={params.row.avatar} />
                                            else
                                                return <div></div>
                                        }
                                    },
                                    {
                                        field: 'id',
                                        headerName: 'ID',
                                        minWidth: 60,
                                        maxWidth: 60,
                                        width: 60,
                                        align: "center",
                                        display: 'flex',      
                                    },
                                    {
                                        field: 'fullname',
                                        headerName: "Name",
                                        minWidth: 200,
                                        align: "left",
                                        display: 'flex',      
                                    },
                                    {
                                        field: 'firstname',
                                        headerName: "First name",
                                        minWidth: 100,
                                        align: "left",
                                        display: 'flex',      
                                    },
                                    {
                                        field: 'lastname',
                                        headerName: "Last name",
                                        minWidth: 100,
                                        align: "left",
                                        display: 'flex',      
                                    },
                                    {
                                        field: 'email',
                                        headerName: 'Email',
                                        minWidth: 200,
                                        width: 230,
                                        display: 'flex',      
                                    },
                                    {
                                        field: 'is_admin',
                                        headerName: 'Admin?',
                                        minWidth: 60,
                                        align: 'center',
                                        display: 'flex',      
                                        renderCell: (params) => {
                                            if(params.row.is_admin){
                                                return <FontAwesomeIcon size={"2x"} icon={faCheck} color={Colors.def_green} />
                                            } else {
                                                return <FontAwesomeIcon size={"2x"} icon={faTimes} color={Colors.def_red} />
                                            }
                                        }
                                    },
                                    {
                                        field: 'accounts_length',
                                        headerName: '# accounts',
                                        minWidth: 100,
                                        width: 100,
                                        align: "center",
                                        headerAlign: "center",
                                        display: 'flex',      
                                    },
                                    {
                                        field: 'accounts',
                                        headerName: 'Accounts',
                                        minWidth: 200,
                                        width: 230,
                                        display: 'flex',      
                                    },
                                    {
                                        field: 'total_calls',
                                        headerName: '# calls',
                                        align: "center",
                                        headerAlign: "center",
                                        minWidth: 120,
                                        width: 120,
                                        display: 'flex',      
                                    },
                                    {
                                        field: 'last_call',
                                        headerName: 'Last call',
                                        minWidth: 175,
                                        width: 175,
                                        display: 'flex',      
                                    },
                                    {
                                        field: 'actions2',
                                        type: 'actions',
                                        width: 40,
                                        maxWidth: 40,
                                        minWidth: 40,
                                        display: 'flex',      
                                        getActions: (params) => [
                                            <GridActionsCellItem
                                            icon={<EditIcon  sx={{ color: Colors.def_orange }} />}
                                            label="Edit"
                                            onClick={()=>{editUser(params.row);}}
                                          />
                                        ],
                                    },
                                    {
                                        field: 'actions',
                                        type: 'actions',
                                        width: 40,
                                        maxWidth: 40,
                                        minWidth: 40,
                                        display: 'flex',      
                                        getActions: (params) => [
                                            params.row.accounts_length > 0 ? <div></div> : <GridActionsCellItem
                                            icon={<DeleteIcon  sx={{ color: Colors.def_red }} />}
                                            label="Remove"
                                            onClick={()=>{deleteUser(params.row.id);}}
                                          />
                                        ],
                                    }, 
                                    
                                  ]}
                                  rows={apiUsers.filter(row => (
                                                (typeof row?.accounts !== "undefined" ? row.fullname.match(new RegExp(searchValue, "i")) : (row.firstname.match(new RegExp(searchValue, "i")) || row.lastname.match(new RegExp(searchValue, "i")))) || 
                                                row.email.match(new RegExp(searchValue, "i")) || 
                                                (typeof row?.accounts !== "undefined" ? row?.accounts?.some(item => item?.name?.match(new RegExp(searchValue, "i"))) : row?.stats.some(item => item?.name?.match(new RegExp(searchValue, "i")))) || 
                                                row.id == searchValue )).map((row, index) => {
                                        return {
                                          id: row.id,
                                          fullname: (row.lastname + " " + row.firstname),
                                          firstname: row.firstname,
                                          lastname: row.lastname,
                                          email: row.email,
                                          avatar: row.avatar,
                                          is_admin: row.is_admin,
                                          total_calls: row?.total_calls,
                                          last_call: row?.last_call,
                                          accounts: typeof row?.accounts !== "undefined" ? row?.accounts.map(item => item.name).join(', ') : row?.stats.map(item => item.name).join(', '),
                                          accounts_length: typeof row?.accounts !== "undefined" ? row?.accounts?.length : row?.stats?.length,
                                          accounts_array: typeof row?.accounts !== "undefined" ? row?.accounts : row?.stats,
                                          uses_accounts:  typeof row?.accounts !== "undefined"

                                      }                          
                                  })} 
                                  //onColumnWidthChange={()=>{visibilityChangedHandler();}}
                                  //onColumnVisibilityModelChange={()=>{visibilityChangedHandler();}}
                                  //onPreferencePanelClose={()=>{visibilityChangedHandler();}}
                                  rowThreshold={0}
                                  rowHeight={38}
                                  loading={loading}
                                  initialState={{
                                    columns: {
                                      columnVisibilityModel: {
                                        // Hide columns firstname and lastname, the other columns will remain visible
                                        firstname: false,
                                        lastname: false,
                                      },
                                    },
                                  }}
                                  
                            /> 
                      </div>
                    </div>
                </TabPanel>
            </Box>
            {/*(props.dbs.length === 1) && <div className="message">{props.t('redirect_message')}</div>*/}
            {/*(props.dbs.length === 0) && notify(props.t('no_databases'), 1)*/}
        </div>
        
    );
}

export default EnvironmentScreen;
